import React from 'react';
import {
    Badge, Button, Col, Row,
} from 'reactstrap';
import { CircularProgress } from '@mui/material';
import { Can } from '../Can';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext, { ApplicationPermissions } from '../Common';
import { EmployeeLocationTypes } from './Employee';
import { util } from '../Util';

export class EmployeeAddressInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showAddressForm: true,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.loading || !this.props.userPermissions.length) {
            return (<CircularProgress color="secondary" variant="indeterminate" />);
        }

        const addresses = this.props.addresses ?? [];
        const hasRoutingAddress = addresses.length && addresses.find((a) => a.employeeLocationTypeId === EmployeeLocationTypes.Routing);
        const hasHomeAddress = addresses.length && addresses.find((a) => a.employeeLocationTypeId === EmployeeLocationTypes.Home);
        const perms = [...this.props.userPermissions];
        const canEdit = {
            homeAddress: !!perms.includes(ApplicationPermissions.employee_home_address_edit),
            routingAddress: !!perms.includes(ApplicationPermissions.employee_routing_address_edit),
        };

        return (
            <CommonContext.Consumer>
                {(value) => (
                    <>
                        <Row className="pb-1">
                            <Col>
                                <div className="d-flex flex-row align-items-center justify-content-end">
                                    <Can I="edit" a="employee_home_address">
                                        <SmallButton
                                            disabled={!!value.formIsOpen}
                                            hidden={!!hasHomeAddress}
                                            onClick={() => { this.props.onAddAddress('home'); }}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Home Address
                                        </SmallButton>
                                    </Can>
                                    <Can I="edit" a="employee_routing_address">
                                        <SmallButton
                                            disabled={!!value.formIsOpen}
                                            hidden={!!hasRoutingAddress}
                                            onClick={() => { this.props.onAddAddress('routing'); }}
                                            className="ml-3"
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Routing Address
                                        </SmallButton>
                                    </Can>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Address</th>
                                            <th>Type</th>
                                            <Can I="edit" a="employee">
                                                <th className="text-center" style={{ width: '30px' }} />
                                            </Can>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(this.props.addresses ?? []).length
                                            ? <tr><td colSpan="4" className="text-center">No addresses found.</td></tr>
                                            : this.props.addresses.map((address, i) => {
                                                const isRoutingAddress = address.employeeLocationTypeId === EmployeeLocationTypes.Routing;
                                                const isHomeAddress = address.employeeLocationTypeId === EmployeeLocationTypes.Home;

                                                return (
                                                    <tr key={address.id}>
                                                        <td>{`${address.address1}${address.address2 ? ` ${address.address2}` : ''} ${address.city}, ${address.state} ${address.zip} `}</td>
                                                        <td>
                                                            <Badge color="secondary" className="font-size-100 font-weight-normal">
                                                                {util.object.keyByValue(EmployeeLocationTypes, address.employeeLocationTypeId)}
                                                            </Badge>
                                                        </td>
                                                        <td className="text-center" style={{ width: '30px' }}>
                                                            {
                                                                !!((!!isRoutingAddress && !!canEdit.routingAddress) || (!!isHomeAddress && !!canEdit.homeAddress))
                                                                        && (
                                                                            <Button
                                                                                disabled={!!value.formIsOpen}
                                                                                type="button"
                                                                                color="outline-primary"
                                                                                onClick={() => this.props.onEditAddress(address)}
                                                                                size="sm"
                                                                            >
                                                                          Edit
                                                                            </Button>
                                                                        )
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </>
                )}
            </CommonContext.Consumer>
        );
    }
}

EmployeeAddressInfo.contextType = CommonContext;
