import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cls from 'classnames';
import {
    FormCheckbox, FormLabel, ValidationErrorMessage, FlexRow,
} from '../common/forms/FormElements';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import SlideForm from '../common/forms/SlideForm';
import CommonContext from '../Common';
import NumericInput from '../common/forms/NumericInput';

export default class ChargeTypeConfigurationForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            formValidated: false,
            validationMessage: '',
            isValidated: false,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onSubmit = (e) => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    };

    async populateState() {
        this.setState((state) => ({
            loading: false,
        }));
    }

    resetForm = () => this.setState({ formValidated: false, errors: {} });

    render() {
        if (!this.props.entity) return '';
        const { entity, hasHourlyEquipment } = this.props;
        const name = (this.props.entityName ? `${this.props.entityName}.` : '');
        const unitsName = this.props.entity.unitsId ? (this.props.entity.unitsId === 1 ? 'Hours' : 'Units') : '';

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="chargeTypeConfigForm"
                formIcon={faMinusCircle}
                formTitle={!!parseInt(this.props.entity.id ?? 0) > 0 ? 'Edit Exception' : 'Add Exception'}
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }); }}
                isValidated={this.state.formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={this.state.validationMessage}
            >
                <>
                    <FormGroup>
                        <FormLabel text="Customers" />
                        <ValidatedSelect
                            name={`${name}customers`}
                            isMulti
                            required
                            options={this.props.customers}
                            value={(this.props.customers ?? []).filter((x) => (entity.customers ?? []).includes(x.value)) ?? ''}
                            onChange={this.props.onSelectChange}
                            validationMessage="At least one group selection is required."
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text="Applicable Charge?" />
                        <FormCheckbox
                            id={`${name}doesNotApply`}
                            className="ml-3"
                            checked={entity.doesNotApply ?? ''}
                            onChange={this.props.onDoesNotApplyChanged}
                            labelText="Does Not Apply"
                        />
                    </FormGroup>

                    {!entity.doesNotApply
                        && (
                            <>
                                {this.props.chargeUnitId == 1
                            && (
                                <>
                                    <FormGroup>
                                        <FormLabel text="Increment" />
                                        <ValidatedSelect
                                            name={`${name}increment`}
                                            required={this.props.requireIncrement}
                                            options={this.props.increments}
                                            value={this.props.increments.find((i) => i.value == entity.increment) ?? ''}
                                            onChange={this.props.onSelectChange}
                                            validationMessage="Increment is required."
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel text="Break Options" />
                                        <FlexRow className="ml-3">
                                            <FormCheckbox
                                                id={`${name}canBreak`}
                                                checked={entity.canBreak ?? ''}
                                                onChange={this.props.onCanBreakChanged}
                                                labelText="Enable Breaks"
                                            />
                                            <FontAwesomeIcon icon={faChevronRight} className="ml-2 mr-2" />
                                            <FormCheckbox
                                                id={`${name}paidBreak`}
                                                disabled={!entity.canBreak}
                                                checked={entity.paidBreak ?? ''}
                                                onChange={this.props.onChange}
                                                labelText="Paid Break"
                                            />
                                        </FlexRow>
                                    </FormGroup>
                                    <FormGroup className={cls('ml-3', { 'opacity-half': !entity.canBreak })}>
                                        <FormLabel
                                            text="Break Description"
                                            required={!!entity.canBreak}
                                        />
                                        <Input
                                            name={`${name}breakDescription`}
                                            value={entity.breakDescription ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder="Break description"
                                            type="text"
                                            required={!!entity.canBreak}
                                        />

                                        <ValidationErrorMessage>Description is required.</ValidationErrorMessage>
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel
                                            text="Minimum Pay"
                                            required={!!entity.minimumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}minimumPayUnits`}
                                            value={entity.minimumPayUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel
                                            text="Maximum Pay"
                                            required={!!entity.maximumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}maximumPayUnits`}
                                            value={entity.maximumPayUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel
                                            text="Minimum Bill"
                                            required={!!entity.minimumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}minimumBillUnits`}
                                            value={entity.minimumBillUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel
                                            text="Maximum Bill"
                                            required={!!entity.maximumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}maximumBillUnits`}
                                            value={entity.maximumBillUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                </>
                            )}

                                {this.props.chargeUnitId == 2
                            && (
                                <>
                                    <FormGroup>
                                        <FormLabel text="Increment" required={this.props.requireIncrement} />
                                        <NumericInput
                                            name={`${name}increment`}
                                            required={this.props.requireIncrement}
                                            value={entity.increment ?? ''}
                                            onChange={this.props.onChange}
                                            type="number"
                                            step="0.25"
                                        />
                                        <ValidationErrorMessage>Increment is required.</ValidationErrorMessage>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel
                                            text="Minimum Bill"
                                            required={!!entity.minimumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}minimumBillUnits`}
                                            value={entity.minimumBillUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel
                                            text="Maximum Bill"
                                            required={!!entity.maximumPayUnits}
                                        />
                                        <NumericInput
                                            name={`${name}maximumBillUnits`}
                                            value={entity.maximumBillUnits ?? ''}
                                            onChange={this.props.onChange}
                                            placeholder={`Enter  ${unitsName}`}
                                            type="number"
                                            step="0.25"
                                        />
                                    </FormGroup>
                                </>
                            )}
                                <FormGroup>
                                    <FormLabel
                                        text="Timesheet Options"
                                    />
                                    <FormCheckbox
                                        className="ml-3"
                                        id={`${name}disallowNA`}
                                        name={`${name}disallowNA`}
                                        checked={entity.disallowNA ?? ''}
                                        onChange={this.props.onChange}
                                        labelText="Require Data Entry on Timesheet (Do not allow N/A reply)"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel text="Cancellation Options" />
                                    {(this.props.tenantSettings ?? {}).blockBillingCancelEnabled
                                && (
                                    <FormGroup>
                                        <FlexRow className="ml-3">
                                            <FormCheckbox
                                                id={`${name}blockBillingCancel`}
                                                name={`${name}blockBillingCancel`}
                                                checked={entity.blockBillingCancel ?? ''}
                                                onChange={this.props.onChange}
                                                labelText="Block Cancellation if Min Bill Qty Charge > 0 for Billing"
                                            />
                                        </FlexRow>
                                    </FormGroup>
                                )}
                                    {(this.props.tenantSettings ?? {}).blockPayrollCancelEnabled
                                && (
                                    <FormGroup>
                                        <FlexRow className="ml-3">
                                            <FormCheckbox
                                                id={`${name}blockPayrollCancel`}
                                                name={`${name}blockPayrollCancel`}
                                                checked={entity.blockPayrollCancel ?? ''}
                                                onChange={this.props.onChange}
                                                labelText="Block Cancellation if Min Pay Qty Charge > 0 for Payroll"
                                            />
                                        </FlexRow>
                                    </FormGroup>
                                )}
                                    <FormLabel
                                        className="ml-3"
                                        text="Cancellation Time Threshold (Min)"
                                        required={!!entity.canBreak}
                                    />
                                    <Input
                                        className="ml-3 w-auto"
                                        id={`${name}cancellationTimeThreshold`}
                                        name={`${name}cancellationTimeThreshold`}
                                        value={entity.cancellationTimeThreshold ?? ''}
                                        onChange={this.props.onChange}
                                        placeholder="Enter Time in Minutes"
                                        type="number"
                                        step="1"
                                        required={!!entity.canBreak}
                                    />
                                    <ValidationErrorMessage>Description is required.</ValidationErrorMessage>
                                </FormGroup>
                                <FormGroup className="ml-3">
                                    <FormLabel
                                        text={`Minimum Cancellation Bill Qty (${this.props.chargeUnitId == 1 ? 'Hours' : 'Flat'})`}
                                        required={!!entity.canBreak}
                                    />
                                    <Input
                                        id={`${name}cancellationBillQty`}
                                        name={`${name}cancellationBillQty`}
                                        value={entity.cancellationBillQty ?? ''}
                                        onChange={this.props.onChange}
                                        placeholder={`Enter Qty ${this.props.chargeUnitId == 1 ? 'in Hours' : 'Flat'}`}
                                        type="number"
                                        step=".025"
                                        required={!!entity.canBreak}
                                    />
                                    <ValidationErrorMessage>Description is required.</ValidationErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        text="Invoicing"
                                    />
                                    <FormCheckbox
                                        id={`${name}omitFromInvoice`}
                                        name={`${name}omitFromInvoice`}
                                        className="ml-3"
                                        checked={entity.omitFromInvoice ?? ''}
                                        onChange={this.props.onChange}
                                        labelText="Omit from Charge Details on Invoice"
                                    />
                                </FormGroup>

                                {hasHourlyEquipment && (
                                    <FormGroup>
                                        <FormLabel
                                            text="Hourly Equipment"
                                        />
                                        <FormCheckbox
                                            id={`${name}hourlyEquipmentOvertime`}
                                            name={`${name}hourlyEquipmentOvertime`}
                                            className="ml-3"
                                            checked={
                                                entity.hourlyEquipmentOvertime ?? false
                                            }
                                            onChange={this.props.onChange}
                                            labelText="Overtime applies to hourly equipment charge"
                                        />
                                    </FormGroup>
                                )}
                            </>
                        )}
                </>
            </SlideForm>
        );
    }
}
