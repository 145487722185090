import { v4 as uuid } from 'uuid';
import { BillingRate } from '../billing/BillingRate';

export const ChargeTypeConfigurationType = {
    Base: 1, Exception: 2,
};

export const ChargeTypeUnits = {
    Hours: 1,
    Flat: 2,
};

export class ChargeTypeViewModel {
    // Lookups
    billableOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

    increments = [{ value: '0.083', label: '5 minutes' }, { value: '0.167', label: '10 minutes' }, { value: '0.25', label: '15 minutes' }, { value: '0.5', label: '30 minutes' }];

    statuses = [];

    units = [];

    customers = [];

    resTypes = [{ label: 'Employee', value: 1 }, { label: 'Equipment', value: 2 }, { label: 'Setup', value: 5 }, { label: 'None', value: 3 }];

    resGroups = [];

    groups = [];

    equipmentTypes = [];

    // Validation
    errors = {};

    formValidated = false;

    validationMessage = '';

    // Object state
    originalData = null;

    chargeType = null;

    loading = true;

    showExceptionForm = false;

    showRateForm = false;

    selectedException = null;

    selectedRate = null;

    billingRates = [];

    billingRateHisotry = [];
}

export class ChargeTypeConfiguration {
    id = uuid();

    breakDescription = '';

    canBreak = false;

    cascadeChanges = false;

    chargeTypeId = null;

    chargeTypeConfigurationTypeId = null;

    customers = [];

    doesNotApply = false;

    increment = null;

    minimumPayUnits = null;

    maximumPayUnits = null;

    minimumBillUnits = null;

    maximumBillUnits = null;

    paidBreak = false;

    disallowNA = false;

    cancellationTimeThreshold = 60.0; // minutes

    cancellationBillQty = 2.0; // hours

    blockBillingCancel = false;

    blockPayrollCancel = false;
}

export class ContractChargeType {
    constructor(props) {
        this.id = props.id;
        this.contractId = props.id;
        this.chargeTypeId = props.chargeTypeId;
        this.chargeTypeEligibilityId = props.chargeTypeEligibilityId;
        this.order = props.order;
    }

    id = null;

    contractId = null;

    chargeTypeId = null;

    chargeTypeEligibilityId = null;

    order = null;

    description = '';

    chargeUnits = '';

    employees = '';

    equipment = '';

    billable = '';
}

export class ChargeType {
    baseConfig = { ...new ChargeTypeConfiguration(), chargeTypeConfigurationTypeId: 1 /* base */ };

    companyId = null;

    companyName = '';

    description = '';

    groups = [];

    isBillable = false;

    equipment = [];

    equipmentFlat = '';

    exceptionAllowed = false;

    exceptions = [];

    billingRates = [];

    billingRateHisotry = [];

    rates = [];

    id = null;

    information = '';

    statusId = 1; // default to active

    statusName = '';

    rolesFlat = '';

    unitsId = null;

    unitsName = '';

    resourceTypeId = null;

    resourceGroupId = null;
}

export const _ContractChargeTypeEligibility = {
    Required: 1,
    Optional: 2,
    DoNotUse: 3,
};

export class ChargeTypeBillingRate {
    chargeTypeId = uuid();

    chargeTypeName = '';

    billingRateId = uuid();

    billingRate = new BillingRate();
}

// export const fakeTrafficControl =
//    { "baseConfig": { "id": "4b02754a-a865-41b3-88fc-0864e7a7bfa8", "breakDescription": "test", "canBreak": true, "cascadeChanges": true, "chargeTypeId": null, "chargeTypeConfigurationTypeId": 1, "customers": [], "doesNotApply": false, "increment": "0.25", "minimumPayUnits": "15.00", "maximumPayUnits": "20.00", "paidBreak": true, "startDate": "" }, "companyId": null, "companyName": "", "description": "Traffic Controller", "groups": [3], "isBillable": true, "equipment": [], "equipmentFlat": "", "exceptionAllowed": false, "exceptions": [], "id": null, "information": "Test", "statusId": 1, "statusName": "", "rolesFlat": "", "unitsId": 1, "unitsName": "" }
// export function createFakeChargeTypes(count) {
//    var units = [{ id: 1, name: 'Hours' }, { id: 2, name: 'Flat' }];
//    var statuses = [{ id: 1, name: 'Active' }, { id: 2, name: 'Inactive' }];
//    var bools = [true, false];
//    var roles = ['Flagger', 'Foreman', 'Dispatch', 'All'];

//    var charges = [...Array(count).keys()].map(i => {
//        var unit = units[Math.floor(Math.random() * units.length)];
//        var billable = bools[Math.floor(Math.random() * bools.length)];
//        var exceptions = bools[Math.floor(Math.random() * bools.length)];
//        var status = statuses[Math.floor(Math.random() * statuses.length)];
//        var role = roles[Math.floor(Math.random() * roles.length)];
//        var ct = new ChargeType();

//        ct.id = faker.random.number();
//        ct.companyId = 1;
//        ct.companyName = faker.company.companyName();
//        ct.description = faker.name.jobTitle();
//        ct.unitsId = unit.id;
//        ct.unitsName = unit.name;
//        ct.isBillable = billable;
//        ct.exceptionAllowed = exceptions;
//        ct.information = '';
//        ct.statusId = status.id;
//        ct.statusName = status.name;
//        //ct.rolesFlat = role;

//    });
//    return charges;
// }
