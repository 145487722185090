import React, { Fragment } from 'react';
import cls from 'classnames';
import {
    faFileContract,
    faSave,
    faSort,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Input,
    FormGroup,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { Prompt, withRouter, Link } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import {
    AppPageForm,
    FlexCenterRow,
    FlexEndRow,
    onFieldChange,
    onReactSelectChanged,
    SimpleTable,
    SmallButton,
    SubHeading,
    toasty,
    YesNoBadge,
    GroupedRow,
    FormLabel,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import { Can } from '../Can';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    ServiceResponseResult,
    LocalizationKeys as l,
    YesNoOptions,
} from '../Common';
import {
    Contract,
    ScheduleTypes,
    WorkOrderNumberOptions,
    ChargeTypeEligbility,
    ChargeTypeEligibilities,
    _ContractStatuses,
    ContractChargeTypeBillingRate,
} from './Contract';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ChargeTypeSelect from '../chargeType/ChargeTypeSelect';
import PayScheduleSelect from '../paySchedule/PayScheduleSelect';
import ReorderListWidget from '../widgets/ReorderListWidget';
import SlideForm from '../common/forms/SlideForm';
import {
    ChargeTypeConfigurationType,
    _ContractChargeTypeEligibility,
} from '../chargeType/ChargeType';
import { TimesheetResponsibilityType } from '../tenant/Tenant';
import authService from '../api-authorization/AuthorizeService';
import EmailRecipients from '../common/forms/EmailRecipients';
import {
    getComplianceTypeData,
    getSelectListOfEquipmentComplianceTypesForAllDispatches,
    getSelectListOfUserComplianceTypesForAllDispatches,
    getSelectListOfAvailableUserComplianceTypes,
    getSelectListOfAvailableEquipmentComplianceTypes,
    getSelectedEquipmentComplianceTypeIds,
    getSelectedUserComplianceTypeIds,
} from '../complianceType/ComplianceType';
import BillingRateForm from '../billing/BillingRateForm';
import { ContractChargeTypeGroupGrid } from './ContractChargeTypeGroupGrid';

class ContractForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.chargeTypeSelectorRef = React.createRef();
        this.payScheduleSelectorRef = React.createRef();
        this.chargeTypesWidgetRef = React.createRef();
        this.rateFormRef = React.createRef();

        const stateBase = {
            contract: new Contract(),
            contractStatuses: [],
            customerChargeTypes: [],
            dispatchLocations: [],
            fromTemplate: true,
            operationsCenters: [],
            originalData: null,
            scheduleTypes: ScheduleTypes,
            selectedChargeType: null,
            selectedJob: null,
            selfDispatchOptions: YesNoOptions,
            selectedSortableChargeTypes: [],
            showReorderWidget: false,
            workOrders: [],
            currentEmailAddress: null,
            billingCompanies: [],
            billingContacts: [],
            invoiceTerms: [],
            invoiceMappings: [],
            chargeTypeGroups: [],
            enableContractDateRange: false,
            expirationGracePeriodOptions: [],
            enableAutoEscalatingContractRates: false,
            autoEscalatingRateOptions: YesNoOptions,
            enablePrevailingWageContracts: false,
            prevailingWageOptions: YesNoOptions,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onScheduleTypeChanged = this.onScheduleTypeChanged.bind(this);
        this.onAllowSelfDispatchChanged = this.onAllowSelfDispatchChanged.bind(this);
        this.onAllowLinkedWorkOrdersChanged = this.onAllowLinkedWorkOrdersChanged.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.getContractTimesheetResponsibilityTypeOptions = this.getContractTimesheetResponsibilityTypeOptions.bind(this);
        this.onAutoEscalatingRatesChanged = this.onAutoEscalatingRatesChanged.bind(this);
        this.onPrevailingWageChanged = this.onPrevailingWageChanged.bind(this);
        this.onSetupChargesRequiredChanged = this.onSetupChargesRequiredChanged.bind(this);
    }

    // #region LIFECYCLE EVENTS
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps
            && this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    onAddCTGroup = () => {
        const { contract, selectedCTGroups } = { ...this.state };

        let updated = [...contract.contractChargeTypeGroups] ?? [];

        // get selections from the popup
        const selectedChargeTypes = selectedCTGroups.map((x, index) => ({
            companyId: contract.id,
            chargeTypeGroupId: x.chargeTypeGroup.id,
            invoiceDescription: x.invoiceDescription,
            billRate: x.billRate,
            chargeTypeGroup: x,
        }));

        // merge additions with current charges
        updated = updated.concat([...selectedChargeTypes]);

        contract.contractChargeTypeGroups = [...updated];

        this.setState({
            contract: { ...contract },
            showCTGroupModal: false,
            selectedCTGroups: [],
        });
    };

    // #region CHARGE TYPES
    onAddChargeTypes = () => {
        const { contract } = { ...this.state };
        const { contractChargeTypes } = { ...contract };
        const typesToOmit = (contractChargeTypes ?? []).map((c) => c.chargeTypeId) ?? [];
        // pass in types already selected
        this.chargeTypeSelectorRef.current.open(null, [...typesToOmit]);
    };

    onAddChargeTypesCallback = (selections) => {
        const { contract } = { ...this.state };
        let updated = [...contract.contractChargeTypes] ?? [];

        // get selections from the popup
        const selectedChargeTypes = [...selections].map((x, index) => ({
            order: null /* append to the end, user can then order them */,
            contractTemplateId: contract.id,
            chargeTypeId: x.id,
            chargeTypeEligibilityId: x.resourceTypeId == 5 && !x.isSurcharge ? 2 : x.resourceTypeId == 5 ? 1 : null,
            description: x.description,
            chargeUnits: x.unitsName,
            group: x.appliesToGroup,
            employees: x.appliesToUsers,
            equipment: x.appliesToEquipment,
            billable: x.isBillable,
            resourceTypeId: x.resourceTypeId,
            isSurcharge: x.isSurcharge,
            isPermit: x.isPermit,
        }));

        // merge additions with current charges
        updated = updated.concat([...selectedChargeTypes]);

        // reorder everything after the add
        updated = updated.map((x, index) => ({ ...x, ...{ order: (index += 1) } }));

        contract.contractChargeTypes = [...updated];

        this.setState({ contract: { ...contract } });
    };

    onAddJob = () => {
        this.props.history.push(
            `${AppNavPaths.JobNew}/${this.props.match.params.id}`,
        );
    };

    onAddPayScheduleCallback = (selections) => {
        const { contract } = { ...this.state };
        let updated = [...contract.contractPaySchedules] ?? [];

        // get selections from the popup
        const selectedPaySchedules = [...selections].map((x, index) => ({
            id: 0,
            contractId: contract.id,
            payScheduleId: x.id,
            paySchedule: {
                id: x.id,
                governingBody: x.governingBody,
                state: x.state,
                counties: x.counties,
                reg: x.reg,
                ot: x.ot,
                fringe: x.fringe,
            },
        }));

        const contractHasStateOnlyPS = contract.contractPaySchedules.filter(
            (x) => x.paySchedule.counties == null
                || x.paySchedule.counties.length == 0,
        );
        const selectionsStateOnlyPS = selectedPaySchedules.filter(
            (x) => x.paySchedule.counties == null
                || x.paySchedule.counties.length == 0,
        );
        const contractHasItems = contract.contractPaySchedules != null
            && contract.contractPaySchedules.length > 0;

        // Contract has a state only pay schedule, nothing else can be added
        if (contractHasStateOnlyPS?.length > 0) {
            toasty.error(
                'Pay Schedule Conflict',
                'There is a pay schedule for the entire state. No more pay schedules can be added.',
            );
            return;
        }

        // Selections contain a state only pay schedule, but contract already has a pay schedule
        if (selectionsStateOnlyPS.length > 0 && contractHasItems) {
            toasty.error(
                'Pay Schedule Conflict',
                'Unable to add pay schedule for the entire state due to an existing pay schedule.',
            );
            return;
        }

        // Selections contain a state only pay schedule, with multiple selections
        if (
            selectionsStateOnlyPS.length > 0
            && selectedPaySchedules.length > 1
        ) {
            toasty.error(
                'Pay Schedule Conflict',
                'Cannot add other pay schedules with a pay schedule for the entire state.',
            );
            return;
        }

        // merge additions with current charges
        updated = updated.concat([...selectedPaySchedules]);
        contract.contractPaySchedules = [...updated];

        this.setState({ contract: { ...contract } });
    };

    // #endregion

    onAddPaySchedules = () => {
        const { contract } = { ...this.state };
        const { contractPaySchedules } = contract;

        const paySchedulesNoCounties = contract.contractPaySchedules.filter(
            (x) => x.paySchedule.counties == null
                || x.paySchedule.counties.length == 0,
        );
        if (paySchedulesNoCounties?.length > 0) {
            toasty.error(
                'Pay Schedule Conflict',
                'There is a pay schedule for the entire state. No more pay schedules can be added.',
            );
            return;
        }

        const itemsToOmit = (contractPaySchedules ?? []).map((c) => c.payScheduleId) ?? [];

        this.payScheduleSelectorRef.current.open(null, [...itemsToOmit]);
    };

    onAddRate = (chargeType) => {
        const selectedRate = { ...new ContractChargeTypeBillingRate() };
        selectedRate.chargeTypeId = chargeType.chargeTypeId;
        selectedRate.chargeTypeName = chargeType.description;
        selectedRate.contractId = chargeType.contractId;

        this.rateFormRef.current.resetForm(); // clear validation
        this.setState((state) => {
            state.selectedChargeType = chargeType;
            state.selectedRate = selectedRate;
            state.showRateForm = true;
            return state;
        });
        this.context.setFormOpened(true); // lock all other page fields while form is open
    };

    onAllowLinkedWorkOrdersChanged = (value) => {
        const { contract } = { ...this.state };
        contract.allowLinkedWorkOrders = value;
        this.setState({ contract });
    };

    onAllowNonFlaggingChanged = (value) => {
        const { contract } = { ...this.state };
        contract.enableNonFlaggingTimesheets = value;
        // FOR NOW, PER CW:
        // Hide the allow self-dispatch button (since self-dispatch isn't yet finished)
        // and default to true when the non-flag contract is set up.
        contract.allowSelfDispatch = !!value;
        this.setState({ contract });
    };

    onAllowSelfDispatchChanged = (value) => {
        const { contract } = { ...this.state };
        contract.allowSelfDispatch = value;
        // When disabling self-dispatch, non-flag should also be disabled.
        if (!value) {
            contract.enableNonFlaggingTimesheets = false;
        }
        this.setState({ contract });
    };

    onAutoEscalatingRatesChanged = (value) => {
        const { contract } = { ...this.state };
        contract.autoEscalatingRates = value;
        this.setState({ contract });
    };

    onCTGroupChanged = (i, ev) => {
        const { contract } = { ...this.state };
        const val = ev.target.value;
        const { name } = ev.target;

        contract.contractChargeTypeGroups[i][name] = val;

        this.setState({ contract: { ...contract } });
    };

    // #endregion

    // #region BASE FORM EVENTS
    onChange = onFieldChange;

    onChargeTypeGroupSelectChanged = (selections) => {
        this.setState({
            selectedCTGroups: selections,
        });
    };

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    /**
     * When the customer selection changes, populate customer specific inputs.
     *
     * @param {any} customer
     */
    onCustomerChanged = async (customer) => {
        const { contract } = this.state;
        const companyId = (customer ?? {}).value ?? null;

        contract.companyId = companyId;

        if (companyId) {
            const [
                centers,
                chargeTypes,
                allowAlternativePayroll,
                operationsCentersApply,
                defaultTerms,
                defaultMapping,
            ] = await Promise.all([
                util.fetch.js(
                    ApiRoutes.company.customerOperationsCentersCompanies(
                        companyId,
                    ),
                ),
                this.getChargeTypesForCustomer(companyId),
                util.fetch.js(
                    ApiRoutes.company.allowAlternativePayroll(companyId),
                ),
                util.fetch.js(
                    ApiRoutes.company.operationsCentersApply(companyId),
                ),
                this.getDefaultTerms(companyId),
                this.getDefaultMapping(companyId),
            ]);

            contract.invoiceTermsId = defaultTerms;
            contract.invoiceMappingId = defaultMapping;
            contract.chargeTypes = chargeTypes;
            contract.hasAlternativePayroll = allowAlternativePayroll;
            contract.operationsCentersApply = operationsCentersApply;

            let billingContacts = [];

            if (contract.companyId) {
                const [contacts] = await Promise.all([
                    util.fetch
                        .js(
                            ApiRoutes.typeAheads.billingContacts(
                                contract.companyId,
                            ),
                        )
                        .catch((error) => console.log('Unable to get the billing contacts.')),
                ]);
                if (contacts) {
                    billingContacts = contacts.map((x) => ({ label: x.label, value: x.value }));
                }
            }

            this.setState({
                contract,
                operationsCenters: centers.map((x) => ({ label: x.companyName, value: x.id })),
                billingContacts,
                customerChargeTypes: chargeTypes,
            });
        } else {
            contract.invoiceTermsId = null;
            contract.invoiceMappingId = null;
            contract.chargeTypes = [];
            contract.hasAlternativePayroll = false;
            contract.operationsCentersApply = false;

            this.setState({
                contract: contract,
                operationsCenters: [],
                billingContacts: [],
                customerChargeTypes: [],
            });
        }
    };

    onDelete = async (e) => {
        const { contract } = this.state;
        const response = await util.fetch.delete(
            ApiRoutes.contract.delete(contract.id),
        );
        if (response) this.onClose(response);
    };
    // #endregion

    onEditCTGroup = (group) => {
        const { contract } = { ...this.state };
        const { contractChargeTypeGroups } = { ...contract };

        const index = contractChargeTypeGroups.findIndex(
            (x) => x.chargeTypeGroupId === group.chargeTypeGroupId,
        );
        contractChargeTypeGroups[index].editing = true;
        this.setState({ contract: { ...contract } });
    };

    onEditRate = (chargeType) => {
        const rate = chargeType.billingRates[0];
        const selectedRate = { ...new ContractChargeTypeBillingRate() };

        const cloned = util.object.clone(rate);
        selectedRate.billingRate = cloned.billingRate;
        selectedRate.billingRateId = cloned.billingRateId;
        selectedRate.chargeTypeId = chargeType.chargeTypeId;
        selectedRate.chargeTypeName = chargeType.description;
        selectedRate.contractId = chargeType.contractId;

        this.rateFormRef.current.resetForm();
        this.setState({
            selectedChargeType: chargeType,
            selectedRate,
            showRateForm: true,
            showRateFormDelete: true,
        });
        this.context.setFormOpened(true);
    };

    onEmailAdded = (e) => {
        const { currentEmailAddress, contract } = { ...this.state };
        if (currentEmailAddress) {
            const emailAddress = currentEmailAddress.trim();
            const match = util.validation.email(emailAddress);
            if (match) {
                if (!(contract.timesheetEmailRecipients ?? []).length) contract.timesheetEmailRecipients = [];
                if (!contract.timesheetEmailRecipients.includes(emailAddress)) contract.timesheetEmailRecipients.push(emailAddress);
                this.setState({
                    contract: { ...contract },
                    currentEmailAddress: null,
                });
            } else {
                toasty.error('Please enter a single valid email address.');
            }
        }
    };

    // #endregion

    // #region EMAIL
    onEmailChanged = (e) => {
        const val = e.currentTarget.value;
        this.setState({ currentEmailAddress: val });
    };

    onEmailRemoved = (selected) => {
        const { contract } = { ...this.state };
        const inx = contract.timesheetEmailRecipients.findIndex(
            (ea) => ea === selected,
        );
        contract.timesheetEmailRecipients.splice(inx, 1);
        this.setState({ contract });
    };

    onPermitFeesApplyChanged = (value) => {
        const { contract } = { ...this.state };
        contract.permitFeesApply = value;
        this.setState({ contract });
    };

    onPrevailingWageChanged = (value) => {
        const { contract } = { ...this.state };
        contract.prevailingWage = value;
        this.setState({ contract });
    };

    onRateFormClosed = () => {
        this.rateFormRef.current.resetForm(); // clear validation
        this.context.setFormOpened(false); // lock all other page fields while form is open
        this.setState({
            showRateForm: false,
            showRateFormDelete: false,
        });
    };

    onRateRemoved = () => {
        const { contract, selectedChargeType } = this.state;

        const chargeType = contract.contractChargeTypes.find(
            (x) => x.chargeTypeId == selectedChargeType.chargeTypeId,
        );
        chargeType.billingRates = [];

        this.rateFormRef.current.resetForm();
        this.setState({
            contract,
            showRateForm: false,
        });
    };

    onRateSaved = () => {
        const { contract, selectedRate, selectedChargeType } = this.state;

        const chargeType = contract.contractChargeTypes.find(
            (x) => x.chargeTypeId == selectedChargeType.chargeTypeId,
        );

        if (chargeType.billingRates.length == 0) {
            selectedRate.billingRate.id = 0;

            chargeType.billingRates.push({
                billingRate: selectedRate.billingRate,
                billingRateId: 0,
                contractBillingRateId: 0,
            });
        } else {
            chargeType.billingRates[0].billingRate = selectedRate.billingRate;
        }

        this.rateFormRef.current.resetForm();
        this.setState({
            showRateForm: false,
            contract,
        });
    };

    onRemoveCTGroup = (group) => {
        const { contract } = { ...this.state };
        const { contractChargeTypeGroups } = { ...contract };

        const index = contractChargeTypeGroups.findIndex(
            (x) => x.chargeTypeGroupId === group.chargeTypeGroupId,
        );
        contractChargeTypeGroups.splice(index, 1);
        this.setState({ contract: { ...contract } });
    };

    onRemoveChargeType = (chargeTypeIndex) => {
        const { contract } = { ...this.state };
        const chargeTypes = [...contract.contractChargeTypes];
        chargeTypes.splice(chargeTypeIndex, 1);
        contract.contractChargeTypes = [...chargeTypes];
        this.setState({ contract: { ...contract } });
    };

    onRemovePaySchedule = (payScheduleIndex) => {
        const { contract } = { ...this.state };

        const paySchedules = [...contract.contractPaySchedules];
        paySchedules.splice(payScheduleIndex, 1);
        contract.contractPaySchedules = [...paySchedules];

        this.setState({ contract: { ...contract } });
    };

    onReorderChargeTypes = () => {
    // clone the list of charge types, to avoid mutating state.
    // pass to the widget.
        this.setState((state) => {
            const { contract } = { ...state };
            const selectedSortableChargeTypes = contract.contractChargeTypes;
            return {
                showReorderWidget: true,
                selectedSortableChargeTypes,
            };
        });
    };

    onRequireSelfDispatchTimesheetsClientSignatureChanged = (value) => {
        const { contract } = { ...this.state };
        contract.requireSelfDispatchTimesheetsClientSignature = value;
        this.setState({ contract });
    };

    onScheduleTypeChanged = (id) => {
        const { contract } = { ...this.state };
        contract.contractScheduleTypeId = id;
        this.setState({ contract });
    };

    onSelectChanged = onReactSelectChanged;

    onSetupChargesRequiredChanged = (value) => {
        const { contract } = { ...this.state };
        contract.setupChargesRequired = value;
        this.setState({ contract });
    };

    onSubmit = async (e) => {
        const { contract } = { ...this.state };

        // if (
        //    contract.prevailingWage == true &&
        //    contract.contractPaySchedules.length == 0
        // ) {
        //    toasty.error(
        //        'Prevailing wage contracts must have at least one pay schedule assigned.'
        //    );
        //    return;
        // }

        // Clear any fluent api errors
        this.setState((state) => ({ errors: {}, saving: true }));

        !contract.id ? this.create(contract) : this.update(contract);
    };

    // Customer Exceptions
    getChargeTypesForCustomer = async (customerId) => {
        const response = await util.fetch
            .js(ApiRoutes.chargeType.byCustomer(customerId))
            .catch((err) => {
                console.log(
                    `Failed to get charge types for selected customer: ${err.toString()}`,
                );
            });
        return response ?? [];
    };

    // #endregion

    getContractTimesheetResponsibilityTypeOptions(crewLead) {
    // Moved from Tenant.js since we're using localized strings now.

        const ret = [
            {
                id: 1,
                name: crewLead,
                description: `${crewLead}s are responsible for all timesheet entry.`,
            },
            {
                id: 2,
                name: 'Individual',
                description: 'Individual employees enter timesheets.',
            },
        ];

        return ret;
    }

    getDefaultMapping = async (companyId) => {
        const response = await util.fetch
            .js(ApiRoutes.company.defaultMapping(companyId))
            .catch((err) => {
                console.log('No mapping setup for selected customer}');
            });
        return response ?? null;
    };

    getDefaultTerms = async (companyId) => {
        const response = await util.fetch
            .js(ApiRoutes.company.defaultTerms(companyId))
            .catch((err) => {
                console.log('No terms setup for selected customer}');
            });
        return response ?? null;
    };

    getOperationsCentersForCustomer = async (customerId) => {
        const response = await util.fetch
            .js(
                ApiRoutes.company.customerOperationsCentersCompanies(customerId),
            )
            .catch((err) => {
                console.log(
                    `Failed to get Operations Centers for selected customer: ${err.toString()}`,
                );
            });
        if (response) {
            return util.select.mapToOptions(response, 'locationName', 'id');
        }
        return [];
    };

    create = async (contract) => {
        contract.effectiveDate = null;
        contract.expirationDate = null;

        const response = await util.fetch.andGetResponse(
            util.fetch.types.post,
            ApiRoutes.contract.create(),
            { ...contract },
            'Error Creating Contract',
        );

        if (response.result === ServiceResponseResult.Ok) {
            toasty.success('Contract created.');
            this.props.history.push(`${AppNavPaths.Contract}/${response.data}`);
        } else {
            toasty.error('Problem saving the contract:', response.message, {
                autoClose: false,
                closeOnClick: true,
            });
        }

        this.setState({ saving: false });
    };

    // #endregion

    // #region SUBMISSION
    handleSaveError = handleFormSaveError;

    notifyError = () => toasty.error(
        'Save Unsuccessful',
        'There was a server error saving this contract.  Please try your request again or contact support for assistance.',
    );

    notifySuccess = () => toasty.success('Contract Saved', 'Contract saved successfully.');

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const contract_id = this.props.match.params.id;

            // RLC: A note on this: This will not always be available because React's context is async.
            // It also bears no events for when it is ready, either.
            const { user } = this.context;

            // RLC: This is ultimately unused in the service, and probably should be cleaned up.
            // All tenant customers are returned.
            const companyId = (user ?? {}).companyId ?? 1;
            let chargeTypeGroups = [];

            const [
                strings,
                customers,
                dispatchLocations,
                contractStatuses,
                contract,
                contractTemplates,
                billingCompanies,
                invoiceTerms,
                invoiceMappings,
                chargeTypes,
            ] = await Promise.all([
                util.l10n.getStrings([l.crewLead]),
                util.fetch
                    .js(ApiRoutes.company.customerCompanies(companyId))
                    .catch((error) => console.log('Unable to get customers.')),
                util.fetch
                    .js(ApiRoutes.company.dispatchCompanies())
                    .catch((error) => console.log('Unable to get dispatch dispatchLocations.')),
                util.fetch
                    .js(ApiRoutes.typeAheads.contractStatuses())
                    .catch((error) => console.log('Unable to get contract statuses.')),
                contract_id
                    ? util.fetch
                        .js(ApiRoutes.contract.byId(contract_id))
                        .catch((error) => console.log('Unable to get contract.'))
                    : new Contract(),
                util.fetch.js(ApiRoutes.contractTemplate.names()),
                util.fetch
                    .js(ApiRoutes.typeAheads.billingCompanies())
                    .catch((error) => console.log('Unable to get billing companies.')),
                util.fetch
                    .js(ApiRoutes.typeAheads.invoiceTerms())
                    .catch((error) => console.log('Unable to get payment terms.')),
                util.fetch
                    .js(ApiRoutes.typeAheads.invoiceMapping())
                    .catch((error) => console.log('Unable to get contract mapping.')),
                util.fetch
                    .js(ApiRoutes.typeAheads.chargeTypesBillable())
                    .catch((error) => console.log('Unable to get charge types.')),
            ]);

            const glStringsEnabled = Boolean(
                this.context.tenant?.tenantSettings?.glStringsEnabled,
            );

            const complianceEnabled = Boolean(
                this.context.tenant?.tenantSettings?.complianceEnabled,
            );

            const enableContractDateRange = Boolean(
                this.context.tenant?.tenantSettings?.enableContractDateRange,
            );

            const enableAutoEscalatingContractRates = Boolean(
                this.context.tenant?.tenantSettings
                    ?.enableAutoEscalatingContractRates,
            );

            const enablePrevailingWageContracts = Boolean(
                this.context.tenant?.tenantSettings
                    ?.enablePrevailingWageContracts,
            );

            const complianceTypeData = await getComplianceTypeData();

            const { complianceTypesForAllDispatches } = complianceTypeData;
            const { allAvailableUserComplianceTypes } = complianceTypeData;
            const { allAvailableEquipmentComplianceTypes } = complianceTypeData;

            const equipmentComplianceTypesForAllDispatches = getSelectListOfEquipmentComplianceTypesForAllDispatches(
                complianceTypesForAllDispatches,
            );

            const personComplianceTypesForAllDispatches = getSelectListOfUserComplianceTypesForAllDispatches(
                complianceTypesForAllDispatches,
            );

            const availableUserComplianceTypes = getSelectListOfAvailableUserComplianceTypes(
                allAvailableUserComplianceTypes,
            );

            const availableEquipmentComplianceTypes = getSelectListOfAvailableEquipmentComplianceTypes(
                allAvailableEquipmentComplianceTypes,
            );

            const warningThresholdOptions = [
                { label: '30', value: 30 },
                { label: '60', value: 60 },
                { label: '90', value: 90 },
            ];

            let operationsCenters = [];

            if (contract_id) {
                if (contract.companyId) {
                    chargeTypeGroups = await util.fetch
                        .js(ApiRoutes.typeAheads.companyChargeTypeGroups(contract.companyId))
                        .catch((error) => console.log('Unable to get charge type groups.'));

                    const [centers] = await Promise.all([
                        util.fetch
                            .js(
                                ApiRoutes.company.customerOperationsCentersCompanies(
                                    contract.companyId,
                                ),
                            )
                            .catch((error) => console.log(
                                'Unable to get the operations centers.',
                            )),
                    ]);
                    if (centers) {
                        operationsCenters = centers.map((x) => ({ label: x.companyName, value: x.id }));
                    }
                }

                const [contractCompliances] = await Promise.all([
                    util.fetch
                        .js(
                            ApiRoutes.contractCompliances.allForContract(
                                contract_id,
                            ),
                        )
                        .catch((error) => console.log(
                            'Unable to get the compliances for this contract.',
                        )),
                ]);

                const selectedEquipmentComplianceTypeIds = getSelectedEquipmentComplianceTypeIds(
                    contractCompliances,
                    allAvailableEquipmentComplianceTypes,
                );

                const selectedUserComplianceTypeIds = getSelectedUserComplianceTypeIds(
                    contractCompliances,
                    allAvailableUserComplianceTypes,
                );

                contract.equipmentCompliances = selectedEquipmentComplianceTypeIds;
                contract.userCompliances = selectedUserComplianceTypeIds;
            }

            let billingContacts = [];

            if (contract_id) {
                if (contract.companyId) {
                    const [contacts] = await Promise.all([
                        util.fetch
                            .js(
                                ApiRoutes.typeAheads.billingContacts(
                                    contract.companyId,
                                ),
                            )
                            .catch((error) => console.log(
                                'Unable to get the billing contacts.',
                            )),
                    ]);
                    if (contacts) {
                        billingContacts = contacts.map((x) => ({ label: x.label, value: x.value }));
                    }
                }
            }

            const originalData = JSON.stringify({ ...contract });

            this.setState({
                strings,
                chargeTypes,
                contractStatuses,
                contractTemplates: [...contractTemplates],
                dispatchLocations: dispatchLocations.map((x) => ({ label: x.companyName, value: x.id })),
                loading: false,
                contract,
                originalData,
                customers: customers.map((x) => ({ label: x.companyName, value: x.id })),
                operationsCenters,
                saving: false,
                equipmentComplianceTypesForAllDispatches,
                personComplianceTypesForAllDispatches,
                availableUserComplianceTypes,
                availableEquipmentComplianceTypes,
                complianceEnabled,
                billingCompanies,
                billingContacts,
                invoiceTerms,
                invoiceMappings,
                chargeTypeGroups,
                enableContractDateRange,
                warningThresholdOptions,
                enableAutoEscalatingContractRates,
                enablePrevailingWageContracts,
                glStringsEnabled,
            });
        }
    }

    resetForm = () => this.setState({ formValidated: false, saving: false });

    toggleCTGroupModal = async () => {
        const { showCTGroupModal } = this.state;

        this.setState({
            showCTGroupModal: !showCTGroupModal,
        });
    };

    update = async (contract) => {
        const response = await util.fetch.andGetResponse(
            util.fetch.types.put,
            ApiRoutes.contract.update(contract.id),
            { ...contract },
            'Error Saving Contract',
        );

        if (response.result === ServiceResponseResult.Ok) {
            toasty.success('Contract saved.');

            this.props.history.go(0);
        } else {
            toasty.error('Problem saving the contract:', response.message, {
                autoClose: false,
                closeOnClick: true,
            });
        }
        this.setState({ saving: false });
    };

    updateChargeTypeEligibility = (index, eligibilityId) => {
        const { contract } = { ...this.state };
        const chargeType = { ...contract.contractChargeTypes[index] };
        chargeType.chargeTypeEligibilityId = eligibilityId;
        const chargeTypes = [...contract.contractChargeTypes];
        chargeTypes[index] = { ...chargeType };
        contract.contractChargeTypes = [...chargeTypes];
        this.setState({ contract: { ...contract } });
    };

    renderContractChargeTypesRows = () => {
        const { contract } = { ...this.state };
        const { contractChargeTypes } = contract;

        const tenantSettings = ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        const isTenantBillingRatesAllowed = !!tenantSettings.billingRatesEnabled;

        if (!contractChargeTypes) return '';

        return contractChargeTypes.map((chargeType, chargeTypeIndex) => {
            // User must select an eligibility
            const eligibilityValid = !!this.state.formValidated
                && !chargeType.chargeTypeEligibilityId;

            // Is this an exception for the currently selected customer on the contract?
            const isException = (
                (
                    (chargeType.configurations ?? []).find(
                        (x) => x.chargeTypeConfigurationTypeId
                            == ChargeTypeConfigurationType.Exception,
                    ) ?? {}
                ).customers ?? []
            ).includes(contract.companyId);

            let overiddenContractChargeType = '';
            if (
                chargeType.billingRates != null
                && chargeType.billingRates.length > 0
            ) {
                const { billingRate } = chargeType.billingRates[0];
                overiddenContractChargeType = `Rate $${
                    billingRate.rate != null ? billingRate.rate : ' '
                }\r\n`
                    + `OT $${
                        billingRate.otRate != null ? billingRate.otRate : ' '
                    }\r\n`
                    + `ALT $${
                        billingRate.altRate != null ? billingRate.altRate : ' '}`;
            }

            if (chargeType.chargeTypeBillingRates == undefined) chargeType.chargeTypeBillingRates = '';

            const redirectToOrgUrl = `./organization/${contract.companyId}#billingRates`;

            return (
                <tr key={chargeTypeIndex}>
                    <td className="text-center">{chargeType.order ?? ''}</td>
                    <td>
                        {chargeType.description}
                        {!!isException && (
                            <span className="exception-badge badge badge-warning ml-2">
              E
                            </span>
                        )}
                    </td>
                    <td className="text-center">{chargeType.chargeUnits}</td>
                    <td>{chargeType.group}</td>
                    <td>{chargeType.employees}</td>
                    <td>{chargeType.equipment}</td>
                    {
                        // Column count must match chargeTypeHeaderLabels in render method.
                        isTenantBillingRatesAllowed && (
                            <>
                                <td>
                                    <table
                                        style={{
                                            width: '100%',
                                            border: 'none',
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        border: 'none',
                                                        padding: 0,
                                                    }}
                                                >
                                                    <pre className="mb-0">
                                                        {
                                                            chargeType.chargeTypeBillingRates
                                                        }
                                                    </pre>
                                                </td>
                                                {chargeType
                                                    .chargeTypeBillingRates
                                                    .length > 0 && (
                                                    <td
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: 'none',
                                                            padding: 0,
                                                            paddingLeft: '6px',
                                                            width: '100%',
                                                            height: '100%',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <a
                                                            href={
                                                                redirectToOrgUrl
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i className="fa fa-info-circle" />
                                                        </a>
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <table
                                        style={{
                                            width: '100%',
                                            border: 'none',
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        border: 'none',
                                                        padding: 0,
                                                    }}
                                                >
                                                    <pre>
                                                        {
                                                            overiddenContractChargeType
                                                        }
                                                    </pre>
                                                </td>
                                                {overiddenContractChargeType.length
                                                    > 0 && (
                                                    <td
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: 'none',
                                                            padding: 0,
                                                            paddingLeft: '6px',
                                                            width: '100%',
                                                            height: '100%',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                this.onEditRate(
                                                                    chargeType,
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-edit fa-md mr-2" />
                                                        </div>
                                                    </td>
                                                )}
                                                {overiddenContractChargeType.length
                                                    == 0 && (
                                                    <td
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: 'none',
                                                            padding: 0,
                                                            paddingLeft: '6px',
                                                            width: '100%',
                                                            height: '100%',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                this.onAddRate(
                                                                    chargeType,
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-plus-circle fa-md mr-2" />
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </>
                        )
                    }
                    <td className="text-center">
                        <YesNoBadge value={chargeType.billable} />
                    </td>
                    <Can I="edit" a="contract">
                        <CommonContext.Consumer>
                            {(value) => (
                                <>
                                    <td
                                        className={cls({
                                            'p-0': eligibilityValid,
                                        })}
                                    >
                                        <div
                                            className={cls('h-100 w-100', {
                                                'p-2 border border-danger':
                                                        eligibilityValid,
                                            })}
                                        >
                                            <ButtonGroup
                                                disabled={
                                                    !!value.formIsOpen
                                                }
                                            >
                                                {ChargeTypeEligibilities.map(
                                                    (
                                                        eligibility,
                                                        eligibilityIndex,
                                                    ) => (
                                                        <Button
                                                            type="button"
                                                            color="secondary"
                                                            size="sm"
                                                            key={
                                                                eligibilityIndex
                                                            }
                                                            className={cls(
                                                                'pt-0 pb-0 pl-1 pr-1 mr-1',
                                                                {
                                                                    active:
                                                                            eligibility.id
                                                                            === chargeType.chargeTypeEligibilityId,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                this.updateChargeTypeEligibility(
                                                                    chargeTypeIndex,
                                                                    eligibility.id,
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                eligibility.name
                                                            }
                                                        </Button>
                                                    ),
                                                )}
                                            </ButtonGroup>
                                            <input
                                                type="text"
                                                className="hidden-validation"
                                                required
                                                defaultValue={
                                                    chargeType.chargeTypeEligibilityId
                                                        ?? ''
                                                }
                                            />
                                            <span
                                                className="invalid-feedback text-danger"
                                                hidden
                                            >
                        Eligibility selection is
                        required.
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            title={`Remove ${chargeType.description}`}
                                            className="text-danger cursor-pointer"
                                            onClick={() => this.onRemoveChargeType(
                                                chargeTypeIndex,
                                            )}
                                        />
                                    </td>
                                </>
                            )}
                        </CommonContext.Consumer>
                    </Can>
                </tr>
            );
        });
    };

    renderContractPaySchedules = () => {
        const { contract } = { ...this.state };
        const { contractPaySchedules } = contract;

        if (!contractPaySchedules) return '';

        return contractPaySchedules.map(
            (contractPaySchedule, contractPayScheduleIndex) => (
                <tr key={contractPayScheduleIndex}>
                    <td>{contractPaySchedule.paySchedule.governingBody}</td>
                    <td>{contractPaySchedule.paySchedule.state}</td>
                    <td>
                        {contractPaySchedule.paySchedule.counties == null
                                || contractPaySchedule.paySchedule.counties.length == 0
                            ? 'All'
                            : contractPaySchedule.paySchedule.counties}
                    </td>
                    <td>{contractPaySchedule.paySchedule.reg}</td>
                    <td>{contractPaySchedule.paySchedule.ot}</td>
                    <td>{contractPaySchedule.paySchedule.fringe}</td>
                    <td className="text-center">
                        <FontAwesomeIcon
                            icon={faTimes}
                            title={`Remove ${contractPaySchedule.governingBody}`}
                            className="text-danger cursor-pointer"
                            onClick={() => this.onRemovePaySchedule(
                                contractPayScheduleIndex,
                            )}
                        />
                    </td>
                </tr>
            ),
        );
    };

    // #region JOBS
    renderJobRows = () => {
        const jobs = this.state.contract.jobs.filter(
            (x) => x.isDeleted === false,
        );
        const { number } = this.state.contract;
        if (!jobs) return '';
        return jobs.map((job, i) => (
            <tr key={job.id}>
                <td className="ct-job-number">
                    <Link
                        className="site-link"
                        title="View this Job"
                        to={`${AppNavPaths.Job}/${job.id}`}
                    >
                        {`${number}-${job.contractSequence}`}
                    </Link>
                </td>
                <td className="ct-job-name">{job.description}</td>
                <td className="ct-job-week">{job.weekOfDate}</td>
                <td className="ct-job-active">
                    <YesNoBadge value />
                </td>
            </tr>
        ));
    };

    render() {
        const {
            strings,
            contract,
            contractStatuses,
            contractTemplates,
            customers,
            errors,
            formValidated,
            fromTemplate,
            dispatchLocations,
            operationsCenters,
            originalData,
            saving,
            currentEmailAddress,
            equipmentComplianceTypesForAllDispatches,
            personComplianceTypesForAllDispatches,
            availableUserComplianceTypes,
            availableEquipmentComplianceTypes,
            complianceEnabled,
            billingCompanies,
            billingContacts,
            invoiceTerms,
            invoiceMappings,
            chargeTypeGroups,
            enableContractDateRange,
            warningThresholdOptions,
            enableAutoEscalatingContractRates,
            enablePrevailingWageContracts,
            chargeTypes,
            showRateFormDelete,
            glStringsEnabled,
            showCTGroupModal,
        } = this.state;
        const existing = !!contract.id;

        const crewLead = strings ? strings[l.crewLead] : '';
        const contractTimesheetResponsibilityTypeOptions = this.getContractTimesheetResponsibilityTypeOptions(crewLead);

        // Computed
        const customerHasOperationsCenters = (operationsCenters ?? []).length > 0;

        const isLoading = !!this.state.loading
            || !this.context?.tenant?.tenantSettings
            || !this.context?.user;

        const newContractFromTemplate = Boolean(
            !!fromTemplate && !contract?.id,
        );

        const selfDispatchTimesheetsEnabled = !!this.context?.tenant?.tenantSettings
            ?.enableSelfDispatchTimesheets;

        const showSelfDispatchOptions = !!contract?.allowSelfDispatch // Contract must be set up for self-dispatching
            && !!selfDispatchTimesheetsEnabled; // Tenant setting must also be enabled for self-dispatch timesheets.

        const jContract = JSON.stringify({ ...contract });

        const tenantSettings = ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        const isInvoicingAllowed = !!tenantSettings.invoicingEnabled;
        const isChargeTypeGroupsAllowed = !!tenantSettings.chargeTypeGroupsEnabled;
        const isBillingRatesEnabled = !!tenantSettings.billingRatesEnabled;

        // Rates column will be available depending on whether or not the tenant
        // has billing rates enabled.
        // If you alter the columns here, update this.renderContractChargeTypesRows as well.
        const chargeTypeHeaderLabels = [
            { name: 'Order', class: 'text-center' },
            { name: 'Description' },
            { name: 'Charge Units', class: 'text-center' },
            { name: 'Group' },
            { name: 'Employees' },
            { name: 'Equipment' },
            { name: 'Org Rates' },
            { name: 'Contract Rates' },
            { name: 'Billable', class: 'text-center' },
            { name: 'Job Eligibility' },
            { name: '', class: 'text-center' },
        ];

        const yesNoOptions = [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        // 6 === index of Rates column.  Could use findIndex too.
        // Remove Rates column if it isn't needed.
        if (!isBillingRatesEnabled) chargeTypeHeaderLabels.splice(6, 1);

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, jContract)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formId="contractForm"
                    formHeadingIcon={faFileContract}
                    formHeading={!existing ? 'New Contract' : 'Edit Contract'}
                    formName="contractForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    loading={isLoading}
                    loadingMessage="Loading Contract, please wait..."
                    onBack={() => this.props.history.push(AppNavPaths.Contracts)}
                    backLabel="Back to Contracts"
                >
                    {!contract.id && (
                        <>
                            <SubHeading className="mt-3">
              Contract Type
                            </SubHeading>
                            <Row className="pt-3">
                                <Col
                                    xl="6"
                                    lg="8"
                                    md="10"
                                    sm="12"
                                    className="ml-auto mr-auto"
                                >
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="fromTemplate"
                                            text=""
                                        />
                                        <ButtonGroup
                                            id="fromTemplate"
                                            name="fromTemplate"
                                        >
                                            <Button
                                                size="sm"
                                                color={
                                                    fromTemplate === true
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                className={cls('p-2', {
                                                    active:
                                                        fromTemplate === true,
                                                })}
                                                onClick={() => this.setState({
                                                    fromTemplate: true,
                                                })}
                                            >
                      From Template
                                            </Button>
                                            <Button
                                                size="sm"
                                                color={
                                                    fromTemplate === false
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                className={cls('p-2', {
                                                    active:
                                                        fromTemplate === false,
                                                })}
                                                onClick={() => this.setState({
                                                    fromTemplate: false,
                                                })}
                                            >
                      Custom
                                            </Button>
                                        </ButtonGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}
                    <SubHeading>Details</SubHeading>
                    <Row>
                        <Col
                            xl="6"
                            lg="8"
                            md="10"
                            sm="12"
                            className="ml-auto mr-auto"
                        >
                            <FormGroup>
                                <FormLabel
                                    htmlFor="customerName"
                                    text="Customer"
                                    required
                                />
                                <ValidatedSelect
                                    id="customers"
                                    name="contract.companyId"
                                    options={customers}
                                    required
                                    value={
                                        (customers ?? []).find(
                                            (s) => s.value === contract.companyId,
                                        ) ?? ''
                                    }
                                    onChange={this.onCustomerChanged}
                                    validationMessage="A customer selection is required."
                                />
                            </FormGroup>
                            <FormGroup
                                hidden={
                                    !customerHasOperationsCenters
                                    || !contract.operationsCentersApply
                                }
                            >
                                <FormLabel
                                    htmlFor="operationsCenter"
                                    text="Operations Center"
                                    required
                                />
                                <ValidatedSelect
                                    id="operationsCenters"
                                    name="contract.operationsCenterCompanyId"
                                    options={operationsCenters}
                                    required={
                                        !!customerHasOperationsCenters
                                        && contract.operationsCentersApply
                                    }
                                    value={
                                        (operationsCenters ?? []).find(
                                            (s) => s.value
                                                === contract.operationsCenterCompanyId,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="A customer selection is required."
                                />
                                <small className="invalid-feedback text-danger">
                  Operations Center is required.
                                </small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="number"
                                    text="Contract Number"
                                    required
                                />
                                <Input
                                    id="number"
                                    name="contract.number"
                                    value={contract.number ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Number (letter and digits only)"
                                    maxLength="150"
                                    pattern="[a-zA-Z0-9\-]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">
                  Number is required.
                                </small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="description"
                                    text="Description"
                                    required
                                />
                                <Input
                                    id="description"
                                    name="contract.description"
                                    className="form-control"
                                    value={contract.description ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Description (max 500 characters)"
                                    type="text"
                                    maxLength="500"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="dispatchLocations"
                                    text="Dispatch Locations"
                                    required
                                />
                                <ValidatedSelect
                                    id="dispatchLocations"
                                    name="contract.dispatchLocations"
                                    options={dispatchLocations}
                                    isMulti
                                    required
                                    value={
                                        (dispatchLocations ?? []).filter((x) => (
                                            contract.dispatchLocations ?? []
                                        ).includes(x.value)) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="A Dispatching selection is required."
                                />
                            </FormGroup>

                            {!!this.context.tenant?.tenantSettings
                                .billingEnabled && (
                                <>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="billingCompany"
                                            text="Billing Company"
                                        />
                                        <ValidatedSelect
                                            id="billingCompany"
                                            name="contract.billingCompanyId"
                                            options={billingCompanies}
                                            value={
                                                (billingCompanies ?? []).find(
                                                    (x) => contract.billingCompanyId
                                                            === x.value,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="billingContact"
                                            text="Billing Contact"
                                        />
                                        <ValidatedSelect
                                            id="billingContact"
                                            name="contract.billingContactId"
                                            options={billingContacts}
                                            value={
                                                (billingContacts ?? []).find(
                                                    (x) => contract.billingContactId
                                                            === x.value,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                        />
                                    </FormGroup>

                                    {isInvoicingAllowed && (
                                        <>
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="invoiceTerms"
                                                    text="Payment Terms"
                                                />
                                                <ValidatedSelect
                                                    id="invoiceTerms"
                                                    name="contract.invoiceTermsId"
                                                    options={invoiceTerms}
                                                    value={
                                                        (
                                                            invoiceTerms ?? []
                                                        ).find(
                                                            (x) => contract.invoiceTermsId
                                                                    === x.value,
                                                        ) ?? ''
                                                    }
                                                    onChange={
                                                        this.onSelectChanged
                                                    }
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="invoiceMappings"
                                                    text="Invoice Mapping"
                                                />
                                                <ValidatedSelect
                                                    id="invoiceMappings"
                                                    name="contract.invoiceMappingId"
                                                    options={invoiceMappings}
                                                    value={
                                                        (
                                                            invoiceMappings
                                                                ?? []
                                                        ).find(
                                                            (x) => contract.invoiceMappingId
                                                                    === x.value,
                                                        ) ?? ''
                                                    }
                                                    onChange={
                                                        this.onSelectChanged
                                                    }
                                                />
                                            </FormGroup>

                                            {glStringsEnabled && (
                                                <FormGroup>
                                                    <FormLabel
                                                        htmlFor="requireGLString"
                                                        text="Require GL String"
                                                    />
                                                    <ValidatedSelect
                                                        id="requireGLString"
                                                        name="contract.requireGLString"
                                                        options={yesNoOptions}
                                                        value={
                                                            (
                                                                yesNoOptions
                                                                    ?? []
                                                            ).find(
                                                                (x) => contract.requireGLString
                                                                        === x.value,
                                                            ) ?? ''
                                                        }
                                                        onChange={
                                                            this.onSelectChanged
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {complianceEnabled && (
                                <>
                                    {!newContractFromTemplate
                                        && ((personComplianceTypesForAllDispatches
                                            && personComplianceTypesForAllDispatches.length
                                            > 0)
                                            || (availableUserComplianceTypes
                                                && availableUserComplianceTypes.length
                                                > 0)) && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="fixedPersonCompliances"
                                                text="Role Compliances Automatically Applied"
                                            />
                                            <ValidatedSelect
                                                id="fixedPersonCompliances"
                                                options={
                                                    personComplianceTypesForAllDispatches
                                                }
                                                value={
                                                    personComplianceTypesForAllDispatches
                                                }
                                                isMulti
                                                isDisabled
                                            />
                                        </FormGroup>
                                    )}

                                    {!newContractFromTemplate
                                        && availableUserComplianceTypes
                                        && availableUserComplianceTypes.length
                                        > 0 && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="userCompliances"
                                                text="Role Compliances"
                                                required={false}
                                            />
                                            <ValidatedSelect
                                                id="userCompliances"
                                                name="contract.userCompliances"
                                                options={
                                                    availableUserComplianceTypes
                                                }
                                                isMulti
                                                value={
                                                    (
                                                        availableUserComplianceTypes
                                                            ?? []
                                                    ).filter((x) => (
                                                        contract.userCompliances
                                                                ?? []
                                                    ).includes(x.value)) ?? ''
                                                }
                                                onChange={
                                                    this.onSelectChanged
                                                }
                                            />
                                        </FormGroup>
                                    )}

                                    {!newContractFromTemplate
                                        && ((equipmentComplianceTypesForAllDispatches
                                            && equipmentComplianceTypesForAllDispatches.length
                                            > 0)
                                            || (availableEquipmentComplianceTypes
                                                && availableEquipmentComplianceTypes.length
                                                > 0)) && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="fixedEquipmentCompliances"
                                                text="Equipment Type Compliances Automatically Applied"
                                            />
                                            <ValidatedSelect
                                                id="fixedEquipmentCompliances"
                                                options={
                                                    equipmentComplianceTypesForAllDispatches
                                                }
                                                value={
                                                    equipmentComplianceTypesForAllDispatches
                                                }
                                                isMulti
                                                isDisabled
                                            />
                                        </FormGroup>
                                    )}

                                    {!newContractFromTemplate
                                        && availableEquipmentComplianceTypes
                                        && availableEquipmentComplianceTypes.length
                                        > 0 && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="equipmentCompliances"
                                                text="Equipment Type Compliances"
                                                required={false}
                                            />
                                            <ValidatedSelect
                                                id="equipmentCompliances"
                                                name="contract.equipmentCompliances"
                                                options={
                                                    availableEquipmentComplianceTypes
                                                }
                                                isMulti
                                                value={
                                                    (
                                                        availableEquipmentComplianceTypes
                                                            ?? []
                                                    ).filter((x) => (
                                                        contract.equipmentCompliances
                                                                ?? []
                                                    ).includes(x.value)) ?? ''
                                                }
                                                onChange={
                                                    this.onSelectChanged
                                                }
                                            />
                                        </FormGroup>
                                    )}
                                </>
                            )}

                            <CommonContext.Consumer>
                                {(value) => {
                                    if (!(value ?? {}).tenant) return null;

                                    return (
                                        value.tenant.tenantSettings
                                            .timesheetResponsibilityType
                                        === TimesheetResponsibilityType.Contract && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="timesheetResponsibility"
                                                    text="Timesheet Responsibility"
                                                    helpMessage={`Timesheet responsibility of INDIVIDUAL will allow individual employees to fill out timesheets.  A setting of ${crewLead.toUpperCase()} will enforce the responsibility of all timesheets to the ${crewLead} for the given day.`}
                                                    required
                                                />
                                                <ValidatedSelect
                                                    id="timesheetResponsibility"
                                                    name="contract.timesheetResponsibilityId"
                                                    options={
                                                        contractTimesheetResponsibilityTypeOptions
                                                    }
                                                    required
                                                    isDisabled={!!contract.id}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) => option.name}
                                                    value={
                                                        contractTimesheetResponsibilityTypeOptions.find(
                                                            (x) => contract.timesheetResponsibilityTypeId
                                                                === x.id,
                                                        ) ?? ''
                                                    }
                                                    onChange={(item) => this.setState(
                                                        (state) => (
                                                            (state.contract.timesheetResponsibilityTypeId = ((
                                                                item
                                                                        ?? {}
                                                            ).id
                                                                        ?? -1) > -1
                                                                ? item.id
                                                                : ''),
                                                            state
                                                        ),
                                                    )}
                                                    validationMessage="A timesheet responsibility selection is required."
                                                />
                                            </FormGroup>
                                        )
                                    );
                                }}
                            </CommonContext.Consumer>
                            {/* <FormGroup hidden={!contract.hasAlternativePayroll}> */}
                            {/*    <FormLabel htmlFor="allowAlternativePayroll" text="Use Alternative Payroll" /> */}
                            {/*    <FormCheckbox */}
                            {/*        small={true} */}
                            {/*        className="ml-3 mt-2" */}
                            {/*        id={"allowAlternativePayroll"} */}
                            {/*        checked={contract.useAlternativePayroll} */}
                            {/*        onChange={(e) => { */}
                            {/*            let { contract } = this.state; */}
                            {/*            contract.useAlternativePayroll = e.target.checked; */}
                            {/*            this.setState({ contract: contract }); */}
                            {/*        }} */}
                            {/*        labelText={"Use Alternative Payroll"} */}
                            {/*    /> */}
                            {/* </FormGroup> */}
                            <FormGroup>
                                <FormLabel
                                    htmlFor="contractStatusId"
                                    text="Contract Status"
                                    required
                                />
                                <ValidatedSelect
                                    id="contractStatusId"
                                    name="contract.contractStatusId"
                                    options={contractStatuses}
                                    required
                                    value={
                                        (contractStatuses ?? []).find(
                                            (x) => contract.contractStatusId
                                                === x.value,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="Account status selection is required."
                                />
                            </FormGroup>
                            {(!fromTemplate || !!contract?.id) && (
                                <>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="scheduleType"
                                            text="Schedule"
                                            required
                                        />
                                        <ButtonGroup
                                            className={cls({
                                                invalid:
                                                    !!this.state
                                                        .formValidated
                                                    && !contract.contractScheduleTypeId,
                                            })}
                                            id="scheduleType"
                                            name="scheduleType"
                                        >
                                            {this.state.scheduleTypes.map(
                                                (t) => (
                                                    <Button
                                                        key={t.id}
                                                        size="sm"
                                                        color={
                                                            t.id
                                                                === contract.contractScheduleTypeId
                                                                ? 'primary'
                                                                : 'secondary'
                                                        }
                                                        className={cls('p-2', {
                                                            active:
                                                                t.id
                                                                === contract.contractScheduleTypeId,
                                                        })}
                                                        onClick={() => {
                                                            this.onScheduleTypeChanged(
                                                                t.id,
                                                            );
                                                        }}
                                                    >
                                                        {t.name}
                                                    </Button>
                                                ),
                                            )}
                                        </ButtonGroup>
                                        <input
                                            type="text"
                                            className="hidden-validation"
                                            required
                                            defaultValue={
                                                contract.contractScheduleTypeId
                                                ?? ''
                                            }
                                        />
                                        <span
                                            className="invalid-feedback text-danger"
                                            hidden
                                        >
                    A schedule type selection is
                    required.
                                        </span>
                                    </FormGroup>
                                    {false && ( // for now, remove this choice until self-dispatch without non-flagging is fully implemented.
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="allowSelfDispatch"
                                                text="Allow Self-Dispatch?"
                                                required
                                            />
                                            <ButtonGroup
                                                id="allowSelfDispatch"
                                                name="allowSelfDispatch"
                                            >
                                                {this.state.selfDispatchOptions.map(
                                                    (t) => (
                                                        <Button
                                                            key={t.value}
                                                            size="sm"
                                                            color={
                                                                t.value
                                                                    === contract.allowSelfDispatch
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                            className={cls(
                                                                'p-2',
                                                                {
                                                                    active:
                                                                        t.value
                                                                        === contract.allowSelfDispatch,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                this.onAllowSelfDispatchChanged(
                                                                    t.value,
                                                                );
                                                            }}
                                                        >
                                                            {t.label}
                                                        </Button>
                                                    ),
                                                )}
                                            </ButtonGroup>
                                        </FormGroup>
                                    )}
                                    {false
                                        && /* Hide for now, until self dispatch has client signature added. */ !!showSelfDispatchOptions && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="requireSelfDispatchTimesheetsClientSignature"
                                                text="Require Client Signatures on Self-Dispatching Timesheets?"
                                                required
                                            />
                                            <ButtonGroup
                                                id="requireSelfDispatchTimesheetsClientSignature"
                                                name="requireSelfDispatchTimesheetsClientSignature"
                                            >
                                                {this.state.selfDispatchOptions.map(
                                                    (t) => (
                                                        <Button
                                                            key={t.value}
                                                            size="sm"
                                                            color={
                                                                t.value
                                                                        === contract.requireSelfDispatchTimesheetsClientSignature
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                            className={cls(
                                                                'p-2',
                                                                {
                                                                    active:
                                                                            t.value
                                                                            === contract.requireSelfDispatchTimesheetsClientSignature,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                this.onRequireSelfDispatchTimesheetsClientSignatureChanged(
                                                                    t.value,
                                                                );
                                                            }}
                                                        >
                                                            {t.label}
                                                        </Button>
                                                    ),
                                                )}
                                            </ButtonGroup>
                                        </FormGroup>
                                    )}
                                    {!!selfDispatchTimesheetsEnabled && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="enableNonFlaggingTimesheets"
                                                text="Is this a Non-Flagging Contract?"
                                                required
                                            />
                                            <ButtonGroup
                                                id="enableNonFlaggingTimesheets"
                                                name="enableNonFlaggingTimesheets"
                                            >
                                                {this.state.selfDispatchOptions.map(
                                                    (t) => (
                                                        <Button
                                                            key={t.value}
                                                            size="sm"
                                                            color={
                                                                t.value
                                                                    === contract.enableNonFlaggingTimesheets
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                            className={cls(
                                                                'p-2',
                                                                {
                                                                    active:
                                                                        t.value
                                                                        === contract.enableNonFlaggingTimesheets,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                this.onAllowNonFlaggingChanged(
                                                                    t.value,
                                                                );
                                                            }}
                                                        >
                                                            {t.label}
                                                        </Button>
                                                    ),
                                                )}
                                            </ButtonGroup>
                                        </FormGroup>
                                    )}

                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="workOrderTypeId"
                                            text="Work Order Number?"
                                            required
                                        />
                                        <ValidatedSelect
                                            id="workOrderTypeId"
                                            name="contract.workOrderTypeId"
                                            options={WorkOrderNumberOptions}
                                            required
                                            value={
                                                WorkOrderNumberOptions.find(
                                                    (wt) => contract.workOrderTypeId
                                                        === wt.value,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                            validationMessage="A work order type selection is required."
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        hidden={
                                            !contract.workOrderTypeId
                                            || (!!contract.workOrderTypeId
                                                && contract.workOrderTypeId
                                                === ChargeTypeEligbility.DoNotUse)
                                        }
                                    >
                                        <FormLabel
                                            htmlFor="linkedWorkOrders"
                                            text="Linked Work Orders?"
                                            required={
                                                !!contract.workOrderTypeId
                                            }
                                        />
                                        <ButtonGroup
                                            id="allowLinkedWorkOrders"
                                            name="contract.allowLinkedWorkOrders"
                                        >
                                            {this.state.selfDispatchOptions.map(
                                                (t) => (
                                                    <Button
                                                        key={t.value}
                                                        size="sm"
                                                        color={
                                                            t.value
                                                                === contract.allowLinkedWorkOrders
                                                                ? 'primary'
                                                                : 'secondary'
                                                        }
                                                        className={cls('p-2', {
                                                            active:
                                                                t.value
                                                                === contract.allowLinkedWorkOrders,
                                                        })}
                                                        onClick={() => {
                                                            this.onAllowLinkedWorkOrdersChanged(
                                                                t.value,
                                                            );
                                                        }}
                                                    >
                                                        {t.label}
                                                    </Button>
                                                ),
                                            )}
                                        </ButtonGroup>
                                    </FormGroup>

                                    {enableContractDateRange && (
                                        <>
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="effectiveDate"
                                                    text="Effective Date"
                                                    required
                                                />
                                                <input
                                                    id="effectiveDate"
                                                    required
                                                    type="date"
                                                    className="form-control"
                                                    name="contract.effectiveDate"
                                                    defaultValue={
                                                        contract.effectiveDate
                                                        ?? ''
                                                    }
                                                    onChange={this.onChange}
                                                />
                                                <small className="invalid-feedback text-danger">
                      Effective Date is required.
                                                </small>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="expirationDate"
                                                    text="Expiration Date"
                                                    required
                                                />
                                                <input
                                                    id="expirationDate"
                                                    required
                                                    type="date"
                                                    className="form-control"
                                                    name="contract.expirationDate"
                                                    defaultValue={
                                                        contract.expirationDate
                                                        ?? ''
                                                    }
                                                    onChange={this.onChange}
                                                />
                                                <small className="invalid-feedback text-danger">
                      Expiration Date is required.
                                                </small>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="warningThresholdInDays"
                                                    text="Warning Threshold (Days)"
                                                    required
                                                />
                                                <ValidatedSelect
                                                    id="warningThresholdInDays"
                                                    name="contract.warningThresholdInDays"
                                                    options={
                                                        warningThresholdOptions
                                                    }
                                                    required
                                                    value={
                                                        (
                                                            warningThresholdOptions
                                                            ?? []
                                                        ).find(
                                                            (x) => contract.warningThresholdInDays
                                                                === x.value,
                                                        ) ?? ''
                                                    }
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                    onChange={(selection) => {
                                                        const value = selection?.value;
                                                        this.setState(
                                                            (state) => (
                                                                (contract.warningThresholdInDays = value),
                                                                { ...state }
                                                            ),
                                                        );
                                                    }}
                                                    validationMessage="Warning Threshold is required."
                                                    hideClear
                                                />
                                            </FormGroup>
                                        </>
                                    )}
                                </>
                            )}
                            {newContractFromTemplate && (
                                <>
                                    <div className="pt-1" />
                                    <hr />
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="contractTemplateId"
                                            text="Template"
                                            required
                                        />
                                        <ValidatedSelect
                                            id="contractStatusId"
                                            name="contract.contractTemplateId"
                                            options={contractTemplates}
                                            required
                                            value={
                                                (contractTemplates ?? []).find(
                                                    (x) => contract.contractTemplateId
                                                        === x.value,
                                                ) ?? ''
                                            }
                                            getOptionLabel={(option) => option.key}
                                            getOptionValue={(option) => option.value}
                                            onChange={(selection) => {
                                                const value = selection?.value;
                                                this.setState(
                                                    (state) => (
                                                        (contract.contractTemplateId = value),
                                                        { ...state }
                                                    ),
                                                );
                                            }}
                                            validationMessage="Template selection is required."
                                        />
                                    </FormGroup>
                                </>
                            )}

                            {enableAutoEscalatingContractRates && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="autoEscalatingRates"
                                        text="Auto-Escalating Rates"
                                    />
                                    <ButtonGroup
                                        id="autoEscalatingRates"
                                        name="contract.autoEscalatingRates"
                                    >
                                        {this.state.autoEscalatingRateOptions.map(
                                            (t) => (
                                                <Button
                                                    key={t.value}
                                                    size="sm"
                                                    color={
                                                        t.value
                                                            === contract.autoEscalatingRates
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                    className={cls('p-2', {
                                                        active:
                                                            t.value
                                                            === contract.autoEscalatingRates,
                                                    })}
                                                    onClick={() => {
                                                        this.onAutoEscalatingRatesChanged(
                                                            t.value,
                                                        );
                                                    }}
                                                >
                                                    {t.label}
                                                </Button>
                                            ),
                                        )}
                                    </ButtonGroup>
                                </FormGroup>
                            )}

                            {enablePrevailingWageContracts && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="prevailingWage"
                                        text="Prevailing Wage"
                                    />
                                    <ButtonGroup
                                        id="prevailingWage"
                                        name="contract.prevailingWage"
                                    >
                                        {this.state.prevailingWageOptions.map(
                                            (t) => (
                                                <Button
                                                    key={t.value}
                                                    size="sm"
                                                    color={
                                                        t.value
                                                            === contract.prevailingWage
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                    className={cls('p-2', {
                                                        active:
                                                            t.value
                                                            === contract.prevailingWage,
                                                    })}
                                                    onClick={() => {
                                                        this.onPrevailingWageChanged(
                                                            t.value,
                                                        );
                                                    }}
                                                >
                                                    {t.label}
                                                </Button>
                                            ),
                                        )}
                                    </ButtonGroup>
                                </FormGroup>
                            )}

                            <FormGroup>
                                <FormLabel
                                    htmlFor="setupCharges"
                                    text="Setup Charges Required"
                                />
                                <ButtonGroup
                                    id="setupCharges"
                                    name="contract.setupChargesRequired"
                                >
                                    {this.state.prevailingWageOptions.map(
                                        (t) => (
                                            <Button
                                                key={t.value}
                                                size="sm"
                                                color={
                                                    t.value
                                                        === contract.setupChargesRequired
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                className={cls('p-2', {
                                                    active:
                                                        t.value
                                                        === contract.setupChargesRequired,
                                                })}
                                                onClick={() => {
                                                    this.onSetupChargesRequiredChanged(
                                                        t.value,
                                                    );
                                                }}
                                            >
                                                {t.label}
                                            </Button>
                                        ),
                                    )}
                                </ButtonGroup>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="permitFeesApply"
                                    text="Permit Fees Apply"
                                />
                                <ButtonGroup
                                    id="permitFeesApply"
                                    name="contract.permitFeesApply"
                                >
                                    {this.state.prevailingWageOptions.map(
                                        (t) => (
                                            <Button
                                                key={t.value}
                                                size="sm"
                                                color={
                                                    t.value
                                                        === contract.permitFeesApply
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                className={cls('p-2', {
                                                    active:
                                                        t.value
                                                        === contract.permitFeesApply,
                                                })}
                                                onClick={() => {
                                                    this.onPermitFeesApplyChanged(
                                                        t.value,
                                                    );
                                                }}
                                            >
                                                {t.label}
                                            </Button>
                                        ),
                                    )}
                                </ButtonGroup>
                            </FormGroup>

                        </Col>
                    </Row>
                    {!!this.context?.tenant?.tenantSettings
                        ?.timesheetEmailEnabled && (
                        <Row className="pt-3 pb-3">
                            <Col
                                xl="8"
                                lg="8"
                                md="10"
                                sm="12"
                                className="ml-auto mr-auto"
                            >
                                <FormGroup>
                                    <FormLabel
                                        className="w-100 contract-email-label"
                                        htmlFor="workOrderTypeId"
                                        text="Timesheet Email Recipients"
                                    />
                                    <small className="pl-2 pb-2 text-success">
                    Enter addresses at which you would like
                    to receive copies of contract-related
                    timesheets below.
                                    </small>
                                    <EmailRecipients
                                        currentEmailAddress={
                                            currentEmailAddress
                                        }
                                        onEmailAdded={this.onEmailAdded}
                                        onEmailChanged={this.onEmailChanged}
                                        onEmailRemoved={this.onEmailRemoved}
                                        emailAddresses={
                                            contract.timesheetEmailRecipients
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    {!!contract.id && contract.prevailingWage && (
                        <Row>
                            <Col>
                                <SubHeading>Pay Schedules</SubHeading>
                                <SimpleTable
                                    className="mb-0"
                                    id="contractPrevailingWageTable"
                                    onAddFunction={this.onAddPaySchedules}
                                    addLabelText={
                                        this.context.formIsOpen ? (
                                            <span>
                                                <i className="fa fa-spin fa-circle-notch mr-1" />
                                                Opening pay schedules
                                                form...
                                            </span>
                                        ) : (
                                            'Add Pay Schedule'
                                        )
                                    }
                                    noTopBorder
                                    permission="contract"
                                    tableHeaderLabels={[
                                        {
                                            name: 'Description',
                                            class: 'ct-job-number',
                                        },
                                        {
                                            name: 'State',
                                            class: 'ct-job-name',
                                        },
                                        {
                                            name: 'Counties',
                                            class: 'ct-job-week',
                                        },
                                        {
                                            name: 'Reg',
                                            class: 'ct-job-active',
                                        },
                                        {
                                            name: 'OT',
                                            class: 'ct-job-active',
                                        },
                                        {
                                            name: 'Fringe',
                                            class: 'ct-job-active',
                                        },
                                        {
                                            name: '',
                                            class: 'ct-job-active',
                                        },
                                    ]}
                                    editable={false}
                                    entities={contract.contractPaySchedules}
                                    rowRenderer={
                                        this.renderContractPaySchedules
                                    }
                                    noDataText="No pay schedules found."
                                />
                            </Col>
                        </Row>
                    )}
                    <p />
                    {!!contract.id && (
                        <>
                            <Row>
                                <Col>
                                    <SubHeading>Charge Types</SubHeading>
                                    <SimpleTable
                                        className="mb-0"
                                        id="contractChargeTypesTable"
                                        onAddFunction={this.onAddChargeTypes}
                                        addButtonEnabled={
                                            parseInt(
                                                this.state.contract.companyId
                                                ?? 0,
                                            ) > 0
                                        }
                                        addLabelText={
                                            this.context.formIsOpen ? (
                                                <span>
                                                    <i className="fa fa-spin fa-circle-notch mr-1" />
                                                Opening charge types form...
                                                </span>
                                            ) : (
                                                'Add Charge Types'
                                            )
                                        }
                                        leftToolbarContent={
                                            !!(
                                                (this.state.contract ?? {})
                                                    .contractChargeTypes ?? []
                                            ).length && (
                                                <SmallButton
                                                    type="button"
                                                    disabled={
                                                        !!this.context
                                                            .formIsOpen
                                                    }
                                                    onClick={
                                                        this
                                                            .onReorderChargeTypes
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSort}
                                                        className="mr-2"
                                                    />
                                              Reorder
                                                </SmallButton>
                                            )
                                        }
                                        noTopBorder
                                        permission="contract"
                                        tableHeaderLabels={
                                            chargeTypeHeaderLabels
                                        }
                                        editable={false}
                                        entities={contract.contractChargeTypes}
                                        rowRenderer={
                                            this.renderContractChargeTypesRows
                                        }
                                        noDataText="No charge types found."
                                    />
                                </Col>
                            </Row>

                            {!!(contract?.contractChargeTypes ?? []).length && (
                                <Row>
                                    <Col>
                                        <FlexEndRow className="pt-1 pl-3 pr-4">
                                            <small
                                                className="site-link w-100 text-right"
                                                onClick={() => {
                                                    const { contract } = {
                                                        ...this.state,
                                                    };
                                                    const {
                                                        contractChargeTypes,
                                                    } = { ...contract };
                                                    const updated = [
                                                        ...contractChargeTypes.map(
                                                            (x) => ({
                                                                ...x,
                                                                chargeTypeEligibilityId:
                                                                    _ContractChargeTypeEligibility.Required,
                                                            }),
                                                        ),
                                                    ];
                                                    contract.contractChargeTypes = updated;
                                                    this.setState({
                                                        contract: {
                                                            ...contract,
                                                        },
                                                    });
                                                }}
                                            >
                    Require All Charge Types
                                            </small>
                                        </FlexEndRow>
                                    </Col>
                                </Row>
                            )}

                            {isChargeTypeGroupsAllowed
                                && (
                                    <Row className="mt-3">
                                        <Col>
                                            <SubHeading>Charge Type Groups</SubHeading>
                                            <GroupedRow>
                                                <ContractChargeTypeGroupGrid
                                                    groups={
                                                        (contract ?? [])
                                                            .contractChargeTypeGroups
                                                    }
                                                    onAddCTGroup={
                                                        this.toggleCTGroupModal
                                                    }
                                                    onEditCTGroup={this.onEditCTGroup}
                                                    onRemoveCTGroup={
                                                        this.onRemoveCTGroup
                                                    }
                                                    onCTGroupChanged={
                                                        this.onCTGroupChanged
                                                    }
                                                />
                                            </GroupedRow>
                                        </Col>
                                    </Row>
                                )}

                            {!showSelfDispatchOptions && (
                                <Row>
                                    <Col>
                                        <SubHeading>Jobs</SubHeading>
                                        <SimpleTable
                                            id="contractJobTable"
                                            scrollable
                                            maxBodyHeight="500px"
                                            noTopBorder
                                            permission="contract"
                                            addLabelText="Add Job"
                                            addButtonEnabled={
                                                contract?.contractStatusId
                                                === _ContractStatuses.Active
                                            }
                                            onAddFunction={this.onAddJob}
                                            tableHeaderLabels={[
                                                {
                                                    name: 'Job #',
                                                    class: 'ct-job-number',
                                                },
                                                {
                                                    name: 'Job Name',
                                                    class: 'ct-job-name',
                                                },
                                                {
                                                    name: 'Week',
                                                    class: 'ct-job-week',
                                                },
                                                {
                                                    name: 'Active?',
                                                    class: 'ct-job-active',
                                                },
                                            ]}
                                            editable={false}
                                            entities={this.state.contract.jobs.filter(
                                                (x) => x.isDeleted == false,
                                            )}
                                            rowRenderer={this.renderJobRows}
                                            noDataText="No jobs found."
                                            leftToolbarContent={(
                                                <span className="pl-1 text-muted font-weight-bold">
                      Most recent 2 contract weeks
                                                    <Link
                                                        className="ml-2 site-link"
                                                        to={`${AppNavPaths.Jobs}?contractNumber=${this.state.contract.number}`}
                                                    >
                        (View All)
                                                    </Link>
                                                </span>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <FlexCenterRow className="mb-3">
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="contractForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {existing ? 'Save' : 'Save New Contract'}
                        </Button>
                    </FlexCenterRow>
                </AppPageForm>
                <ChargeTypeSelect
                    ref={this.chargeTypeSelectorRef}
                    id="chargeTypeSelector"
                    onAddCallback={this.onAddChargeTypesCallback}
                />

                <PayScheduleSelect
                    ref={this.payScheduleSelectorRef}
                    id="payScheduleSelector"
                    onAddCallback={this.onAddPayScheduleCallback}
                />

                {!!isBillingRatesEnabled && (
                    <BillingRateForm
                        ref={this.rateFormRef}
                        id="billingRateForm"
                        entity={this.state.selectedRate}
                        entityName="selectedRate"
                        show={this.state.showRateForm}
                        onClose={this.onRateFormClosed}
                        onSaveCallback={this.onRateSaved}
                        onRemoveCallback={this.onRateRemoved}
                        onChange={this.onChange}
                        onSelectChange={this.onSelectChanged}
                        chargeTypes={chargeTypes}
                        showChargeType
                        showRemove={showRateFormDelete}
                        chargeTypeReadonly
                    />
                )}

                <Modal
                    isOpen={showCTGroupModal}
                    toggle={() => this.toggleCTGroupModal()}
                >
                    <ModalHeader toggle={() => this.toggleCTGroupModal()}>
            Charge Type Groups
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <FormLabel
                                htmlFor="chargeTypeGroups"
                                text="Charge Type Group(s)"
                            />
                            <ValidatedSelect
                                id="chargeTypeGroups"
                                name="contract.contractChargeTypeGroups"
                                isMulti
                                options={chargeTypeGroups.filter(
                                    (x) => !(
                                        contract.contractChargeTypeGroups
                                            ?? []
                                    )
                                        .map((x) => x.chargeTypeGroupId)
                                        .includes(x.id),
                                )}
                                getOptionLabel={(o) => o.invoiceDescription}
                                getOptionValue={(o) => o.id}
                                onChange={
                                    this.onChargeTypeGroupSelectChanged
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.onAddCTGroup()}
                        >
              Ok
                        </Button>
                        {' '}
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ showCTGroupModal: false })}
                        >
              Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <SlideForm
                    loading={false}
                    show={this.state.showReorderWidget}
                    id="reorderChargeTypesWidget"
                    formIcon={faFileContract}
                    formTitle="Reorder Charge Types"
                    ref={this.chargeTypesWidgetRef}
                    onClose={() => {
                        this.setState(
                            (state) => (
                                (state.showReorderWidget = false), state
                            ),
                        );
                    }}
                >
                    <ReorderListWidget
                        entities={this.state.selectedSortableChargeTypes}
                        entityName="description"
                        customColors={false}
                        setUpdatedEntities={(items) => {
                            this.setState({
                                selectedSortableChargeTypes: items,
                            });
                        }}
                        ref={this.chargeTypesWidgetRef}
                        onSaveCallback={() => {
                            const { contract, selectedSortableChargeTypes } = {
                                ...this.state,
                            };
                            let updated = [...selectedSortableChargeTypes];
                            updated = updated.map((x, index) => ({ ...x, ...{ order: (index += 1) } }));
                            contract.contractChargeTypes = updated;
                            this.setState({
                                contract,
                                showReorderWidget: false,
                            });
                        }}
                    />
                </SlideForm>
            </>
        );
    }
}
export default withRouter(ContractForm);
