import React from 'react';
import { withRouter } from 'react-router-dom';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { SmallButton } from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';

class AdminToolIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = { loading: true };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onTenantPageClicked = () => this.props.history.push(`${AppNavPaths.Tenant}/${this.state.tenantId}`);

    getAdminTool = async () => {
        await this.setState({ loading: true });

        const [adminToolList] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.search()),
        ]);

        await this.setState((state) => ({
            loading: false,
            toolList: adminToolList,
        }));
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        const tenant = await util.fetch.js(ApiRoutes.tenant.settings());

        if (isAuthenticated) {
            await this.setState({ loading: true });
            this.getAdminTool();
            if (tenant && tenant.id) {
                await this.setState({ tenantId: tenant.id });
            }
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label="Loading admin tools..."
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <div className="p-5">
                <h2>
                    Admin Tools
                </h2>

                <SmallButton type="button" className="mb-2" onClick={this.onTenantPageClicked}>
                    <i className="fa fa-md mr-2 fa fa-cogs" />
                    Tenant Setting
                </SmallButton>

                <div>
                    <ListGroup>
                        {this.state.toolList.map((item) => (
                            <ListGroupItem href={`/admintool/${item.id}`} tag="a" action key={item.id}>
                                <ListGroupItemHeading>
                                    {item.toolName}
                                </ListGroupItemHeading>

                                <ListGroupItemText className="mb-0">
                                    {item.description}
                                </ListGroupItemText>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>

            </div>
        );
    }
}

export default withRouter(AdminToolIndex);
