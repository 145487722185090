import React from 'react';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormLabel,
    ValidationErrorMessage,
    onFieldChange,
    toasty,
    onReactSelectChanged,
} from '../common/forms/FormElements';
import {
    ContactActivity,
    ActivityTypeOptions,
    KnownActivityTypes,
} from './ContactActivity';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';

export default class ContactActivityForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            contactActivity: null,
            show: false,
            activityTaskStatuses: props.activityTaskStatuses || [],
            activityTaskTypes: props.activityTaskTypes || [],
            taskInteractionTypes: props.activityTaskTypes || [],
            activityTypes: ActivityTypeOptions,
            contactName: '',
            readOnly: false,
            removeUnavailable: true,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitAndConfirm = this.onSubmitAndConfirm.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onChange = onFieldChange;

    onClose = (response) => {
        this.resetForm();
        this.setState({ show: false });
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onDelete = async (e) => {
        await this.setState({ readOnly: true });

        const { contactActivity } = this.state;

        const response = await util.fetch
            .delete(ApiRoutes.contactActivity.delete(contactActivity.id))
            .catch(this.handleSaveError);

        if (response) {
            await this.setState({ show: false });
            this.props.onDeleteCallback(response);
        }

        this.setState({ readOnly: false });
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async (e) => {
        await this.setState({ readOnly: true });

        // Clear any fluent api errors
        const { contactActivity } = this.state;

        let savedSuccessfully = false;

        const activityType = this.isNote(contactActivity) ? 'Note' : 'Task';

        if (contactActivity.id && contactActivity.id > 0) {
            savedSuccessfully = await this.updateActivity(contactActivity);

            if (savedSuccessfully) {
                toasty.success(
                    `${activityType} Updated`,
                    `${activityType} Successfully Updated`,
                );
            }
        } else {
            savedSuccessfully = await this.createActivity(contactActivity);

            if (savedSuccessfully) {
                toasty.success(
                    `${activityType} Created`,
                    `${activityType} Successfully Created`,
                );
            }
        }

        await this.setState({ readOnly: false });

        if (!savedSuccessfully) {
            return;
        }

        this.clearErrors();
        this.setState({ contactName: '', show: false });
        this.props.onSaveCallback();
        this.resetForm();
    };

    onSubmitAndConfirm = async (e) => {
        await this.setState({ readOnly: true });

        const { contactActivity } = this.state;

        contactActivity.activityTaskStatusId = 3;

        await this.setState({ contactActivity });

        this.onSubmit(e);
    };

    getActivityTypeDescription = (contactActivity) => {
        let activityTypeDescription = 'Contact Activity';

        if (this.isNote(contactActivity)) {
            activityTypeDescription = 'Note';
        }

        if (this.isTask(contactActivity)) {
            activityTypeDescription = 'Task';
        }

        return activityTypeDescription;
    };

    getSecondarySaveButtonText = (contactActivity) => {
        if (!this.canBeConfirmed(contactActivity)) {
            return null;
        }

        return 'Save and Complete';
    };

    canBeConfirmed = (contactActivity) => Boolean(
        !this.isNew(contactActivity)
                && this.isTask(contactActivity)
                && contactActivity.activityTaskStatusId != 3,
    );

    clearErrors = () => this.setState({ errors: {} });

    createActivity = async (contactActivity) => {
        const createResponse = await util.fetch
            .post(ApiRoutes.contactActivity.create(), contactActivity)
            .catch(this.handleSaveError);

        return Boolean(createResponse);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    isNew = (contactActivity) => !contactActivity.id;

    isNote = (contactActivity) => Boolean(
        contactActivity
                && contactActivity.activityType === KnownActivityTypes.Note,
    );

    isTask = (contactActivity) => Boolean(
        contactActivity
                && contactActivity.activityType === KnownActivityTypes.Task,
    );

    open = async (id) => {
        await this.setState({ loading: true });

        const [contactActivity] = await Promise.all([
            util.fetch.js(ApiRoutes.contactActivity.byId(id)),
        ]);

        if (!contactActivity) {
            toasty.error(
                'Contact Activity',
                'We failed to find that activity. Please refresh and try again.',
            );

            this.setState({ loading: false });
            return;
        }

        contactActivity.taskDueDate = util.date.getInputFormat(
            contactActivity.taskDueDate,
        );

        contactActivity.noteDate = util.date.getInputFormat(
            contactActivity.noteDate,
        );

        let contactName = '[UNKNOWN]';
        const { locationContact } = contactActivity;

        if (locationContact) {
            contactName = `${locationContact.lastName}, ${locationContact.firstName}`;
        }

        await this.setState({
            show: true,
            loading: false,
            contactName,
            contactActivity,
        });
    };

    openNew = (locationContactId, contactName) => {
        const contactActivity = new ContactActivity();

        contactActivity.locationContactId = locationContactId;

        // notes should default to today's date
        contactActivity.noteDate = util.date.getInputFormat(
            new Date().toLocaleDateString(),
        );

        // tasks should default to pending
        contactActivity.activityTaskStatusId = 1;

        this.setState({ contactActivity, contactName, show: true });
    };

    populateState = async () => {
        let { activityTaskStatuses, activityTaskTypes, taskInteractionTypes } = this.state;

        const removeUnavailable = !this.context.permissions.find(
            (p) => p.permission === 'contactactivity' && p.action === 'delete',
        );

        if (
            !activityTaskStatuses
            || !activityTaskStatuses.length
            || !activityTaskTypes
            || !activityTaskTypes.length
            || !taskInteractionTypes
            || !taskInteractionTypes.length
        ) {
            [activityTaskTypes, activityTaskStatuses, taskInteractionTypes] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.activityTaskTypes()),
                util.fetch.js(ApiRoutes.typeAheads.activityTaskStatuses()),
                util.fetch.js(ApiRoutes.typeAheads.taskInteractionTypes()),
            ]);

            this.setState({
                activityTaskTypes,
                activityTaskStatuses,
                taskInteractionTypes,
                removeUnavailable,
            });
        }

        this.setState({ loading: false });
    };

    resetForm = () => this.setState({ formValidated: false });

    updateActivity = async (contactActivity) => {
        const updateResponse = await util.fetch
            .put(
                ApiRoutes.contactActivity.update(contactActivity.id),
                contactActivity,
            )
            .catch(this.handleSaveError);

        return Boolean(updateResponse);
    };

    render() {
        const {
            contactActivity,
            formValidated,
            validationMessage,
            loading,
            show,
            activityTaskStatuses,
            activityTaskTypes,
            taskInteractionTypes,
            activityTypes,
            contactName,
            readOnly,
            removeUnavailable,
        } = this.state;

        if (!contactActivity || !contactActivity.locationContactId) {
            return '';
        }

        const isNew = this.isNew(contactActivity);
        const isNote = this.isNote(contactActivity);
        const isTask = this.isTask(contactActivity);

        const titlePrefix = isNew ? 'Add' : 'Edit';
        const activityTypeDescription = this.getActivityTypeDescription(contactActivity);
        const nameAddendum = contactName ? ` for ${contactName}` : '';
        const secondarySaveButtonText = this.getSecondarySaveButtonText(contactActivity);

        return (
            <SlideForm
                loading={loading}
                show={show}
                id="ContactActivityForm"
                formIcon={faComments}
                formTitle={`${titlePrefix} ${activityTypeDescription}${nameAddendum}`}
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={contactActivity.id}
                readOnly={readOnly}
                removeUnavailable={removeUnavailable}
                secondarySaveButtonText={secondarySaveButtonText}
                onSecondarySubmit={this.onSubmitAndConfirm}
            >
                {isNew && (
                    <FormGroup>
                        <FormLabel
                            htmlFor="activityType"
                            text="Type"
                            required
                        />
                        <ValidatedSelect
                            id="activityType"
                            name="contactActivity.activityType"
                            required
                            options={activityTypes}
                            value={
                                activityTypes.find(
                                    (at) => at.value
                                        === contactActivity.activityType,
                                ) ?? ''
                            }
                            onChange={this.onSelectChange}
                            validationMessage="Please tell us which type of activity should be
                            created."
                        />
                    </FormGroup>
                )}

                {isNote && (
                    <>
                        <FormGroup>
                            <FormLabel
                                htmlFor="title"
                                text="Title"
                                required
                            />
                            <Input
                                id="title"
                                name="contactActivity.title"
                                value={contactActivity.title ?? ''}
                                onChange={this.onChange}
                                placeholder="Enter Name (maximum 80 characters)"
                                maxLength="80"
                                pattern="[^()/><\][\\\x22,;|]+"
                                type="text"
                                required
                            />
                            <ValidationErrorMessage>
              Title is required and can contain hyphens and
              periods.
                            </ValidationErrorMessage>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="contactActivity.noteDate"
                                text="Note Date"
                                required
                            />
                            <Input
                                id="contactActivity.noteDate"
                                name="contactActivity.noteDate"
                                value={
                                    contactActivity.noteDate
                                    ?? new Date().toLocaleDateString()
                                }
                                onChange={this.onChange}
                                placeholder="Note Date"
                                type="date"
                                required
                            />
                            <ValidationErrorMessage>
              Note Date is required.
                            </ValidationErrorMessage>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel htmlFor="notes" text="Notes" />
                            <textarea
                                id="notes"
                                name="contactActivity.notes"
                                className="form-control"
                                defaultValue={contactActivity.notes ?? ''}
                                onChange={this.onChange}
                                placeholder="Enter notes here."
                                type="text"
                                maxLength="500"
                                rows="5"
                            />
                        </FormGroup>
                    </>
                )}

                {isTask && (
                    <>
                        <FormGroup>
                            <FormLabel
                                htmlFor="description"
                                text="Description"
                                required
                            />
                            <textarea
                                id="description"
                                name="contactActivity.description"
                                value={contactActivity.description ?? ''}
                                onChange={this.onChange}
                                placeholder="Enter Description (maximum 250 characters)"
                                maxLength="250"
                                pattern="[^()/><\][\\\x22,;|]+"
                                type="text"
                                rows="3"
                                required
                            />
                            <ValidationErrorMessage>
              Description is required.
                            </ValidationErrorMessage>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="contactActivity.taskDueDate"
                                text="Task Due Date"
                                required
                            />
                            <Input
                                id="contactActivity.taskDueDate"
                                name="contactActivity.taskDueDate"
                                value={
                                    contactActivity.taskDueDate
                                    ?? new Date().toLocaleDateString()
                                }
                                onChange={this.onChange}
                                placeholder="Due Date"
                                type="date"
                                required
                            />
                            <ValidationErrorMessage>
              Due Date is required.
                            </ValidationErrorMessage>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="activityTaskStatusId"
                                text="Status"
                                required
                            />
                            <ValidatedSelect
                                id="activityTaskStatusId"
                                name="contactActivity.activityTaskStatusId"
                                required
                                options={activityTaskStatuses}
                                value={
                                    activityTaskStatuses.find(
                                        (ats) => ats.value
                                            === contactActivity.activityTaskStatusId,
                                    ) ?? ''
                                }
                                onChange={this.onSelectChange}
                                validationMessage="Please tell us what the status of this task is."
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="activityTaskTypeId"
                                text="Task Type"
                                required
                            />
                            <ValidatedSelect
                                id="activityTaskTypeId"
                                name="contactActivity.activityTaskTypeId"
                                required
                                options={activityTaskTypes}
                                value={
                                    activityTaskTypes.find(
                                        (att) => att.value
                                            === contactActivity.activityTaskTypeId,
                                    ) ?? ''
                                }
                                onChange={this.onSelectChange}
                                validationMessage="Please tell us what type of task is."
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="taskInteractionTypeId"
                                text="Interaction Type"
                                required
                            />
                            <ValidatedSelect
                                id="taskInteractionTypeId"
                                name="contactActivity.taskInteractionTypeId"
                                required
                                options={taskInteractionTypes}
                                value={
                                    taskInteractionTypes.find(
                                        (tt) => tt.value
                                            === contactActivity.taskInteractionTypeId,
                                    ) ?? ''
                                }
                                onChange={this.onSelectChange}
                                validationMessage="Please tell us what type of interaction the task requires."
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel htmlFor="details" text="Details" />
                            <textarea
                                id="details"
                                name="contactActivity.details"
                                value={contactActivity.details ?? ''}
                                onChange={this.onChange}
                                placeholder="Enter Details (maximum 500 characters)"
                                maxLength="500"
                                type="text"
                                rows="3"
                            />
                        </FormGroup>
                    </>
                )}
            </SlideForm>
        );
    }
}
