export class BaseFormViewModel {
    isAuthenticated = false;

    formValidated = false;

    loading = true;

    saving = false;

    errors = {};

    validationMessage = '';

    isValidated = false;

    toast = null;
}
