import React from 'react';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup,
} from 'reactstrap';
import * as moment from 'moment';
import { BaseFormViewModel } from '../common/ViewModel';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import CommonContext, { ApiRoutes } from '../Common';
import {
    CollapseUnderlineHeader,
    FlexStartRow,
    FormLabel,
} from '../common/forms/FormElements';
import {
    BillingOTDays,
} from '../organization/Organization';

export default class BillingChargeEmployeeSlideout extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        const stateBase = {

            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
    }

    componentDidMount() { return this.populateState(); }

    onClose = () => {
        this.props.toggleShow(false);
        this.props.onClose();
    };

    open = async (billingDetailId) => {
    // reset adjustments and validation on the way back in.
        this.props.toggleShow(true);
        await this.setState({
            loading: true,
        });

        const [billperiods] = await Promise.all([
            util.fetch.js(
                ApiRoutes.billingChargeCategory.billperiods(billingDetailId),
            ),
        ]);

        await this.setState({
            loading: false,
            billperiods,
        });
    };

    openInvoice = (invoiceId) => {
        this.props.toggleShow(false);
        this.props.onOpenInvoice(invoiceId);
    };

    openTimesheetDetails = (timesheetId) => {
        this.props.toggleShow(false);
        this.props.onOpenTimesheet(timesheetId);
    };

    async populateState() {

    }

    render() {
        const {
            loading,
            billperiods,
        } = this.state;

        return (
            (!!billperiods
                && (
                    <SlideForm
                        size="col-xl-12 col-md-12 col-xs-12"
                        loading={loading}
                        show={this.props.show}
                        id="timesheetForm"
                        formIcon={faUserClock}
                        formTitle={`${billperiods.employeeName} - Week Of ${billperiods.weekOf}`}
                        ref={this.formRef}
                        className="pb-2 w-100"
                        readOnly={false}
                        onClose={this.onClose}
                    >
                        {
                            billperiods.companies.map((company) => {
                                const initialSelection = company.charges.find((x) => x.id == billperiods.id);
                                if (initialSelection !== undefined) {
                                    company.opened = true;
                                    billperiods.id = 0;
                                }

                                return (
                                    <CollapseUnderlineHeader
                                        className="mb-3"
                                        headerText={company.companyName}
                                        isOpen={company.opened}
                                        toggleCollapse={() => {
                                            company.opened = !company.opened;

                                            this.setState({
                                                billperiods,
                                            });
                                        }}
                                    >

                                        <div className="invoice-details" style={{ paddingTop: '4px', paddingBottom: '0px' }}>
                                            <FlexStartRow>
                                                <FormLabel
                                                    text="Overtime Days"
                                                />
                                                <span
                                                    className="text-muted"
                                                    style={{ marginLeft: '16px', marginTop: '5px' }}
                                                >
                                                    {company.otDays & BillingOTDays.Sunday ? 'Sunday' : ''}
                                                    {company.otDays & BillingOTDays.Monday ? ', Monday' : ''}
                                                    {company.otDays & BillingOTDays.Tuesday ? ', Tuesday' : ''}
                                                    {company.otDays & BillingOTDays.Wednesday ? ', Wednesday' : ''}
                                                    {company.otDays & BillingOTDays.Thursday ? ', Thursday' : ''}
                                                    {company.otDays & BillingOTDays.Friday ? ', Friday' : ''}
                                                    {company.otDays & BillingOTDays.Saturday ? ', Saturday' : ''}
                                                </span>
                                            </FlexStartRow>
                                            {company.useDailyOT && company.dailyOTThreshold > 0
                                            && (
                                                <FlexStartRow>
                                                    <FormLabel
                                                        text="Daily Overtime"
                                                    />
                                                    <span
                                                        className="text-muted"
                                                        style={{ marginLeft: '16px', marginTop: '5px' }}
                                                    >
                                                        {company.dailyOTThreshold}
                                                        {' '}
                                                hours
                                                    </span>
                                                </FlexStartRow>
                                            )}
                                            {company.otShiftStart != null && company.otShiftStart !== company.otShiftEnd
                                            && (
                                                <FlexStartRow>
                                                    <FormLabel
                                                        text="Overtime Shift"
                                                    />
                                                    <span
                                                        className="text-muted"
                                                        style={{ marginLeft: '16px', marginTop: '5px' }}
                                                    >
                                                        {moment(company.otShiftStart).format('h:mm A')}
                                                        {' '}
                                                -
                                                        {moment(company.otShiftEnd).format('h:mm A')}
                                                    </span>
                                                </FlexStartRow>
                                            )}
                                            {company.otJobStart != null && company.otJobStart !== company.otJobEnd
                                            && (
                                                <FlexStartRow>
                                                    <FormLabel
                                                        text="Job Start Overtime"
                                                    />
                                                    <span
                                                        className="text-muted"
                                                        style={{ marginLeft: '16px', marginTop: '5px' }}
                                                    >

                                                        {moment(company.otJobStart).format('h:mm A')}
                                                        {' '}
                                                -
                                                        {moment(company.otJobEnd).format('h:mm A')}
                                                    </span>
                                                </FlexStartRow>
                                            )}
                                        </div>

                                        <FormGroup>
                                            <FormLabel
                                                text="Week Charges"
                                            />

                                            <table className="table table-sm table-bordered table-striped timesheet-details-table">
                                                <thead className="text-muted">
                                                    <tr>
                                                        <th style={{ display: 'none' }} />
                                                        <th style={{ width: '165px' }}>Job Date</th>
                                                        <th>Timesheet</th>
                                                        <th>Dispatching</th>
                                                        <th>Charge</th>
                                                        <th>Job Start</th>
                                                        <th>Emp Start</th>
                                                        <th>Emp End</th>
                                                        <th style={{ textAlign: 'center', width: '50px' }}>Charge</th>
                                                        <th style={{ textAlign: 'center', width: '75px' }}>Bill</th>
                                                        <th style={{ textAlign: 'center', width: '50px' }}>Reg</th>
                                                        <th style={{ textAlign: 'center', width: '50px' }}>OT</th>
                                                        <th style={{ textAlign: 'center', width: '75px' }}>Cancel</th>
                                                        <th style={{ textAlign: 'right', width: '75px' }}>MinMax Bill</th>
                                                        <th>Processed By</th>
                                                        <th>Process Date</th>
                                                        <th>Billing Status</th>
                                                        <th>Invoice</th>
                                                        <th style={{ display: 'none' }} />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        company.charges.map((charge) => (
                                                            <tr className={`timesheet-details-table-row${charge.current == 0 ? ' strikeout' : ''}`}>
                                                                <td style={{ display: 'none' }} />
                                                                <td title={charge.id}>{moment(charge.jobDate).format('ddd M/D/YYYY')}</td>
                                                                <td>
                                                                    <div
                                                                        className="site-link"
                                                                        onClick={() => {
                                                                            this.openTimesheetDetails(charge.timesheetId);
                                                                        }}
                                                                    >
                                                                        {charge.timesheetNumber}
                                                                    </div>
                                                                </td>
                                                                <td>{charge.dispatchLocation}</td>
                                                                <td>{charge.chargeName}</td>
                                                                <td title={charge.jobStart != null ? moment(charge.jobStart).format('M/D/YYYY h:mm A') : '---'}>{charge.jobStart != null ? moment(charge.jobStart).format('h:mm A') : '---'}</td>
                                                                <td title={charge.startTime != null ? moment(charge.startTime).format('M/D/YYYY h:mm A') : '---'}>{charge.startTime != null ? moment(charge.startTime).format('h:mm A') : '---'}</td>
                                                                <td title={charge.endTime != null ? moment(charge.endTime).format('M/D/YYYY h:mm A') : '---'}>{charge.endTime != null ? moment(charge.endTime).format('h:mm A') : '---'}</td>
                                                                <td style={{ textAlign: 'right' }}>{charge.value}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    {charge.bill}
                                                                    {charge.breakAmt > 0
                                                                    && (
                                                                        <span
                                                                            className="ml-2 badge badge-info"
                                                                            style={{
                                                                                fontSize: '1em',
                                                                                marginTop: '-2px',
                                                                                cursor: 'default',
                                                                            }}
                                                                            title={`Hours: ${charge.breakAmt}`}
                                                                        >
                                                                      B
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>{charge.reg}</td>
                                                                <td style={{ textAlign: 'right' }}>{charge.ot}</td>
                                                                <td style={{ textAlign: 'center' }}>{charge.isMinCancel ? 'Yes' : 'No'}</td>
                                                                <td style={{ textAlign: 'center' }}>{charge.isMinMaxBill ? 'Yes' : 'No'}</td>
                                                                <td>{charge.processedBy}</td>
                                                                <td>
                                                                    {charge.processDate != null ? moment(charge.processDate).format('M/D/YYYY h:mm A') : ''}
                                                                &nbsp;&nbsp;

                                                                    {charge.isOutOfOrder && charge.ot > 0
                                                                    && (
                                                                        <i
                                                                            style={{ color: 'red' }}
                                                                            className="fas fa-exclamation-circle"
                                                                            title="OT Charge was processed out of order"
                                                                        />
                                                                    )}

                                                                    {charge.isOutOfOrder && charge.ot == 0
                                                                    && (
                                                                        <i
                                                                            style={{ color: 'orange' }}
                                                                            className="fas fa-exclamation-triangle"
                                                                            title="Charge was processed out of order"
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td>{charge.billingStatus}</td>
                                                                <td>
                                                                    <div
                                                                        className="site-link"
                                                                        onClick={() => {
                                                                            this.openInvoice(charge.invoiceId);
                                                                        }}
                                                                    >
                                                                        {charge.invoiceNumber}
                                                                    </div>
                                                                </td>
                                                                <td style={{ display: 'none' }} />
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </FormGroup>

                                        <table>
                                            <tr>
                                                <td>
                                                    <FormGroup style={{ marginTop: '-15px' }}>
                                                        <FormLabel
                                                            text="Charged Hours"
                                                        />

                                                        <table className="table table-sm table-bordered table-striped adjustment-table" style={{ width: '150px' }}>
                                                            <thead className="text-muted">
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', minWidth: '75px' }}>Hours</th>
                                                                    <th style={{ textAlign: 'center', minWidth: '75px' }}>Breaks</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center' }}>{company.totalHours}</td>
                                                                    <td style={{ textAlign: 'center' }}>{company.breakHours}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </FormGroup>
                                                </td>

                                                <td><div style={{ width: '10px' }}>&nbsp;</div></td>

                                                <td>
                                                    <FormGroup style={{ marginTop: '-15px' }}>
                                                        <FormLabel
                                                            text="Processed Hours"
                                                        />

                                                        <table className="table table-sm table-bordered table-striped adjustment-table" style={{ width: '225px' }}>
                                                            <thead className="text-muted">
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', minWidth: '75px' }}>Hours</th>
                                                                    <th style={{ textAlign: 'center', minWidth: '75px' }}>Regular</th>
                                                                    <th style={{ textAlign: 'center', minWidth: '75px' }}>OT</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center' }}>{company.totalHours - company.breakHours}</td>
                                                                    <td style={{ textAlign: 'center' }}>{company.regularHours}</td>
                                                                    <td style={{ textAlign: 'center' }}>{company.totalHours - (company.regularHours + company.breakHours)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </FormGroup>
                                                </td>

                                                {/* {company.useDailyOT && company.dailyOTThreshold > 0 && */}
                                                {/*    <> */}
                                                {/*        <td><div style={{ width: '10px' }}>&nbsp;</div></td> */}
                                                {/*        <td> */}
                                                {/*            <FormGroup style={{ marginTop: '-15px' }}> */}
                                                {/*                <FormLabel */}
                                                {/*                    text="(Daily OT)" */}
                                                {/*                /> */}

                                                {/*                <table className="table table-sm table-bordered table-striped adjustment-table" style={{ width: '200px'}}> */}
                                                {/*                    <thead className="text-muted"> */}
                                                {/*                        <tr> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Mon</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Tue</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Wed</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Thu</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Fri</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Sat</th> */}
                                                {/*                            <th style={{ textAlign: 'center', minWidth: '75px' }}>Sun</th> */}
                                                {/*                        </tr> */}
                                                {/*                    </thead> */}
                                                {/*                    <tbody> */}
                                                {/*                        <tr> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Mon}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Tue}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Wed}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Thu}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Fri}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Sat}</td> */}
                                                {/*                            <td style={{ textAlign: 'center' }}>{company.dailyOTReg_Sun}</td> */}
                                                {/*                        </tr> */}
                                                {/*                    </tbody> */}
                                                {/*                </table> */}
                                                {/*            </FormGroup> */}
                                                {/*        </td> */}
                                                {/*    </> */}
                                                {/* } */}
                                            </tr>
                                        </table>

                                    </CollapseUnderlineHeader>
                                );
                            })
                        }

                    </SlideForm>
                )

            )

        );
    }
}
