import { v4 as uuid } from 'uuid';

export class Invoice {
    id = null;

    invoiceStatusId = 0;
}

export const InvoiceChargeDetailType = {
    LineItem: 1,
    Header: 2,
    Annotation: 3,
};

export const InvoiceStatus = {
    New: 1,
    Draft: 2,
    Approved: 3,
    Submitted: 4,
    Exported: 5,
    InvoiceIssue: 6,
    PendingReview: 7,
    Deleted: 8,
};

export const AdjustmentTypeOptionsValues = {
    Add: 'ADD',
};

export const AdjustmentTypeOptions = [
    { value: AdjustmentTypeOptionsValues.Add, label: 'Add Charge', className: 'fa-plus-circle text-success' },
    { value: AdjustmentTypeOptionsValues.Adjust, label: 'Adjust Charge', className: 'fa-minus-circle text-danger' },
];

export class InvoiceChargeDetailAdjustment {
    id = uuid();

    invoiceId = 0;

    removed = false;

    chargeQty = 0;

    description = '';

    billingRate = 0;

    chargeAmount = 0;

    current = true;

    IsAdjustment = true;

    invoicechargeDetailTypeId = 1;

    adjustmentType = AdjustmentTypeOptionsValues.Add;
}
