import React, { Children } from 'react';
import { FlexCenterRow, FlexStartRow } from '../common/forms/FormElements';
import { withRouter } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import './AssignmentCalendar.scss';
import moment from 'moment';
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarDay, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';

const localizer = momentLocalizer(moment);

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
};

const views = [
    'month',
    'week',
    'day',
];

const api_date_format = 'YYYYMMDD';

class AssignmentToolbar extends React.Component {
    static contextType = CommonContext;

    navigate = (action) => { this.props.onNavigate(action); };

    render() {
        const { label } = this.props;
        if (!(this.context ?? {}).user) return null;
        return (
            <ButtonToolbar className="mb-2 d-flex flex-row">
                <ButtonGroup size="sm">

                    <Button color="outline-primary p-1" onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
                        <i className="fa fa-step-backward" />
                    </Button>
                    <Button color="outline-primary p-1" onClick={this.navigate.bind(null, navigate.TODAY)}>
                        <small>TODAY</small>
                    </Button>
                    <Button color="outline-primary p-1" onClick={this.navigate.bind(null, navigate.NEXT)}>
                        <i className="fa fa-step-forward" />
                    </Button>
                </ButtonGroup>
                <div className="rbc-toolbar-label d-flex flex-column flex-fill align-items-center justify-content-center">
                    <strong>{label}</strong>
                    <small>{this.context.user.fullName}</small>
                </div>
                <ButtonGroup size="sm">
                    {views.map((view) => (
                        <Button color="outline-primary text-capitalize" key={view} onClick={() => this.props.onView(view)}>
                            <FontAwesomeIcon className="text-muted" icon={view === 'month' ? faCalendarAlt : (view === 'day' ? faCalendarDay : faCalendarWeek)} size="lg" />
                        </Button>
                    ))}
                </ButtonGroup>
            </ButtonToolbar>
        );
    }
}

class AssignmentEvent extends React.Component {
    static contextType = CommonContext;

    render() {
        let { event } = this.props;
        const jobTitle = `${event.jobTitle}${(event.subcontractorName ? ` - ${event.subcontractorName}` : '')}`
        if (!(this.context ?? {}).user)
            return null;
        return (
            <FlexStartRow>
                <span>{`${event.title}`}</span>
                <span className="ml-1 event-job-title">{` - ${jobTitle}`}</span>
            </FlexStartRow>
        );
    }
}

const CURRENT_DATE = moment();

const ColoredDateCellWrapper = ({ children, value }) => React.cloneElement(Children.only(children), {
    style: {
        ...children.style,
        backgroundColor:
                CURRENT_DATE.isAfter(moment(value), 'day')
                    ? '#ddd'
                    : CURRENT_DATE.isSame(moment(value), 'day')
                        ? '#c0e1fa'
                        : 'white',
    },
});

class AssignmentCalendar extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            events: [], // list of calendar events
            user: null,
            calendarAssignments: [],
            view: 'month',
            defaultView: 'month',
            startDate: moment().startOf('month').format(api_date_format),
            endDate: moment().endOf('month').format(api_date_format),
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onNavigate = async (date, view, action) => {
        await this.setState({ date });
        this.getAssignments();
    };

    getAssignments = async () => {
        await this.setState({ loading: true });
        const { date, view } = { ...this.state };
        const start = moment(date).startOf(view).format(api_date_format);
        const end = moment(date).endOf(view).format(api_date_format);

        const [calendarAssignments] = await Promise.all([
            util.fetch.js(ApiRoutes.calendar.get(start, end)),
        ]);

        // https://jquense.github.io/react-big-calendar/examples/index.html#prop-events
        const events = calendarAssignments.map((x) => {
            const ev = {
                title: `${moment(x.day).format('h:mma')}`.slice(0, -1),
                start: moment(x.day).toDate(),
                end: moment(x.day).toDate(),
                allDay: false,
                resource: null,
                eventTimeRangeFormat: '',
            };
            return { ...x, ...ev };
        });

        this.setState((state) => ({
            events: events ?? [],
            loading: false,
            calendarAssignments,
        }));
    };

    setSaving = (b) => this.setState({ saving: b });

    changeView = (view) => {
        this.setState({ view });
    };

    customEventPropGetter = (event) => {
        let statusClass = '';

        if (event.rejected) statusClass = 'rejected';
        else statusClass = event.confirmed ? 'confirmed' : 'unconfirmed';

        return { className: `${this.state.view} ${statusClass}` };
    };

    handleEventSelect = (event, e) => {
        if (event.timesheetId > 0) {
            this.props.history.push(`${AppNavPaths.Timesheet}/${event.timesheetId}/`, { assignmentId: event.assignmentId });
        } else {
            // confirm
            if (event.isCancellation) {
                this.props.history.push(`${AppNavPaths.CancelConfirmation}/${event.assignmentCancellationId}`);
            } else {
                this.props.history.push(`${AppNavPaths.AssignmentConfirmation}/${event.assignmentId}`);
            }
        }
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            this.getAssignments();
        }
    }

    render() {
        const {
            events,
        } = this.state;

        if (!(this.context ?? {}).user) return null;

        return (
            <div className="assignmentCalendarPage calendar-content p-1">
                <Calendar
                    selectable
                    toolbar
                    views={views}
                    defaultView="month"
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    events={events}
                    longPressThreshold={75}
                    onSelectEvent={this.handleEventSelect.bind(this)}
                    eventPropGetter={this.customEventPropGetter.bind(this)}
                    components={{ toolbar: AssignmentToolbar, event: AssignmentEvent, dateCellWrapper: ColoredDateCellWrapper }}
                    onView={this.changeView.bind(this)}
                    onNavigate={this.onNavigate}
                />
                <FlexCenterRow className="confirm-legend">
                    <small className="unconfirmed">Unconfirmed</small>
                    <small className="confirmed">Confirmed</small>
                    <small className="rejected ml-2">Time Rejected</small>
                </FlexCenterRow>
            </div>
        );
    }
}

export default withRouter(AssignmentCalendar);
