import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@mui/material';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { faUserTag } from '@fortawesome/free-solid-svg-icons';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    DataGridRefreshButton,
    refreshDataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import { Can } from '../Can';
import CommonContext, { ApiRoutes, agGridConstants } from '../Common';
import {
    FlexRow,
    FlexStartRow,
    FlexEndRow,
    PageHeading,
    SmallButton,
    onReactSelectChanged,
    onFieldChange,
    PageWrap,
    toasty,
} from '../common/forms/FormElements';
import DailyAttendanceEventForm from './DailyAttendanceEventForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { DailyAttendanceEvent } from './DailyAttendanceEvent';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';

class DailyAttendanceEventIndex extends React.Component {
    // bugbug: the index/edit components were borrowed from an outdated component,
    // so don't go patterning other pieces off of this
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        const openNew = params.get('new') === 'true';

        this.state = {
            loading: true,
            types: [],
            selectedDailyAttendanceEvent: openNew
                ? new DailyAttendanceEvent()
                : null,
            showDailyAttendanceEventForm: openNew,
        };
        this.onChange = this.onChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onAddDailyAttendanceEvent = () => {
        this.setState({
            selectedDailyAttendanceEvent: new DailyAttendanceEvent(),
            showDailyAttendanceEventForm: true,
        });
    };

    onChange = onFieldChange;

    onDailyAttendanceEventDeleteCallback = () => {
        this.onDailyAttendanceEventFormClosed();

        this.state.gridApi.setDatasource(this.state.dataSource);
    };

    onDailyAttendanceEventFormClosed = () => {
        this.setState({
            selectedDailyAttendanceEvent: null,
            showDailyAttendanceEventForm: false,
        });
    };

    onDailyAttendanceEventSaveCallback = async () => {
        await this.setState({ saving: true });
        const { selectedDailyAttendanceEvent } = this.state;

        const saveUrl = selectedDailyAttendanceEvent.id
            && selectedDailyAttendanceEvent.id > 0
            ? ApiRoutes.dailyAttendanceEvent.update()
            : ApiRoutes.dailyAttendanceEvent.create();

        const response = await util.fetch
            .andGetResponse(
                util.fetch.types.post,
                saveUrl,
                selectedDailyAttendanceEvent,
                'Error Saving Daily Attendance Event',
                () => this.setState({ saving: false }),
            )
            .catch(this.handleSaveError);

        if (response && response > 0) {
            this.notifySuccess();

            this.setState({
                selectedDailyAttendanceEvent: null,
                showDailyAttendanceEventForm: false,
                saving: false,
            });

            this.state.gridApi.setDatasource(this.state.dataSource);
        }
    };

    onEditDailyAttendanceEvent = async (DailyAttendanceEventId) => {
        const selectedDailyAttendanceEvent = await util.fetch.js(
            ApiRoutes.dailyAttendanceEvent.byId(DailyAttendanceEventId),
        );

        this.setState({
            selectedDailyAttendanceEvent,
            showDailyAttendanceEventForm: true,
        });
    };

    onSelectChange = onReactSelectChanged;

    booleanTranslator = (paramValue) => {
        const value = Boolean(paramValue && paramValue.value);

        return value ? 'Yes' : 'No';
    };

    clearErrors = () => this.setState((state) => ({ errors: {} }));

    handleSaveError = (err) => {
        console.debug(err);
        handleFormSaveError(this, err);
    };

    notifyError = (message) => toasty.error(
        'Save Unsuccessful',
        `There was a server error saving this Daily Attendance Event - (${message}).  Please try your request again or contact support for assistance.`,
    );

    notifySuccess = () => toasty.success(
        'Daily Attendance Event Saved',
        'Daily Attendance Event saved successfully.',
    );

    async populateState() {
    // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            nameRenderer: LinkCellRenderer,
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
        };

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        const yesNoFilterParams = {
            suppressFilterButton: true,
            options: yesNo,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: null,
        };

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                colId: 'Name',
                sortable: true,
                headerName: 'Name',
                field: 'name',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditDailyAttendanceEvent,
                    nameField: 'name',
                    idField: 'id',
                    title: 'View this Daily Attendance Event',
                },
            },
            {
                colId: 'active',
                sortable: false,
                headerName: 'Active',
                field: 'active',
                cellRenderer: this.booleanTranslator,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
            {
                colId: 'employeeMayNotBeAssigned',
                sortable: false,
                headerName: 'Employee May Not Be Assigned For Rest Of Day',
                field: 'employeeMayNotBeAssigned',
                cellRenderer: this.booleanTranslator,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
        ];

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(
            ApiRoutes.dailyAttendanceEvent.search(),
            gridOptions,
        );

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    refreshGrid = () => refreshDataGrid;

    render() {
        if (this.state.loading) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        const {
            rowData,
            gridOptions,
            showDailyAttendanceEventForm,
            selectedDailyAttendanceEvent,
        } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faUserTag}
                        className="mr-2 text-muted"
                    />
                    <span>Daily Attendance Events</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                                <DataGridRefreshButton
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                />
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create" a="longtermstatus">
                                    <CommonContext.Consumer>
                                        {(value) => (
                                            <SmallButton
                                                type="button"
                                                disabled={
                                                    showDailyAttendanceEventForm
                                                }
                                                onClick={
                                                    this
                                                        .onAddDailyAttendanceEvent
                                                }
                                            >
                                                <i className="fa fa-plus-circle fa-md mr-2" />
                        Add Daily Attendance Event
                                            </SmallButton>
                                        )}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                />
                <DailyAttendanceEventForm
                    selectedDailyAttendanceEvent={selectedDailyAttendanceEvent}
                    onChange={this.onChange}
                    onSelectChange={this.onSelectChange}
                    onClose={this.onDailyAttendanceEventFormClosed}
                    show={showDailyAttendanceEventForm}
                    onSaveCallback={this.onDailyAttendanceEventSaveCallback}
                    onDeleteCallback={this.onDailyAttendanceEventDeleteCallback}
                />
            </PageWrap>
        );
    }
}

export default withRouter(DailyAttendanceEventIndex);
