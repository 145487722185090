import { ApiRoutes, ApplicationPermissions } from '../Common';
import { util } from '../Util';

export const getTenantUserProfile = async () => {
    const [currentUser, permissions, userTenant] = await Promise.all([
        util.fetch.js(ApiRoutes.auth.profile()),
        util.fetch.js(ApiRoutes.auth.permissions()),
        util.fetch.js(ApiRoutes.tenant.settings()),
    ]);

    const userPermissions = permissions.map(
        (x) => `${x.permission}.${x.action}`,
    );

    currentUser.isAdmin = !!(
        (currentUser.roles ?? []).filter(
            (x) => x.displayName === 'Administrator',
        ) ?? []
    ).length;

    currentUser.canCreateNonFlagTimesheet = Boolean(
        currentUser.employeeId
        && userPermissions.includes(
            ApplicationPermissions.nonFlagging_timesheet_create,
        ),
    );

    // This feature is not currently implemented
    currentUser.canCreateSelfDispatchTimesheet = false;

    /*
    currentUser.canCreateSelfDispatchTimesheet = Boolean(
        currentUser.employeeId &&
        userPermissions.includes(
                ApplicationPermissions.self_dispatch_timesheet_create
            )
    );
    */

    return {
        currentUser,
        userTenant,
        userPermissions,
    };
};
