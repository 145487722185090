import { v4 as uuid } from 'uuid';
import { BillingRate } from '../billing/BillingRate';

export class Organization {
    id = null;

    name = '';

    locationId = null;

    parentCompanyId = null;

    tenantCompanyId = null;

    customerCompanyId = null;

    address1 = '';

    address2 = '';

    city = '';

    state = '';

    zip = '';

    phone = '';

    countryCode = '';

    types = [];

    typesFlat = '';

    contacts = [];

    companyChargeTypeGroups = [];

    operationsCentersApply = false;

    subcontractorsApply = false;

    parentCustomerApply = false;

    hasAlternativePayroll = false;

    useInvoicing = false;

    operationsCenterUseInvoicing = false;

    showRateForm = false;

    selectedRate = null;

    billingRates = [];

    companyMinimumWageRates = [];

    useAsBillingAddress = true;
}

export class OrganizationContact {
    // 2022-05-23 - M. Nicol - changed to null from uuid().
    // OrganizationContactForm.js assumes we're editing an existing contact if id has a vaule.
    // OrganizationForm.js uses array.util.upsert though to assume that identical keys are for the
    // same vaule.  I updated it with a new optional parameter to consider nulls equal or not.
    id = null; // uuid();

    firstName = '';

    lastName = '';

    title = '';

    email = '';

    mobilePhone = '';

    mobilePhoneIsPrimary = false;

    officePhone = '';

    officePhoneIsPrimary = false;

    locationContactTypeId = null;

    locationContactTypeName = '';

    isActive = true;
}

export class CompanyChargeTypeBillingRate {
    companyId = null;

    chargeTypeId = null;

    chargeTypeName = '';

    billingRateId = uuid();

    billingRate = new BillingRate();
}

export class MinimumWageRate {
    id = 0;

    companyId = 0;

    payRate = null;

    startDate = null;

    endDate = null;
}

export const OrganizationTypes = {
    Billing: 2,
    Payroll: 3,
    Dispatch: 4,
    Customer: 5,
    OperationsCenter: 6,
    CustomerSubcontractor: 7,
    Carrier: 8,
    ParentCustomer: 9,
    Prospect: 10,
};

export const BillingOTDays = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 4,
    Wednesday: 8,
    Thursday: 16,
    Friday: 32,
    Saturday: 64,
};
