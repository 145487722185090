import * as React from 'react';
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import { ApiRoutes, ApplicationPermissions } from '../Common';
import { FlexStartRow, FormLabel, toasty } from '../common/forms/FormElements';
import { Contract } from '../contracts/Contract';
import {
    RejectionNote,
    TimesheetStatus,
    TimesheetAuditType,
} from '../timesheet/Timesheet';
import { util } from '../Util';
import { WorkOrderTypes } from '../workOrder/WorkOrder';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { DateTimeInputFormat } from '../dispatch/Dispatch';

export class Job {
    contractId = 0;

    contractSequence = 0;

    weekOfDate = '';

    requestedById = 0;

    foremanId = 0;

    startTime = '';

    dispatchOfficeLocationId = '';

    dispatchOfficeLocationName = '';

    workOrderNumber = '';

    jobCity = '';

    notes = '';

    resources = [];

    nonResources = [];

    overrides = [];

    contract = new Contract();

    meetingLocation = null; // Address()

    jobLocation = null; // Address()

    hasAssignments = false;

    tenantSettings = null;
}

export class JobDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            workOrderNumberValid: true,
            foremanValid: true,
            showEmergencyConfirmModal: false,
            isCallingOutAssignment: false,
            isCancellingJob: false,
            showCancelJobModal: false,
        };
    }

    onClientCancel = async () => {
        const {
            timesheetId,
            timesheetNumber,
            onRefresh,
            jobId,
            jobStartTimeRaw,
        } = { ...this.props };

        const { selectedJobCancellationTime, selectedJobCancellationNotes } = {
            ...this.state,
        };

        const jobDate = new Date(jobStartTimeRaw);
        const JobDay = jobDate.getDay();

        const model = {
            jobId,
            day: JobDay,
            cancellationTypeId: 1, // client cancel only
            cancellationNote: selectedJobCancellationNotes,
            cancellationTime: selectedJobCancellationTime,
            sendNotifications: false,
        };

        this.setState({ isCancellingJob: true });

        const response = await util.fetch.post(
            ApiRoutes.dispatch.cancelJob(),
            model,
        );

        if (response === 'ok') {
            await util.fetch.put(ApiRoutes.timesheet.audit(), {
                timesheetId,
                timesheetNumber,
                timesheetAuditTypeId: TimesheetAuditType.ClientCancelApplied,
                systemMessage: selectedJobCancellationTime,
                userNotes: selectedJobCancellationNotes,
            });

            this.setState({
                showCancelJobModal: false,
                isCancellingJob: false,
                selectedJobCancellationTime: DateTimeInputFormat(new Date()),
                selectedJobCancellationNotes: '',
            });

            toasty.success('Client Cancellation Successful');
            onRefresh();
        } else {
            this.setState({
                showCancelJobModal: false,
                isCancellingJob: false,
                selectedJobCancellationTime: DateTimeInputFormat(new Date()),
                selectedJobCancellationNotes: '',
            });

            toasty.error('Client Cancellation Error');
        }
    };

    onClientCancelClick = () => {
        this.setState({
            selectedJobCancellationTime: DateTimeInputFormat(new Date()),
            showCancelJobModal: true,
        });
    };

    onEmergencyAssignment = async () => {
        this.setState({ isCallingOutAssignment: true });
        const {
            onRefresh,
            timesheetId,
            isEmergency,
            isBillingEmergency,
            isPayrollEmergency,
            isPayroll,
            isBilling,
            isTimesheet,
        } = this.props;

        let isEmergencyOverall = false;
        if (isTimesheet && isEmergency) isEmergencyOverall = true;
        if (isBilling && isBillingEmergency) isEmergencyOverall = true;
        if (isPayroll && isPayrollEmergency) isEmergencyOverall = true;

        const model = {
            timesheetId,
            isEmergency: !isEmergencyOverall,
        };
        let route = ApiRoutes.timesheet.setEmergency();
        if (isPayroll) route = ApiRoutes.payrollManagement.setEmergency();
        else if (isBilling) route = ApiRoutes.billing.setEmergency();

        const response = await util.fetch.post(route, model);

        if (response.success) {
            onRefresh(); // refreshes reused
        } else {
            toasty.warning('Error adjusting emergency status.', {
                autoClose: false,
                closeOnClick: true,
            });
        }

        this.setState({
            showEmergencyConfirmModal: false,
            isCallingOutAssignment: false,
            selectedEmergencyAssignments: null,
        });
    };

    onEmergencyClicked = () => {
    // validation?
        const { isSelfDispatching } = { ...this.props };

        if (isSelfDispatching) return false;

        // open a modal for "are you sure"
        this.toggleEmergencyConfirmModal();
    };

    onUndoClientCancel = async () => {
        await this.setState({ undoingClientCancel: true });

        const { timesheetId, timesheetNumber, onRefresh } = { ...this.props };

        let success = false;
        try {
            const response = await util.fetch.put(
                ApiRoutes.timesheet.undoClientCancel(),
                timesheetId,
                util.fetch.format.none,
            );

            success = !!response.ok;
        } catch {
            success = false;
        } finally {
            this.setState({ undoingClientCancel: false });
        }

        if (success) {
            await util.fetch.put(ApiRoutes.timesheet.audit(), {
                timesheetId,
                timesheetNumber,
                timesheetAuditTypeId: TimesheetAuditType.ClientCancelRemoved,
                systemMessage: '',
                userNotes: '',
            });

            toasty.success('Client Cancellation Undone.');
            onRefresh();
        } else {
            toasty.error(
                'There was an error undoing the client cancel. Please try your request again.',
            );
        }
    };

    onUndoClientCancelClick = async () => {
        this.setState({
            showUndoClientCancelModal: true,
        });
    };

    loadOptions = (inputValue, callback) => {
        fetch(ApiRoutes.typeAheads.jobCities(inputValue))
            .then((response) => response.json())
            .then((data) => callback(data));
    };

    saveTimesheetWorkOrderNumber = async (evt) => {
        await this.setState({ workOrderNumberValid: true });
        const {
            onRefresh,
            requireWorkOrder,
            workOrderNumber,
            timesheetId,
            timesheetNumber,
            foremanId,
        } = { ...this.props };

        // Validation
        if (!!requireWorkOrder && !workOrderNumber) {
            this.setState({ workOrderNumberValid: false });
            return false;
        }

        if (!foremanId) {
            this.setState({ foremanValid: false });
            return false;
        }

        const { saving } = { ...this.state };
        if (!saving) {
            await this.setState({ saving: true });

            let success = false;
            try {
                const response = await util.fetch.put(
                    ApiRoutes.timesheet.updateWorkOrderNumber(timesheetId),
                    workOrderNumber,
                    util.fetch.format.none,
                );
                success = !!response.ok;
            } catch {
                success = false;
            } finally {
                this.setState({ saving: false });
            }

            if (success) {
                toasty.success(
                    `[${timesheetNumber}]: Work Order Number Updated.`,
                );
                onRefresh();
            } else {
                toasty.error(
                    'There was an error updating the work order number. Please try your request again.',
                );
            }
        }
    };

    toggleCancelJobModal = () => {
        const { showCancelJobModal } = this.state;
        this.setState({ showCancelJobModal: !showCancelJobModal });
    };

    toggleEmergencyConfirmModal = () => {
        const { showEmergencyConfirmModal, isCallingOutAssignment } = this.state;

        if (showEmergencyConfirmModal == false) this.isCallingOutAssignment = false;

        this.setState({
            showEmergencyConfirmModal: !showEmergencyConfirmModal,
            isCallingOutAssignment,
        });
    };

    toggleUndoConfirmModal = () => {
        const { showUndoClientCancelModal } = this.state;
        this.setState({
            showUndoClientCancelModal: !showUndoClientCancelModal,
        });
    };

    render() {
        const { workOrderNumberValid } = { ...this.state };

        const {
            customerName,
            subcontractorName,
            enableWorkOrderNumberEditing,
            customerContactsOptions,

            enableForemanEditing,
            foremanChanged,
            foremanId,
            initialForemanId,
            foremanName,
            foremanPhone,

            enableRequestorEditing,
            requestorChanged,
            requestorId,
            initialRequestorId,
            requestorName,
            requestorPhone,

            jobNotes,
            jobNumber,
            jobCity,
            jobStartTime,
            lastRejectionNote,
            onWorkOrderChanged,
            onJobCityChanged,
            permissions,
            timesheetNumber,
            timesheetStatus,
            statusRejectionNote,

            workOrderNumber,
            initialWorkOrderNumber,
            workOrderNumberChanged,
            workOrderType,
            disabledProperties,
            isClientCancelled,

            isEmergency,
            emergencyEmployee,
            timesheetStatusId,
            isTimesheetForm,
            tenantSettings,
            isTimesheet,
            isPayroll,
            isBilling,
            isBillingEmergency,
            billingEmergencyEmployee,
            isPayrollEmergency,
            payrollEmergencyEmployee,
            billingStatusId,
            payrollStatusId,
            canEmergency,
            isSelfDispatching,
            isNonFlagging,
            isAdmin,
            jobName,
        } = { ...this.props };

        const {
            isCallingOutAssignment,
            showEmergencyConfirmModal,
            showCancelJobModal,
            showUndoClientCancelModal,
            selectedJobCancellationTime,
            selectedJobCancellationNotes,
            isCancellingJob,
            undoingClientCancel,
        } = this.state;

        const perms = (permissions ?? []).map(
            (x) => `${x.permission}.${x.action}`,
        );
        const workOrderNA = workOrderType === WorkOrderTypes.NotApplicable;
        const requireWorkOrder = workOrderType === WorkOrderTypes.Required;
        const customerNameFormatted = (customerName ?? '')
            + (subcontractorName ? ` - ${subcontractorName}` : '');
        const foremanNameFormatted = (foremanName ?? '-') + (foremanPhone ? ` - ${foremanPhone}` : '');
        const requestorNameFormatted = (requestorName ?? '-')
            + (requestorPhone ? ` - ${requestorPhone}` : '');

        let emergencyEmployeeName = emergencyEmployee;
        if (isPayroll && !!payrollEmergencyEmployee) {
            emergencyEmployeeName = payrollEmergencyEmployee;
        }
        if (isBilling && !!billingEmergencyEmployee) {
            emergencyEmployeeName = billingEmergencyEmployee;
        }
        let isEmergencyOverall = false;
        if (isTimesheet && isEmergency) isEmergencyOverall = true;
        if (isBilling && isBillingEmergency) isEmergencyOverall = true;
        if (isPayroll && isPayrollEmergency) isEmergencyOverall = true;

        const timesheetUseJobCity = tenantSettings
            ? tenantSettings.timesheetUseJobCity
            : false;

        const showContractDescriptionInTimesheetView = Boolean(
            tenantSettings
                && tenantSettings.showContractDescriptionInTimesheetView
                && jobName,
        );

        return (
            <div className="job-confirm-details">
                {!!timesheetNumber && (
                    <FlexStartRow>
                        <FormLabel
                            htmlFor="timesheetNumber"
                            text="Timesheet #"
                        />
                        <span
                            id="timesheetNumber"
                            name="timesheetNumber"
                            className="text-muted"
                            style={{ marginLeft: '16px' }}
                        >
                            {timesheetNumber ?? ''}
                        </span>
                        {!!isSelfDispatching && (
                            <span
                                className="ml-2 badge badge-info"
                                title={
                                    isNonFlagging
                                        ? 'This is a non-flagging timesheet'
                                        : 'This is a self-dispatching timesheet'
                                }
                                style={{
                                    fontSize: '1em',
                                    marginTop: '-2px',
                                    padding: '2px 4px',
                                }}
                            >
                                {isNonFlagging ? 'NF' : 'SD'}
                            </span>
                        )}
                    </FlexStartRow>
                )}
                {!!timesheetStatus && (
                    <FlexStartRow className="align-items-start">
                        <FormLabel
                            htmlFor="timesheetStatus"
                            text="Status"
                        />
                        {timesheetStatus !== 'Rejected' && (
                            <span
                                className={`timesheet-status-label form-control-sm  ${(
                                    timesheetStatus ?? ''
                                )
                                    .replace(' ', '')
                                    .toLowerCase()}`}
                            >
                                {timesheetStatus}
                            </span>
                        )}
                        {timesheetStatus === 'Rejected'
                                && !!lastRejectionNote && (
                            <div style={{ marginLeft: '20px' }}>
                                {!!lastRejectionNote && (
                                    <RejectionNote
                                        note={lastRejectionNote}
                                    />
                                )}
                            </div>
                        )}
                        {timesheetStatus === 'Billing-Payroll Rejected'
                                && !!statusRejectionNote && (
                            <div style={{ marginLeft: '20px' }}>
                                {!!statusRejectionNote && (
                                    <RejectionNote
                                        note={statusRejectionNote}
                                    />
                                )}
                            </div>
                        )}
                        {timesheetStatus === 'Rejected'
                                && !lastRejectionNote && (
                            <div
                                style={{ marginLeft: '20px' }}
                                className="site-alert alert-danger text-center"
                            >
                                  REJECTED
                            </div>
                        )}

                        {isClientCancelled && (
                            <>
                                <span className="timesheet-status-label">
                                    {' '}
              -
                                    {' '}
                                </span>
                                <span
                                    className="timesheet-status-label form-control-sm client-cancel"
                                >
              Client Cancel
                                </span>
                            </>
                        )}
                    </FlexStartRow>
                )}

                <FlexStartRow>
                    <FormLabel htmlFor="jobStartTime" text="Date/Start" />
                    <span
                        id="jobStartTime"
                        name="jobStartTime"
                        className="form-control-sm form-control-plaintext"
                    >
                        {jobStartTime ?? ''}
                    </span>
                </FlexStartRow>

                <FlexStartRow>
                    <FormLabel htmlFor="customerName" text="Customer" />
                    <span
                        id="customerName"
                        name="customerName"
                        className="form-control-sm form-control-plaintext"
                    >
                        {customerNameFormatted ?? ''}
                    </span>
                </FlexStartRow>
                {showContractDescriptionInTimesheetView && (
                    <FlexStartRow>
                        <FormLabel htmlFor="jobName" text="Contract" />
                        <span
                            id="jobName"
                            name="jobName"
                            className="form-control-sm form-control-plaintext"
                        >
                            {jobName ?? ''}
                        </span>
                    </FlexStartRow>
                )}
                {!isSelfDispatching && (
                    <FlexStartRow>
                        <FormLabel htmlFor="jobNumber" text="Job" />
                        <span
                            id="jobNumber"
                            name="jobNumber"
                            className="form-control-sm form-control-plaintext"
                        >
                            {jobNumber ?? ''}
                        </span>
                    </FlexStartRow>
                )}
                {!isSelfDispatching && timesheetUseJobCity && (
                    <FlexStartRow>
                        <FormLabel htmlFor="jobCity" text="Job City" />
                        <div className="adj-indicator" />
                        <div className="d-flex flex-column">
                            {!!enableWorkOrderNumberEditing
                                    && perms.includes(
                                        ApplicationPermissions.job_edit,
                                    ) && (
                                <AsyncSelect
                                    classNamePrefix="react-async"
                                    className="smallReactAsync"
                                    loadOptions={this.loadOptions}
                                    placeholder="Type City Name Here"
                                    id="jobCity"
                                    value={
                                        jobCity != null
                                            ? {
                                                label: jobCity,
                                                value: jobCity,
                                            }
                                            : ''
                                    }
                                    onChange={onJobCityChanged}
                                />
                            )}
                        </div>
                    </FlexStartRow>
                )}
                {
                    /* TODO - Check for contract setting for work orders on self-dispatching timesheets */
                    !isSelfDispatching && (
                        <FlexStartRow id="workOrderRow">
                            <FormLabel
                                htmlFor="workOrderNumber"
                                text="Work Order #"
                            />
                            <div className="adj-indicator">
                                {!!workOrderNumberChanged && (
                                    <span
                                        title="This work order # has been adjusted."
                                        className="badge badge-lg badge-warning adj-badge"
                                        style={{
                                            fontSize: '100% !important',
                                        }}
                                    >
                              ADJ
                                    </span>
                                )}
                            </div>
                            {
                                // Read only fields when WON editing is off.
                                (!enableWorkOrderNumberEditing
                                        || !!workOrderNA) && (
                                    <div className="d-flex flex-column">
                                        <span
                                            id="workOrderNumber"
                                            name="workOrderNumber"
                                            readOnly
                                            className="form-control-sm form-control-plaintext ml-0"
                                        >
                                            {workOrderNumber ?? '-'}
                                        </span>
                                        {!!workOrderNumberChanged && (
                                            <small className="mt-1">
                                                <strong className="mr-1 text-muted">
                                                Initial Work Order:
                                                </strong>
                                                {`${initialWorkOrderNumber}`}
                                            </small>
                                        )}
                                    </div>
                                )
                            }
                            {
                                // Read only field if no permission, otherwise provide input.
                                !!enableWorkOrderNumberEditing && (
                                    <>
                                        {!perms.includes(
                                            ApplicationPermissions.job_edit,
                                        )
                                                && !workOrderNA && (
                                            <div className="d-flex flex-column">
                                                <span
                                                    id="workOrderNumber"
                                                    name="workOrderNumber"
                                                    readOnly
                                                    className="form-control-sm form-control-plaintext ml-0"
                                                >
                                                    {workOrderNumber
                                                                ?? '-'}
                                                </span>
                                                {!!workOrderNumberChanged && (
                                                    <small className="mt-1">
                                                        <strong className="mr-1 text-muted">
                                                      Initial Work
                                                      Order:
                                                        </strong>
                                                        {`${initialWorkOrderNumber}`}
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                        {!!perms.includes(
                                            ApplicationPermissions.job_edit,
                                        )
                                                && !workOrderNA && (
                                            <div className="d-flex flex-column">
                                                <FlexStartRow className="work-order-number-editing">
                                                    <input
                                                        autoComplete="off"
                                                        id="workOrderNumber"
                                                        maxLength="100"
                                                        required={
                                                            requireWorkOrder
                                                        }
                                                        disabled={
                                                            disabledProperties.includes('workOrderNumber')
                                                                    && !perms.includes(ApplicationPermissions.billing_view)
                                                        }
                                                        name="workOrderNumber"
                                                        className={cls(
                                                            'p-1 form-control form-control-sm w-auto',
                                                            {
                                                                'border-danger':
                                                                            !workOrderNumberValid,
                                                            },
                                                        )}
                                                        value={
                                                            workOrderNumber
                                                                    ?? ''
                                                        }
                                                        onChange={
                                                            onWorkOrderChanged
                                                        }
                                                    />
                                                </FlexStartRow>
                                                {!workOrderNumberValid && (
                                                    <small className="ml-3 pb-2 invalid-feedback text-danger d-flex">
                                                    Contract
                                                    requires a work
                                                    order number.
                                                    </small>
                                                )}
                                                {!!workOrderNumberChanged && (
                                                    <small className="mt-1">
                                                        <strong className="mr-1 text-muted">
                                                      Initial Work
                                                      Order:
                                                        </strong>
                                                        {`${initialWorkOrderNumber}`}
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </FlexStartRow>
                    )
                }
                {
                    /* TODO - Check for contract setting for foreman capture on self-dispatching timesheets */
                    !isSelfDispatching && !enableForemanEditing && (
                        <FlexStartRow>
                            <FormLabel
                                htmlFor="foremanName"
                                text="Foreman"
                            />
                            <span
                                id="foremanName"
                                name="foremanName"
                                readOnly
                                className="form-control-sm form-control-plaintext"
                            >
                                {foremanNameFormatted}
                            </span>
                        </FlexStartRow>
                    )
                }
                {
                    /* TODO - Check for contract setting for foreman capture on self-dispatching timesheets */
                    !isSelfDispatching && !!enableForemanEditing && (
                        <FlexStartRow>
                            <FormGroup className="p-0 m-0 d-flex flex-row flex-nowrap align-items-start">
                                <FormLabel
                                    htmlFor="foremanId"
                                    text="Foreman"
                                />
                                <div className="adj-indicator">
                                    {!!foremanChanged && (
                                        <span
                                            title="This foreman has been adjusted."
                                            className="badge badge-lg badge-warning adj-badge"
                                            style={{
                                                fontSize: '100% !important',
                                            }}
                                        >
                                ADJ
                                        </span>
                                    )}
                                </div>
                                <div className="d-flex flex-column">
                                    <ValidatedSelect
                                        hideClear
                                        id="foreman"
                                        name="foremanId"
                                        className="smallReactSelect"
                                        options={customerContactsOptions}
                                        value={
                                            (
                                                customerContactsOptions
                                                    ?? []
                                            ).find(
                                                (x) => x.value === foremanId,
                                            ) ?? ''
                                        }
                                        onChange={
                                            this.props.onForemanChanged
                                        }
                                        required
                                        validationMessage="A foreman selection is required."
                                        isDisabled={false}
                                    />
                                    {!!foremanChanged && (
                                        <small className="mt-1">
                                            <strong className="mr-1 text-muted">
                                  Initial Foreman:
                                            </strong>
                                            {`${
                                                customerContactsOptions?.find(
                                                    (x) => x.value
                                                            === initialForemanId,
                                                )?.label
                                            }`}
                                        </small>
                                    )}
                                </div>
                                {!!foremanId && (
                                    <div className="w-100 pt-3 pl-2">
                                        <i className="text-danger fa fa-caret-right mr-2" />
                                        <small
                                            className="site-link"
                                            onClick={() => this.props.onEditContact(
                                                'foremanId',
                                            )}
                                        >
                                Edit Foreman Contact Info
                                        </small>
                                    </div>
                                )}
                            </FormGroup>
                        </FlexStartRow>
                    )
                }
                {
                    /* TODO - Check for contract setting for requestor capture on self-dispatching timesheets */
                    !isSelfDispatching && !enableRequestorEditing && (
                        <FlexStartRow>
                            <FormLabel
                                htmlFor="requestorName"
                                text="Requestor"
                            />
                            <span
                                id="requestorName"
                                name="requestorName"
                                readOnly
                                className="form-control-sm form-control-plaintext"
                            >
                                {requestorNameFormatted}
                            </span>
                        </FlexStartRow>
                    )
                }
                {
                    /* TODO - Check for contract setting for requestor capture on self-dispatching timesheets */
                    !isSelfDispatching && !!enableRequestorEditing && (
                        <FlexStartRow>
                            <FormGroup className="p-0 m-0 d-flex flex-row flex-nowrap align-items-start">
                                <FormLabel
                                    htmlFor="requestorId"
                                    text="Requestor"
                                />
                                <div className="adj-indicator">
                                    {!!requestorChanged && (
                                        <span
                                            title="This requestor has been adjusted."
                                            className="badge badge-lg badge-warning adj-badge"
                                            style={{
                                                fontSize: '100% !important',
                                            }}
                                        >
                                ADJ
                                        </span>
                                    )}
                                </div>
                                <div className="d-flex flex-column">
                                    <ValidatedSelect
                                        hideClear
                                        id="requestor"
                                        name="requestorId"
                                        className="smallReactSelect"
                                        options={customerContactsOptions}
                                        value={
                                            (
                                                customerContactsOptions
                                                    ?? []
                                            ).find(
                                                (x) => x.value === requestorId,
                                            ) ?? ''
                                        }
                                        onChange={
                                            this.props.onRequestorChanged
                                        }
                                        required
                                        validationMessage="A requestor selection is required."
                                        isDisabled={false}
                                    />
                                    {!!requestorChanged && (
                                        <small className="mt-1">
                                            <strong className="mr-1 text-muted">
                                  Initial Requestor:
                                            </strong>
                                            {`${
                                                customerContactsOptions?.find(
                                                    (x) => x.value
                                                            === initialRequestorId,
                                                )?.label
                                            }`}
                                        </small>
                                    )}
                                </div>
                                {!!requestorId && (
                                    <div className="w-100 pt-3 pl-2">
                                        <i className="text-danger fa fa-caret-right mr-2" />
                                        <small
                                            className="site-link"
                                            onClick={() => this.props.onEditContact(
                                                'requestorId',
                                            )}
                                        >
                                Edit Requestor Contact Info
                                        </small>
                                    </div>
                                )}
                            </FormGroup>
                        </FlexStartRow>
                    )
                }
                <FlexStartRow>
                    <FormLabel htmlFor="notes" text="Notes" />
                    <div className="form-control-sm form-control-plaintext">
                        {jobNotes ?? '-'}
                    </div>
                </FlexStartRow>

                <FlexStartRow>
                    <div className="d-flex" style={{ marginTop: '12px' }}>
                        {!isSelfDispatching
                                && (!!perms.includes(
                                    ApplicationPermissions.job_edit,
                                )
                                    || !!perms.includes(
                                        ApplicationPermissions.payroll_edit,
                                    )
                                    || !!perms.includes(
                                        ApplicationPermissions.billing_edit,
                                    ))
                                && ((isTimesheet
                                    && timesheetStatusId
                                        !== TimesheetStatus.Approved)
                                    || (isPayroll
                                        && (payrollStatusId == 1
                                            || payrollStatusId == 3))
                                    || (isBilling
                                        && (billingStatusId == 1
                                            || billingStatusId == 3)))
                                && canEmergency
                                && (tenantSettings ?? {})
                                    .timesheetEmergencyEnabled && (
                            <span
                                className="site-link"
                                style={
                                    !isEmergencyOverall
                                        ? {
                                            color: 'blue',
                                            marginLeft: '4px',
                                            marginRight: '10px',
                                        }
                                        : {
                                            marginLeft: '4px',
                                            marginRight: '10px',
                                        }
                                }
                                onClick={() => this.onEmergencyClicked()}
                            >
                                <i className="mr-1 fa fa-ambulance" />
                                      Emergency
                                {isEmergencyOverall
                                    ? ` - ${emergencyEmployeeName}`
                                    : ''}
                            </span>
                        )}
                        {isAdmin
                                && !isSelfDispatching
                                && isTimesheet
                                && !isClientCancelled
                                && (timesheetStatusId
                                    == TimesheetStatus.Submitted
                                    || timesheetStatusId
                                        == TimesheetStatus.BillingPayrollRejected) && (
                            <span
                                className="site-link"
                                style={{
                                    color: 'blue',
                                    marginLeft: '4px',
                                }}
                                onClick={() => this.onClientCancelClick()}
                            >
                                <i className="mr-1 fa fa-times-circle" />
                                          Client Cancel
                            </span>
                        )}
                        {isAdmin
                                && !isSelfDispatching
                                && isTimesheet
                                && isClientCancelled
                                && (timesheetStatusId
                                    == TimesheetStatus.Submitted
                                    || timesheetStatusId
                                        == TimesheetStatus.BillingPayrollRejected) && (
                            <span
                                className="site-link"
                                style={{
                                    color: 'red',
                                    marginLeft: '4px',
                                }}
                                onClick={() => this.onUndoClientCancelClick()}
                            >
                                <i className="mr-1 fa fa-times-circle" />
                                          Undo Client Cancel
                            </span>
                        )}
                    </div>
                </FlexStartRow>

                {!isSelfDispatching
                        && ((isTimesheet
                            && timesheetStatusId == TimesheetStatus.Approved)
                            || (isPayroll
                                && (payrollStatusId == 2
                                    || payrollStatusId == 4))
                            || (isBilling
                                && (billingStatusId == 2
                                    || billingStatusId == 4))
                            || isTimesheetForm)
                        && isEmergencyOverall && (
                    <FlexStartRow>
                        <div className="d-flex flex-column flex-fill">
                            <span className="text-danger">
                              *** Emergency ***
                            </span>
                        </div>
                    </FlexStartRow>
                )}
                <Modal
                    isOpen={showEmergencyConfirmModal}
                    toggle={this.toggleEmergencyConfirmModal}
                >
                    <ModalHeader toggle={this.toggleEmergencyConfirmModal}>
            Emergency confirmation
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <p>
                                {!!isEmergencyOverall === false
                                    ? 'Are you sure you would like to set this job as emergency work?'
                                    : 'Are you sure you would like to remove emergency work from this job?'}
                            </p>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {isCallingOutAssignment && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            disabled={isCallingOutAssignment}
                            onClick={this.onEmergencyAssignment}
                        >
              Ok
                        </Button>
                        {' '}
                    </ModalFooter>
                </Modal>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showCancelJobModal}
                    toggle={this.toggleCancelJobModal}
                >
                    <ModalHeader toggle={this.toggleCancelJobModal}>
            Client Cancellation
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label>{`Job Start Time: ${jobStartTime}`}</label>
                        </FormGroup>
                        <FormGroup>
                            <label>Cancellation Time</label>
                            <input
                                id="selectedJobCancellationTime"
                                required
                                type="datetime-local"
                                className="form-control"
                                name="selectedJobCancellationTime"
                                defaultValue={
                                    selectedJobCancellationTime ?? ''
                                }
                                onChange={(ev) => {
                                    const val = ev.target.value;
                                    this.setState({
                                        selectedJobCancellationTime: val,
                                    });
                                }}
                            />
                            <small className="invalid-feedback text-danger">
                Cancellation Time is required.
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <label>Notes</label>
                            <textarea
                                id="notes"
                                name="selectedJobCancellationNotes"
                                className="form-control"
                                defaultValue={
                                    selectedJobCancellationNotes ?? ''
                                }
                                placeholder="Enter optional notes regarding the cancellation."
                                type="text"
                                maxLength="500"
                                rows="5"
                                onChange={(ev) => {
                                    const val = ev.target.value;
                                    this.setState({
                                        selectedJobCancellationNotes: val,
                                    });
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {isCancellingJob && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            onClick={this.onClientCancel}
                        >
              Ok
                        </Button>
                        {' '}
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={showUndoClientCancelModal}
                    toggle={() => this.toggleUndoConfirmModal()}
                >
                    <ModalHeader
                        toggle={() => this.toggleUndoConfirmModal()}
                    >
            Undo Client Cancel
                    </ModalHeader>
                    <ModalBody>
                        <p>
              Are you sure you would like to undo the client
              cancel?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        {undoingClientCancel && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            disabled={undoingClientCancel}
                            onClick={() => this.onUndoClientCancel()}
                        >
              Ok
                        </Button>
                        {' '}
                        <Button
                            color="secondary"
                            onClick={() => this.setState({
                                showUndoClientCancelModal: false,
                            })}
                        >
              Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
