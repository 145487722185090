import React from 'react';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import {
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    toasty,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { EmployeeCompliance } from './EmployeeCompliance';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { isComplianceAssociatedWithEmployee } from '../complianceType/ComplianceType';

export default class EmployeeComplianceForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const { allKnownEmployees, allEmployeeComplianceTypes } = this.props;

        const stateBase = {
            employeeCompliance:
                    this.props.employeeCompliance ?? new EmployeeCompliance(),
            allKnownEmployees,
            allEmployeeComplianceTypes,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.isNew = this.isNew.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onChange = onFieldChange;

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onDateChanged = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        const { employeeCompliance } = this.state;

        if (fieldName.includes('effectiveDate')) {
            employeeCompliance.effectiveDate = fieldValue;

            if (employeeCompliance.expirationDate) {
                const effectiveDate = new Date(fieldValue);
                const expirationDate = new Date(
                    employeeCompliance.expirationDate,
                );

                if (effectiveDate.getTime() >= expirationDate.getTime()) {
                    toasty.info('Expiration date cleared');
                    employeeCompliance.expirationDate = null;
                }
            }
        } else {
            employeeCompliance.expirationDate = fieldValue;

            if (employeeCompliance.expirationDate) {
                const effectiveDate = new Date(
                    employeeCompliance.effectiveDate,
                );
                const expirationDate = new Date(fieldValue);

                if (effectiveDate.getTime() >= expirationDate.getTime()) {
                    toasty.info('Expiration date cleared');
                    employeeCompliance.expirationDate = null;
                }
            }
        }

        this.setState({ employeeCompliance });
    };

    onDelete = async (e) => {
        const { employeeCompliance } = this.state;
        const response = await util.fetch.delete(
            ApiRoutes.employeeCompliances.delete(employeeCompliance.id),
        );
        if (response) {
            this.onClose();
        }
    };

    onSelectChange = onReactSelectChanged;

    onSelectedComplianceTypeChanged = async (selectedItem) => {
        const { employeeCompliance } = this.state;

        employeeCompliance.complianceTypeId = selectedItem.id;

        this.setState({ employeeCompliance });
    };

    onSelectedEmployeeChanged = async (selectedItem) => {
        const { employeeCompliance } = this.state;

        if (!selectedItem) {
            employeeCompliance.employeeId = 0;

            this.setState({
                employeeCompliance,
            });

            return;
        }

        const employeeId = selectedItem.id;
        employeeCompliance.employeeId = employeeId;

        this.setState({
            employeeCompliance,
        });
    };

    onSubmit = async (e) => {
        const { employeeCompliance } = this.state;

        this.setState((state) => ({ errors: {}, saving: true }));

        // Is this POST or PUT?
        const url = this.isNew()
            ? ApiRoutes.employeeCompliances.create()
            : ApiRoutes.employeeCompliances.update(employeeCompliance.id);
        const fetchCte = this.isNew() ? util.fetch.post : util.fetch.put;

        const response = await fetchCte(url, employeeCompliance).catch(
            this.handleSaveError,
        );

        if (response) {
            this.onClose();
            toasty.success(
                'Employee Compliance Saved',
                'Employee Compliance saved successfully.',
            );
        }
    };

    getApplicableEmployeeComplianceTypes = (
        allEmployeeComplianceTypes,
        employee,
    ) => {
        if (!allEmployeeComplianceTypes || !employee) {
            return [];
        }

        return allEmployeeComplianceTypes.filter(
            (ect) => isComplianceAssociatedWithEmployee(ect, employee),
        );
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    isNew = () => {
        const { employeeCompliance } = this.state;

        return Boolean(
            !employeeCompliance
            || !employeeCompliance.id
            || employeeCompliance.id <= 0,
        );
    };

    open = async () => {
        await this.setState({ loading: true });

        await this.populateState();

        const employeeCompliance = new EmployeeCompliance();

        this.resetForm();

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({
            employeeCompliance,
            loading: false,
        });
    };

    openEmployee = async (employeeId) => {
        await this.setState({ loading: true });

        await this.populateState();

        const { allKnownEmployees } = this.state;

        const employee = allKnownEmployees.find((e) => e.id == employeeId);

        const employeeCompliance = new EmployeeCompliance();

        if (employee) {
            employeeCompliance.employeeId = employee.id;
        }

        await this.setState({
            employeeCompliance,
            loading: false,
        });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);
    };

    openEmployeeCompliance = async (employeeComplianceId) => {
        await this.setState({ loading: true });

        await this.populateState();

        let employeeCompliance = null;

        this.resetForm();

        if (employeeComplianceId) {
            employeeCompliance = await util.fetch.js(
                ApiRoutes.employeeCompliances.byId(employeeComplianceId),
            );

            employeeCompliance.effectiveDate = util.date.getInputFormat(
                employeeCompliance.effectiveDate,
            );
            employeeCompliance.expirationDate = util.date.getInputFormat(
                employeeCompliance.expirationDate,
            );
        } else {
            employeeCompliance = new EmployeeCompliance();
        }

        await this.setState({
            employeeCompliance,
        });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({ loading: false });
    };

    async populateState() { }

    resetForm = () => {
        this.setState({
            showEmployeeAssignment: false,
            formValidated: false,
        });
    };

    render() {
        const {
            allEmployeeComplianceTypes,
            formValidated,
            validationMessage,
            employeeCompliance,
            allKnownEmployees,
        } = this.state;

        const employee = allKnownEmployees.find(
            (e) => e.id == employeeCompliance.employeeId,
        );

        const employeeComplianceTypes = this.getApplicableEmployeeComplianceTypes(
            allEmployeeComplianceTypes,
            employee,
        );

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="employeeForm"
                formIcon={faToolbox}
                formTitle={
                    this.isNew()
                        ? 'Add Employee Compliance'
                        : 'Edit Employee Compliance'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={employeeCompliance.id}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="employeeCompliance.employeeId"
                        text="Employee"
                        required
                    />
                    <ValidatedSelect
                        id="employeeCompliance.employeeId"
                        name="employeeCompliance.employeeId"
                        required
                        options={allKnownEmployees}
                        value={
                            allKnownEmployees.find(
                                (e) => e.id === employeeCompliance.employeeId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.displayName}
                        getOptionValue={(option) => option.id}
                        onChange={this.onSelectedEmployeeChanged}
                        validationMessage="Employee is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="employeeCompliance.complianceTypeId"
                        text="Compliance Name"
                        required
                    />
                    <ValidatedSelect
                        id="employeeCompliance.complianceTypeId"
                        name="employeeCompliance.complianceTypeId"
                        required
                        options={employeeComplianceTypes}
                        value={
                            employeeComplianceTypes.find(
                                (eqc) => eqc.id
                                    === employeeCompliance.complianceTypeId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={this.onSelectedComplianceTypeChanged}
                        validationMessage="The compliance type is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="employeeCompliance.effectiveDate"
                        text="Effective Date"
                        required
                    />
                    <Input
                        id="employeeCompliance.effectiveDate"
                        name="employeeCompliance.effectiveDate"
                        value={employeeCompliance.effectiveDate}
                        onChange={this.onDateChanged}
                        placeholder="Effective Date"
                        type="date"
                        required
                    />
                    <ValidationErrorMessage>
            Effective Date is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="employeeCompliance.expirationDate"
                        text="Expiration Date"
                        required={false}
                    />
                    <Input
                        id="employeeCompliance.expirationDate"
                        name="employeeCompliance.expirationDate"
                        value={employeeCompliance.expirationDate ?? ''}
                        min={employeeCompliance.effectiveDate}
                        onChange={this.onDateChanged}
                        placeholder="Expiration Date"
                        type="date"
                    />
                    <ValidationErrorMessage />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="employeeCompliance.notes"
                        text="Notes"
                        required={false}
                    />
                    <Input
                        id="employeeCompliance.notes"
                        name="employeeCompliance.notes"
                        value={employeeCompliance.notes ?? ''}
                        onChange={this.onChange}
                        placeholder="Notes"
                        type="textarea"
                        max={500}
                        maxLength={500}
                    />
                    <ValidationErrorMessage />
                </FormGroup>
            </SlideForm>
        );
    }
}
