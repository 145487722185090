import './TimesheetDashboardTile.scss';
import React from 'react';
import {
    Card, CardTitle, CardText, NavLink, CardBody,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

const TimesheetDashboardTile = (props) => {
    const { url, Workflow, Counts } = props;

    return (
        <Card>
            <CardBody>
                <CardTitle className="d-flex flex-column align-items-center">
                    <h4 className="col-12 text-center border-red-top-bottom">
                        <NavLink
                            className=" col-12 dashboard-link"
                            tag={Link}
                            to={url}
                        >
                            {Workflow}
                        </NavLink>
                    </h4>
                </CardTitle>
                <CardText className="d-flex flex-column align-items-center">
                    {Counts.map((val) => (
                        <span
                            key={val.name + val.count}
                            style={{ minWidth: '100px' }}
                            className={val.className}
                        >
                            {val.name}
                            <span className="ml-2">{val.count}</span>
                        </span>
                    ))}
                </CardText>
            </CardBody>
        </Card>
    );
};

export default withRouter(TimesheetDashboardTile);
