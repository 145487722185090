import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'reactstrap';
import { FlexStartRow } from './FormElements';

const ValidationMessageDisplay = ({
    errors,
    onClear,
    errorMessageHeader,
}) => (
    <div
        hidden={(Object.keys(errors ?? {}) ?? []).length <= 0}
        className="col-xl-6 col-md-12 mt-1 offset-xl-3 d-flex flex-column justify-content-center"
    >
        <Alert
            color="danger"
            className="d-flex flex-column flex-nowrap position-relative pr-3"
        >
            <FontAwesomeIcon
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                size="lg"
                icon={faTimesCircle}
                className="close-validation-errors cursor-pointer"
                onClick={onClear}
            />
            <span>{errorMessageHeader ?? 'The following form errors were found:'}</span>
            <ul>
                {(Object.keys(errors ?? {}) ?? []).map((k) => (
                    <li key={k} className="d-flex flex-column flex-nowrap">
                        <FlexStartRow>
                            <FontAwesomeIcon icon={faCircle} style={{ fontSize: '6px' }} className="mr-2" />
                            <b>{k}</b>
                        </FlexStartRow>
                        <span className="ml-3">{errors[k]}</span>
                    </li>
                ))}
            </ul>
        </Alert>
    </div>
);

export default ValidationMessageDisplay;
