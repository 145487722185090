import React from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            currentVersion: null,
            refreshCacheAndReload: (version) => {
                console.log('Clearing cache and hard reloading...');

                if (localStorage) {
                    localStorage.setItem('appVersion', version);
                }

                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then((names) => {
                        for (const name of names) caches.delete(name);
                    });
                }

                // delete browser cache and hard reload
                window.location.reload(true);
            },
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = localStorage ? localStorage.getItem('appVersion') : global.appVersion;
                const shouldForceRefresh = latestVersion !== currentVersion;

                if (shouldForceRefresh) {
                    // console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false, currentVersion: latestVersion });
                } else {
                    // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true, currentVersion: latestVersion });
                }
            });
    }

    render() {
        const {
            loading, isLatestVersion, refreshCacheAndReload, currentVersion,
        } = this.state;
        return this.props.children({
            loading, isLatestVersion, refreshCacheAndReload, currentVersion,
        });
    }
}

export default CacheBuster;