import moment from 'moment';
import { Weekdays } from '../Common';

export class JobOverride {
    id = null;

    jobId = 0;

    weekOfDate = '';

    overrideDate = '';

    overrideDay = '';

    startTime = '';

    description = '';

    dispatchOfficeLocationId = '';

    workOrderNumber = '';

    meetingLocationId = '';

    jobLocationId = '';

    notes = '';

    requestedById = '';

    foremenId = '';

    subcontractorCompanyId = '';

    meetingLocation = null; // Address()

    jobLocation = null; // Address()

    constructor(day) {
        this.overrideDay = Weekdays[day.getDay()].name;
        this.overrideDate = moment(day).toDate();
    }
}
