export class Group {
    id = null;

    groupName = '';

    description = '';

    code = '';

    roles = [];

    isFieldGroup = false;

    requireMFA = false;

    isImpersonatedGroup = false;
}
