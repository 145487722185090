import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {
    FormLabel,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import CommonContext, { MomentDateFormat } from '../Common';

export default class MinimumWageRateForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            formValidated: false,
            validationMessage: '',
            isValidated: false,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onRemove = (e) => {
        this.context.setFormOpened(false);
        this.props.onRemoveCallback();
    };

    onSubmit = (e) => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    };

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    resetForm = () => this.setState({ formValidated: false, errors: {} });

    render() {
        const {
            entity, entityName, show, showRemove,
        } = this.props;

        if (!entity) {
            return '';
        }

        const {
            loading, formValidated, errors, validationMessage,
        } = this.state;

        const name = entityName ? `${entityName}.` : '';

        return (
            <SlideForm
                size="col-xl-9 col-md-9 col-xs-9"
                loading={loading}
                show={show}
                id="billingRateForm"
                formIcon={faMinusCircle}
                formTitle={
                    !!parseInt(entity.id ?? 0) > 0
                        ? 'Edit Min. Wage Rate'
                        : 'Add Min. Wage Rate'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                showDelete={showRemove}
                onDelete={this.onRemove}
            >
                <>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Rate"
                            required={!entity.payRate}
                        />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}payRate`}
                            name={`${name}payRate`}
                            value={entity.payRate ?? {}}
                            onChange={this.props.onChange}
                            placeholder="Enter Rate"
                            type="number"
                            step="0.01"
                            required
                        />
                        <ValidationErrorMessage>
              Min. Wage Rate is required.
                        </ValidationErrorMessage>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Effective Date"
                            required
                        />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}startDate`}
                            name={`${name}startDate`}
                            onChange={this.props.onChange}
                            value={
                                moment(entity.startDate).format(
                                    MomentDateFormat.DateOnlyUTCHyphenated,
                                ) ?? ''
                            }
                            type="date"
                            required={!entity.startDate}
                        />
                        <ValidationErrorMessage>
              Effective Date is required.
                        </ValidationErrorMessage>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Expiration Date"
                            required={false}
                        />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}endDate`}
                            name={`${name}endDate`}
                            value={
                                moment(entity.endDate).format(
                                    MomentDateFormat.DateOnlyUTCHyphenated,
                                ) ?? ''
                            }
                            onChange={this.props.onChange}
                            type="date"
                            required={false}
                        />
                    </FormGroup>
                </>
            </SlideForm>
        );
    }
}
