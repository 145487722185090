import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { ContractStatus, _ContractStatuses } from './Contract';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    TextFilterDefaults,
    LinkCellRenderer,
    ButtonCellRenderer,
    PermissionButtonCellRenderer,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { getTenantUserProfile } from '../common/TenantUserProfile';

class ContractIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedRow: null,
        };
        this.onAddJob = this.onAddJob.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAddJob = (id) => {
        this.props.history.push(`${AppNavPaths.JobNew}/${id}`);
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const [currentUserProfile] = await Promise.all([
                getTenantUserProfile(),
            ]);

            await this.setState({
                currentUserProfile,
            });

            const { currentUser } = { ...currentUserProfile };

            const gridPermissions = await util.fetch.get(
                ApiRoutes.contract.getGridPermissions(),
            );

            const gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                buttonRenderer: ButtonCellRenderer,
                permissionButtonRenderer: PermissionButtonCellRenderer,
            };

            const [dispatchLocations] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            ]);

            // Set the user's initial dispatch selection to their assigned dispatch, if any.
            // Due to an enhancement, the user may see more than one dispatch, this is determined
            // at the server level and by role.
            let initialDispatchValue = null;
            if ((dispatchLocations || []).length === 1) {
                initialDispatchValue = dispatchLocations[0].value;
            } else if (!currentUser.isAdmin) {
                initialDispatchValue = currentUser.dispatchCompanyId;
            }

            const dispatchFilterParams = {
                suppressFilterButton: true,
                options: dispatchLocations,
                optionsLabel: 'label',
                optionsValue: 'value',
                disableFilter: (dispatchLocations || []).length === 1,
                initialFilterValue: initialDispatchValue,
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    maxWidth: 120,
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    cellRenderer: indexCellRenderer,
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    sortable: true,
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: gridPermissions.canEditContract
                        ? 'nameRenderer'
                        : null,
                    cellRendererParams: {
                        to: (id) => `${AppNavPaths.Contract}/${id}`,
                        nameField: 'description',
                        idField: 'id',
                        title: 'View this Contract',
                    },
                },
                {
                    colId: 'Company.CompanyName',
                    headerName: 'Customer Name',
                    field: 'customerName',
                    sortable: true,

                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'Number',
                    headerName: 'Contract #',
                    field: 'number',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'OperationsCenterCompany.CompanyName',
                    headerName: 'Operations Center',
                    field: 'operationsCenter',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'ContractLocations.LocationId',
                    headerName: 'Dispatching',
                    sortable: false,
                    minWidth: 220,
                    flex: 1,
                    field: 'dispatchingFlat',
                    tooltipField: 'dispatchingFlat',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...dispatchFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...dispatchFilterParams },
                },
                {
                    colId: 'ContractStatus.Description',
                    flex: 0,
                    maxWidth: 120,
                    headerName: 'Status',
                    field: 'status',
                    sortable: true,
                    filter: 'selectFilter',
                    filterParams: {
                        suppressMenu: true,
                        floatingFilter: true,
                        labelText: 'Filter by status',
                        options: ContractStatus,
                        optionsLabel: 'description',
                        optionsValue: 'id',
                        initialFilterValue: _ContractStatuses.Active,
                    },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                        options: ContractStatus,
                        optionsLabel: 'description',
                        optionsValue: 'id',
                        initialFilterValue: _ContractStatuses.Active,
                    },
                },
            ];

            const enableContractDateRange = Boolean(
                this.context.tenant?.tenantSettings?.enableContractDateRange,
            );

            if (enableContractDateRange) {
                gridOptions.columnDefs.push({
                    colId: 'EffectiveDate',
                    headerName: 'Effective Date',
                    field: 'effectiveDate',
                    sortable: true,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: { direction: 'desc', priority: 0 },
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults,
                });

                gridOptions.columnDefs.push({
                    colId: 'ExpirationDate',
                    headerName: 'Expiration Date',
                    field: 'expirationDate',
                    sortable: true,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: { direction: 'desc', priority: 0 },
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults,
                });
            }

            gridOptions.columnDefs.push({
                colId: 'ContractStatus.AddJob',
                flex: 0,
                maxWidth: 120,
                headerName: '',
                field: 'status',
                sortable: false,
                cellRenderer: 'permissionButtonRenderer',
                cellRendererParams: {
                    clicked: this.onAddJob,
                    idField: 'id',
                    title: 'Create a Job',
                    buttonText: 'New Job',
                    action: 'create',
                    category: 'job',
                    renderCondition: (context) => {
                        const doRender = context.props.data?.status === 'Active'
                            && !context.props.data?.isSelfDispatch;

                        return doRender;
                    },
                },
            });

            const dataSource = createDataSource(
                ApiRoutes.contract.search(),
                gridOptions,
            );

            this.setState({
                loading: false,
                gridOptions,
                dataSource,
                isAdmin: currentUser.isAdmin,
            });
        }
    }

    render() {
        const { isAdmin } = this.state;

        if (this.state.loading) return <LinearProgress variant="indeterminate" color="secondary" />;

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faAddressCard}
                        className="mr-2 text-muted"
                    />
                    <span>Contracts</span>
                </PageHeading>
                <DataGridToolbar
                    entity="contract"
                    hideExcelButton
                    gridOptions={this.state.gridOptions}
                    serverExport={{
                        apiPath: ApiRoutes.contract.searchExport(),
                        filePrefix: 'Contracts',
                    }}
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() => this.props.history.push(`${AppNavPaths.ContractNew}`)}
                    addLabel="Add Contract"
                    serverExportDisabled={!isAdmin}
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(ContractIndex);
