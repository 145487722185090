export class DailyAttendanceEvent {
    id = 0;

    name = '';

    active = true;

    employeeMayNotBeAssigned = false;

    precedence = 0;

    tenantCompanyId = 0;

    assignedDailyAttendanceEvents = []; // Collection of AssignedDailyAttendanceEvents
}

export class AssignedDailyAttendanceEvent {
    id = 0;

    title = '';

    dayOfWeek = 0;

    specificDate = null; // Date object

    dailyAttendanceEvent_Id = 0;

    dailyAttendanceEventName = '';
}
