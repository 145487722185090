import React, { Fragment } from 'react';
import cls from 'classnames';
import { faFileContract, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button, ButtonGroup, Col, Input, FormGroup, Row,
} from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { CircularProgress } from '@mui/material';
import {
    AppPageForm, FlexCenterRow, FlexEndRow, FormBlocker, SimpleTable, SmallButton, SubHeading, toasty, YesNoBadge, onReactSelectChanged,
    FormLabel,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths, YesNoOptions } from '../Common';
import {
    ScheduleTypes, WorkOrderNumberOptions, ChargeTypeEligbility, ChargeTypeEligibilities,
} from '../contracts/Contract';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ChargeTypeSelect from '../chargeType/ChargeTypeSelect';
import ReorderListWidget from '../widgets/ReorderListWidget';
import SlideForm from '../common/forms/SlideForm';
import authService from '../api-authorization/AuthorizeService';
// import { getTenantUserProfile } from '../common/TenantUserProfile';
import { _ContractChargeTypeEligibility } from '../chargeType/ChargeType';
import { ContractTemplate } from './ContractTemplate';
import {
    getComplianceTypeData,
    getSelectListOfEquipmentComplianceTypesForAllDispatches,
    getSelectListOfUserComplianceTypesForAllDispatches,
    getSelectListOfAvailableUserComplianceTypes,
    getSelectListOfAvailableEquipmentComplianceTypes,
    getSelectedEquipmentComplianceTypeIds,
    getSelectedUserComplianceTypeIds,
} from '../complianceType/ComplianceType';

class ContractTemplateForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.chargeTypeSelectorRef = React.createRef();
        this.chargeTypesWidgetRef = React.createRef();

        const stateBase = {
            contractTemplate: new ContractTemplate(),
            isNew: true,
            loading: true,
            originalData: null,
            scheduleTypes: ScheduleTypes,
            selectedChargeType: null,
            selfDispatchOptions: YesNoOptions,
            selectedSortableChargeTypes: [],
            showReorderWidget: false,
            ...new BaseFormViewModel(),
        };

        this.state = { ...stateBase };
        this.onSubmit = this.onSubmit.bind(this);
        this.onScheduleTypeChanged = this.onScheduleTypeChanged.bind(this);
        this.onAllowSelfDispatchChanged = this.onAllowSelfDispatchChanged.bind(this);
        this.onAllowLinkedWorkOrdersChanged = this.onAllowLinkedWorkOrdersChanged.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
    }

    // #region LIFECYCLE EVENTS
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    // #endregion

    // #region CHARGE TYPES
    onAddChargeTypes = () => {
        const { contractTemplate } = { ...this.state };
        const { chargeTypes } = { ...contractTemplate };
        const typesToOmit = (chargeTypes ?? []).map((c) => c.chargeTypeId) ?? [];
        // pass in types already selected
        this.chargeTypeSelectorRef.current.open(null, [...typesToOmit]);
    };

    onAddChargeTypesCallback = (selections) => {
        const { contractTemplate } = { ...this.state };
        let updated = [...contractTemplate.chargeTypes] ?? [];

        // get selections from the popup
        const selectedChargeTypes = [...selections].map((x, index) => ({
            order: null, /* append to the end, user can then order them */
            contractTemplateId: contractTemplate.id,
            chargeTypeId: x.id,
            chargeTypeEligibilityId: null,
            description: x.description,
            chargeUnits: x.unitsName,
            group: x.appliesToGroup,
            employees: x.appliesToUsers,
            equipment: x.appliesToEquipment,
            billable: x.isBillable,
        }));

        // merge additions with current charges
        updated = updated.concat([...selectedChargeTypes]);

        // reorder everything after the add
        updated = updated.map((x, index) => ({ ...x, ...{ order: index += 1 } }));

        contractTemplate.chargeTypes = [...updated];

        this.setState({ contractTemplate: { ...contractTemplate } });
    };

    onAllowLinkedWorkOrdersChanged = (value) => {
        const { contractTemplate } = { ...this.state };
        contractTemplate.allowLinkedWorkOrders = value;
        this.setState({ contractTemplate: { ...contractTemplate } });
    };

    onAllowNonFlaggingChanged = (value) => {
        const { contractTemplate } = { ...this.state };
        contractTemplate.enableNonFlaggingTimesheets = value;
        // FOR NOW, PER CW:
        // Hide the allow self-dispatch button (since self-dispatch isn't yet finished)
        // and default to true when the non-flag contract is set up.
        contractTemplate.allowSelfDispatch = !!value;
        this.setState({ contractTemplate });
    };

    onAllowSelfDispatchChanged = (value) => {
        const { contractTemplate } = { ...this.state };
        contractTemplate.allowSelfDispatch = value;
        // When disabling self-dispatch, non-flag should also be disabled.
        if (!value) {
            contractTemplate.enableNonFlaggingTimesheets = false;
        }
        this.setState({ contractTemplate });
    };

    onRemoveChargeType = (chargeTypeIndex) => {
        const { contractTemplate } = { ...this.state };
        const chargeTypes = [...contractTemplate.chargeTypes];
        chargeTypes.splice(chargeTypeIndex, 1);
        contractTemplate.chargeTypes = [...chargeTypes];
        this.setState({ contractTemplate: { ...contractTemplate } });
    };

    onReorderChargeTypes = () => {
    // clone the list of charge types, to avoid mutating state.
    // pass to the widget.
        this.setState((state) => {
            const { contractTemplate } = { ...state };
            const selectedSortableChargeTypes = contractTemplate.chargeTypes;
            return { showReorderWidget: true, selectedSortableChargeTypes };
        });
    };

    onRequireSelfDispatchTimesheetsClientSignatureChanged = (value) => {
        const { contractTemplate } = { ...this.state };
        contractTemplate.requireSelfDispatchTimesheetsClientSignature = value;
        this.setState({ contractTemplate });
    };

    onScheduleTypeChanged = (id) => {
        const { contractTemplate } = { ...this.state };
        contractTemplate.contractScheduleTypeId = id;
        this.setState({ contractTemplate: { ...contractTemplate } });
    };

    onSelectChanged = onReactSelectChanged;

    onSubmit = async (e) => {
        const { contractTemplate, isNew, saving } = { ...this.state };
        if ((contractTemplate.chargeTypes ?? []).length <= 0) {
            toasty.error('Invalid Contract Template', 'Please add charge types.');
            return false;
        }
        if (!saving) {
            this.setState({ saving: true });
            isNew ? this.create({ ...contractTemplate }) : this.update({ ...contractTemplate });
        }
    };

    create = async (template) => {
        const result = await util.fetch.andGetResponse(
            util.fetch.types.post,
            ApiRoutes.contractTemplate.create(),
            { ...template },
            'Error Creating Template',
        );

        if (result) {
            toasty.success('Template saved.');
            this.props.history.push(AppNavPaths.ContractTemplateIndex);
        } else {
            this.setState({ saving: false });
        }
    };

    // #endregion

    // #region SUBMISSION
    handleSaveError = handleFormSaveError;

    notifyError = (message) => toasty.error('Save Unsuccessful', `There was a server error saving this contractTemplate. ${(message ? `[${message}]` : '')}  Please try your request again or contact support for assistance.`);

    notifySuccess = () => toasty.success('Contract Saved', 'Contract saved successfully.');

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const contract_template_id = this.props.match.params.id;
            // const { currentUser, userTenant, userPermissions } = await getTenantUserProfile();

            const complianceEnabled = Boolean(this.context.tenant?.tenantSettings?.complianceEnabled);

            const complianceTypeData = await getComplianceTypeData();

            const { complianceTypesForAllDispatches } = complianceTypeData;
            const { allAvailableUserComplianceTypes } = complianceTypeData;
            const { allAvailableEquipmentComplianceTypes } = complianceTypeData;

            const equipmentComplianceTypesForAllDispatches = getSelectListOfEquipmentComplianceTypesForAllDispatches(complianceTypesForAllDispatches);

            const personComplianceTypesForAllDispatches = getSelectListOfUserComplianceTypesForAllDispatches(complianceTypesForAllDispatches);

            const availableUserComplianceTypes = getSelectListOfAvailableUserComplianceTypes(allAvailableUserComplianceTypes);

            const availableEquipmentComplianceTypes = getSelectListOfAvailableEquipmentComplianceTypes(allAvailableEquipmentComplianceTypes);

            let contractTemplate = null;
            if (contract_template_id) {
                contractTemplate = await util.fetch.js(ApiRoutes.contractTemplate.byId(contract_template_id));

                const [contractTemplateCompliances] = await Promise.all([util.fetch.js(ApiRoutes.contractTemplateCompliances.allForContractTemplate(contract_template_id)).catch((error) => console.log('Unable to get the compliances for this contract.'))]);

                const selectedEquipmentComplianceTypeIds = getSelectedEquipmentComplianceTypeIds(
                    contractTemplateCompliances,
                    allAvailableEquipmentComplianceTypes,
                );

                const selectedUserComplianceTypeIds = getSelectedUserComplianceTypeIds(
                    contractTemplateCompliances,
                    allAvailableUserComplianceTypes,
                );

                contractTemplate.equipmentCompliances = selectedEquipmentComplianceTypeIds;
                contractTemplate.userCompliances = selectedUserComplianceTypeIds;
            } else {
                contractTemplate = new ContractTemplate();
            }

            if (!contractTemplate) {
                this.props.history.push(AppNavPaths.NotFound);
                return false;
            }

            const isNew = parseInt(contractTemplate.id ?? 0) <= 0;

            this.setState({
                loading: false,
                isNew,
                contractTemplate: { ...contractTemplate },
                originalData: JSON.stringify({ ...contractTemplate }),
                equipmentComplianceTypesForAllDispatches,
                personComplianceTypesForAllDispatches,
                availableUserComplianceTypes,
                availableEquipmentComplianceTypes,
                complianceEnabled,
            });
        }
    }

    // #endregion

    // #region BASE FORM EVENTS
    resetForm = () => this.setState({ formValidated: false, saving: false });

    update = async (template) => {
        const result = await util.fetch.andGetResponse(
            util.fetch.types.put,
            ApiRoutes.contractTemplate.byId(template.id),
            { ...template },
            'Error Saving Template',
        );
        if (result) {
            toasty.success('Template saved.');
            this.props.history.go(0);
        } else {
            this.setState({ saving: false });
        }
    };

    updateChargeTypeEligibility = (index, eligibilityId) => {
        const { contractTemplate } = { ...this.state };
        const chargeType = { ...contractTemplate.chargeTypes[index] };
        chargeType.chargeTypeEligibilityId = eligibilityId;
        const chargeTypes = [...contractTemplate.chargeTypes];
        chargeTypes[index] = { ...chargeType };
        contractTemplate.chargeTypes = [...chargeTypes];
        this.setState({ contractTemplate: { ...contractTemplate } });
    };

    renderChargeTypesRows = () => {
        const { contractTemplate } = { ...this.state };
        const chargeTypes = [...contractTemplate.chargeTypes];

        if (!chargeTypes) return '';

        return chargeTypes.map((chargeType, chargeTypeIndex) => {
            const eligibilityValid = !!this.state.formValidated && !chargeType.chargeTypeEligibilityId;
            return (
                <tr key={chargeTypeIndex}>
                    <td className="text-center">{chargeType.order ?? ''}</td>
                    <td>
                        {chargeType.description}
                    </td>
                    <td className="text-center">{chargeType.chargeUnits}</td>
                    <td>{chargeType.group}</td>
                    <td>{chargeType.employees}</td>
                    <td>{chargeType.equipment}</td>
                    <td className="text-center"><YesNoBadge value={chargeType.billable} /></td>
                    <td className={cls({ 'p-0': eligibilityValid })}>
                        <div className={cls('h-100 w-100', { 'p-2 border border-danger': eligibilityValid })}>
                            <ButtonGroup>
                                {
                                    ChargeTypeEligibilities.map((eligibility, eligibilityIndex) => (
                                        <Button
                                            key={eligibilityIndex}
                                            type="button"
                                            color="secondary"
                                            size="sm"
                                            className={cls('pt-0 pb-0 pl-1 pr-1 mr-1', { active: eligibility.id === chargeType.chargeTypeEligibilityId })}
                                            onClick={() => { this.updateChargeTypeEligibility(chargeTypeIndex, eligibility.id); }}
                                        >
                                            {eligibility.name}
                                        </Button>
                                    ))
                                }
                            </ButtonGroup>
                            <input
                                type="text"
                                className="hidden-validation"
                                required
                                defaultValue={chargeType.chargeTypeEligibilityId ?? ''}
                            />
                            <span className="invalid-feedback text-danger" hidden>Eligibility selection is required.</span>
                        </div>
                    </td>
                    <td className="text-center">
                        <span
                            title={`Remove ${chargeType.description}`}
                            className="fa fa-trash text-danger cursor-pointer"
                            onClick={() => this.onRemoveChargeType(chargeTypeIndex)}
                        />
                    </td>
                </tr>
            );
        });
    };

    // #endregion

    render() {
        const
            {
                contractTemplate,
                errors,
                formValidated,
                isNew,
                loading,
                originalData,
                scheduleTypes,
                saving,
                equipmentComplianceTypesForAllDispatches,
                personComplianceTypesForAllDispatches,
                availableUserComplianceTypes,
                availableEquipmentComplianceTypes,
                complianceEnabled,
            } = { ...this.state };

        const { formIsOpen } = { ...this.context };

        if (loading) {
            return (
                <div className="h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CircularProgress color="secondary" variant="indeterminate" />
                </div>
            );
        }

        const formTitle = isNew ? 'New Contract Template' : 'Edit Contract Template';

        const selfDispatchTimesheetsEnabled = !!this.context?.tenant?.tenantSettings?.enableSelfDispatchTimesheets;

        const showSelfDispatchOptions = !!contractTemplate?.allowSelfDispatch // Contract must be set up for self-dispatching
            && !!selfDispatchTimesheetsEnabled; // Tenant setting must also be enabled for self-dispatch timesheets.

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, JSON.stringify({ ...contractTemplate }))}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formId="contractTemplateForm"
                    formHeadingIcon={faFileContract}
                    formHeading={formTitle}
                    formName="contractTemplateForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }); }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    loading={loading}
                    onBack={() => this.props.history.push(AppNavPaths.ContractTemplateIndex)}
                    backLabel="Back to Contract Templates"
                >
                    <FormBlocker show={!!formIsOpen} />

                    <Row>
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto">
                            <SubHeading>Details</SubHeading>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="description"
                                    text="Description"
                                    required
                                />
                                <Input
                                    id="description"
                                    name="contractTemplate.description"
                                    className="form-control"
                                    value={contractTemplate.description ?? ''}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        this.setState((state) => (contractTemplate.description = value, state));
                                    }}
                                    placeholder="Enter Description (max 250 characters)"
                                    type="text"
                                    maxLength="250"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Description is required.</small>
                            </FormGroup>

                            {
                                complianceEnabled
                                && (
                                    <>
                                        {
                                            ((personComplianceTypesForAllDispatches && personComplianceTypesForAllDispatches.length > 0)
                                            || (availableUserComplianceTypes && availableUserComplianceTypes.length > 0))
                                        && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="fixedPersonCompliances"
                                                    text="Role Compliances Automatically Applied"
                                                />
                                                <ValidatedSelect
                                                    id="fixedPersonCompliances"
                                                    options={personComplianceTypesForAllDispatches}
                                                    value={personComplianceTypesForAllDispatches}
                                                    isMulti
                                                    isDisabled
                                                />
                                            </FormGroup>
                                        )
                                        }

                                        {
                                            (availableUserComplianceTypes && availableUserComplianceTypes.length > 0)
                                        && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="userCompliances"
                                                    text="Role Compliances"
                                                    required={false}
                                                />
                                                <ValidatedSelect
                                                    id="userCompliances"
                                                    name="contractTemplate.userCompliances"
                                                    options={availableUserComplianceTypes}
                                                    isMulti
                                                    value={(availableUserComplianceTypes ?? []).filter((x) => (contractTemplate.userCompliances ?? []).includes(x.value)) ?? ''}
                                                    onChange={this.onSelectChanged}
                                                />
                                            </FormGroup>
                                        )
                                        }

                                        {
                                            ((equipmentComplianceTypesForAllDispatches && equipmentComplianceTypesForAllDispatches.length > 0)
                                            || (availableEquipmentComplianceTypes && availableEquipmentComplianceTypes.length > 0))
                                        && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="fixedEquipmentCompliances"
                                                    text="Equipment Type Compliances Automatically Applied"
                                                />
                                                <ValidatedSelect
                                                    id="fixedEquipmentCompliances"
                                                    options={equipmentComplianceTypesForAllDispatches}
                                                    value={equipmentComplianceTypesForAllDispatches}
                                                    isMulti
                                                    isDisabled
                                                />
                                            </FormGroup>
                                        )
                                        }

                                        {
                                            (availableEquipmentComplianceTypes && availableEquipmentComplianceTypes.length > 0)
                                        && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="equipmentCompliances"
                                                    text="Equipment Type Compliances"
                                                    required={false}
                                                />
                                                <ValidatedSelect
                                                    id="equipmentCompliances"
                                                    name="contractTemplate.equipmentCompliances"
                                                    options={availableEquipmentComplianceTypes}
                                                    isMulti
                                                    value={(availableEquipmentComplianceTypes ?? []).filter((x) => (contractTemplate.equipmentCompliances ?? []).includes(x.value)) ?? ''}
                                                    onChange={this.onSelectChanged}
                                                />
                                            </FormGroup>
                                        )
                                        }
                                    </>
                                )
                            }

                            <FormGroup>
                                <FormLabel
                                    htmlFor="scheduleType"
                                    text="Schedule"
                                    required
                                />
                                <ButtonGroup
                                    className={cls({ invalid: !!this.state.formValidated && !contractTemplate.contractScheduleTypeId })}
                                    id="scheduleType"
                                    name="scheduleType"
                                >
                                    {
                                        scheduleTypes.map((t) => (
                                            <Button
                                                key={t.id}
                                                size="sm"
                                                color={t.id === contractTemplate.contractScheduleTypeId ? 'primary' : 'secondary'}
                                                className={cls('p-2', { active: t.id === contractTemplate.contractScheduleTypeId })}
                                                onClick={() => { this.onScheduleTypeChanged(t.id); }}
                                            >
                                                {t.name}
                                            </Button>
                                        ))
                                    }
                                </ButtonGroup>
                                <input
                                    type="text"
                                    className="hidden-validation"
                                    required
                                    defaultValue={contractTemplate.contractScheduleTypeId ?? ''}
                                />
                                <span className="invalid-feedback text-danger" hidden>A schedule type selection is required.</span>
                            </FormGroup>
                            {
                                false // for now, remove this choice until self-dispatch without non-flagging is fully implemented.
                                && (
                                    <FormGroup>
                                        <FormLabel htmlFor="allowSelfDispatch" text="Allow Self-Dispatch?" required />
                                        <ButtonGroup
                                            id="allowSelfDispatch"
                                            name="allowSelfDispatch"
                                        >
                                            {
                                                this.state.selfDispatchOptions.map((t) => (
                                                    <Button
                                                        key={t.value}
                                                        size="sm"
                                                        color={t.value === contractTemplate.allowSelfDispatch ? 'primary' : 'secondary'}
                                                        className={cls('p-2', { active: t.value === contractTemplate.allowSelfDispatch })}
                                                        onClick={() => { this.onAllowSelfDispatchChanged(t.value); }}
                                                    >
                                                        {t.label}
                                                    </Button>
                                                ))
                                            }
                                        </ButtonGroup>
                                    </FormGroup>
                                )
                            }
                            {
                                false /* Hide for now, until self dispatch has client signature added. */
                                && !!showSelfDispatchOptions
                                && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="requireSelfDispatchTimesheetsClientSignature"
                                            text="Require Client Signatures on Self-Dispatching Timesheets?"
                                            required
                                        />
                                        <ButtonGroup
                                            id="requireSelfDispatchTimesheetsClientSignature"
                                            name="requireSelfDispatchTimesheetsClientSignature"
                                        >
                                            {
                                                this.state.selfDispatchOptions.map((t) => (
                                                    <Button
                                                        key={t.value}
                                                        size="sm"
                                                        color={t.value === contractTemplate.requireSelfDispatchTimesheetsClientSignature ? 'primary' : 'secondary'}
                                                        className={cls('p-2', { active: t.value === contractTemplate.requireSelfDispatchTimesheetsClientSignature })}
                                                        onClick={() => { this.onRequireSelfDispatchTimesheetsClientSignatureChanged(t.value); }}
                                                    >
                                                        {t.label}
                                                    </Button>
                                                ))
                                            }
                                        </ButtonGroup>
                                    </FormGroup>
                                )
                            }
                            {
                                !!selfDispatchTimesheetsEnabled
                                && (
                                    <FormGroup>
                                        <FormLabel htmlFor="enableNonFlaggingTimesheets" text="Is this a Non-Flagging Contract?" required />
                                        <ButtonGroup
                                            id="enableNonFlaggingTimesheets"
                                            name="enableNonFlaggingTimesheets"
                                        >
                                            {
                                                this.state.selfDispatchOptions.map((t) => (
                                                    <Button
                                                        key={t.value}
                                                        size="sm"
                                                        color={t.value === contractTemplate.enableNonFlaggingTimesheets ? 'primary' : 'secondary'}
                                                        className={cls('p-2', { active: t.value === contractTemplate.enableNonFlaggingTimesheets })}
                                                        onClick={() => { this.onAllowNonFlaggingChanged(t.value); }}
                                                    >
                                                        {t.label}
                                                    </Button>
                                                ))
                                            }
                                        </ButtonGroup>
                                    </FormGroup>
                                )
                            }
                            <FormGroup>
                                <FormLabel
                                    htmlFor="workOrderTypeId"
                                    text="Require Work Order Number?"
                                    required
                                />
                                <ValidatedSelect
                                    id="workOrderTypeId"
                                    name="contractTemplate.workOrderTypeId"
                                    options={WorkOrderNumberOptions}
                                    required
                                    value={WorkOrderNumberOptions.find((wt) => contractTemplate.workOrderTypeId === wt.value) ?? ''}
                                    onChange={(selection) => {
                                        const { contractTemplate } = { ...this.state };
                                        const value = selection?.value ?? null;
                                        contractTemplate.workOrderTypeId = value;
                                        this.setState({ contractTemplate: { ...contractTemplate } });
                                    }}
                                    validationMessage="A work order type selection is required."
                                />
                            </FormGroup>
                            {
                                (!!contractTemplate.workOrderTypeId && contractTemplate.workOrderTypeId !== ChargeTypeEligbility.DoNotUse)
                                && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="linkedWorkOrders"
                                            text="Linked Work Orders?"
                                            required={!!contractTemplate.workOrderTypeId}
                                        />
                                        <ButtonGroup
                                            id="allowLinkedWorkOrders"
                                            name="contractTemplate.allowLinkedWorkOrders"
                                            className={cls({
                                                invalid: !!this.state.formValidated && contractTemplate.allowLinkedWorkOrders === null,
                                            })}
                                        >
                                            {
                                                this.state.selfDispatchOptions.map((t) => (
                                                    <Button
                                                        key={t.value}
                                                        size="sm"
                                                        color={t.value === contractTemplate.allowLinkedWorkOrders ? 'primary' : 'secondary'}
                                                        className={cls('p-2', { active: t.value === contractTemplate.allowLinkedWorkOrders })}
                                                        onClick={() => { this.onAllowLinkedWorkOrdersChanged(t.value); }}
                                                    >
                                                        {t.label}
                                                    </Button>
                                                ))
                                            }
                                        </ButtonGroup>
                                        <input
                                            type="text"
                                            className="hidden-validation"
                                            required
                                            defaultValue={contractTemplate.allowLinkedWorkOrders}
                                        />
                                        <span className="invalid-feedback text-danger" hidden>When work order numbers are optional or required, choose whether or not to allow work order linking.</span>
                                    </FormGroup>
                                )
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubHeading>Charge Types</SubHeading>
                            <SimpleTable
                                className="mb-0"
                                onAddFunction={this.onAddChargeTypes}
                                addButtonEnabled
                                addLabelText={this.context.formIsOpen ? (
                                    <span>
                                        <i className="fa fa-spin fa-circle-notch mr-1" />
                    Opening charge types form...
                                    </span>
                                ) : 'Add Charge Types'}
                                leftToolbarContent={(
                                    <SmallButton
                                        type="button"
                                        disabled={!!this.context.formIsOpen || !(contractTemplate.chargeTypes ?? []).length}
                                        onClick={this.onReorderChargeTypes}
                                    >
                                        <span className="fa fa-sort mr-2" />
                    Reorder
                                    </SmallButton>
                                )}
                                noTopBorder
                                permission="contract_template"
                                tableHeaderLabels={[
                                    { name: 'Order', class: 'text-center' },
                                    { name: 'Description' },
                                    { name: 'Charge Units', class: 'text-center' },
                                    { name: 'Group' },
                                    { name: 'Employees' },
                                    { name: 'Equipment' },
                                    { name: 'Billable', class: 'text-center' },
                                    { name: 'Job Eligibility' },
                                    { name: '', class: 'text-center' },
                                ]}
                                editable={false}
                                entities={[...contractTemplate.chargeTypes]}
                                rowRenderer={this.renderChargeTypesRows}
                                noDataText="No charge types found."
                            />
                        </Col>
                    </Row>
                    {
                        !!(contractTemplate.chargeTypes ?? []).length
                        && (
                            <Row>
                                <Col>
                                    <FlexEndRow className="pt-1 pl-3 pr-4">
                                        <small
                                            className="site-link w-100 text-right"
                                            onClick={() => {
                                                const { contractTemplate } = { ...this.state };
                                                const { chargeTypes } = { ...contractTemplate };
                                                const updated = [...chargeTypes.map((x) => ({ ...x, chargeTypeEligibilityId: _ContractChargeTypeEligibility.Required }))];
                                                contractTemplate.chargeTypes = updated;
                                                this.setState({ contractTemplate: { ...contractTemplate } });
                                            }}
                                        >
                                Require All Charge Types
                                        </small>
                                    </FlexEndRow>
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="pt-3">
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto">
                            <SubHeading>Template Status</SubHeading>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="active"
                                    text="Set Status"
                                    required
                                />
                                <ButtonGroup
                                    id="active"
                                    name="contractTemplate.active"
                                    className={cls({
                                        invalid: !!this.state.formValidated && (contractTemplate.active === null || contractTemplate.active === undefined),
                                    })}
                                >
                                    <Button
                                        size="sm"
                                        color={contractTemplate.active ? 'primary' : 'secondary'}
                                        className={cls('p-2', { active: contractTemplate.active === true })}
                                        onClick={() => this.setState((state) => (contractTemplate.active = true, { ...state }))}
                                    >
                    Active
                                    </Button>
                                    <Button
                                        size="sm"
                                        color={contractTemplate.active ? 'secondary' : 'primary'}
                                        className={cls('p-2', { active: contractTemplate.active === false })}
                                        onClick={() => this.setState((state) => (contractTemplate.active = false, { ...state }))}
                                    >
                    Inactive
                                    </Button>
                                </ButtonGroup>
                                <input
                                    type="text"
                                    className="hidden-validation"
                                    required
                                    defaultValue={contractTemplate.active}
                                />
                                <span className="invalid-feedback text-danger" hidden>Status is required.</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <FlexCenterRow className="mt-4">
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    name="contractForm"
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave}
                                    />
                                    {!isNew ? 'Save Template' : 'Save New Contract Template'}
                                </Button>
                            </FlexCenterRow>
                        </Col>
                    </Row>
                </AppPageForm>
                <ChargeTypeSelect
                    ref={this.chargeTypeSelectorRef}
                    id="chargeTypeSelector"
                    onAddCallback={this.onAddChargeTypesCallback}
                />
                <SlideForm
                    loading={false}
                    show={this.state.showReorderWidget}
                    id="reorderChargeTypesWidget"
                    formIcon={faFileContract}
                    formTitle="Reorder Charge Types"
                    ref={this.chargeTypesWidgetRef}
                    onClose={() => { this.setState((state) => (state.showReorderWidget = false, state)); }}
                >
                    <ReorderListWidget
                        entities={this.state.selectedSortableChargeTypes}
                        entityName="description"
                        customColors={false}
                        setUpdatedEntities={(items) => {
                            this.setState({ selectedSortableChargeTypes: items });
                        }}
                        ref={this.chargeTypesWidgetRef}
                        onSaveCallback={() => {
                            const { contractTemplate, selectedSortableChargeTypes } = { ...this.state };
                            let updated = [...selectedSortableChargeTypes];
                            updated = updated.map((x, index) => ({ ...x, ...{ order: index += 1 } }));
                            contractTemplate.chargeTypes = [...updated];
                            this.setState({
                                contractTemplate: { ...contractTemplate },
                                showReorderWidget: false,
                            });
                        }}
                    />
                </SlideForm>
            </>
        );
    }
}
export default withRouter(ContractTemplateForm);
