import * as React from 'react';
import { FormGroup } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import CommonContext, { ApiRoutes } from '../Common';
import { FormLabel } from '../common/forms/FormElements';
import SignatureButton from '../common/forms/SignatureButton';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { util } from '../Util';
import { TimesheetSignatureType } from './ClientReview';

export const NoReasonInput = (props) => {
    const {
        formValidated, tenantSettings, review, missingSignatureReasons, onMissingSigReasonChanged, onChangeNotes,
    } = { ...props };
    return (
        <FormGroup>
            <FormLabel
                htmlFor="notes"
                text="Missing Signature Reason:"
                required={!review.isComplete}
            />
            {
                // If the tenant setting is enabled, show dropdown with prdefined reasons.
                !review.isComplete && !!tenantSettings.enablePredefinedTimesheetMissingSigReason
                  && (
                      <ValidatedSelect
                          id="noSigReasons"
                          name="review.missingSigReasonId"
                          required={!review.isComplete}
                          isDisabled={!!review.isComplete}
                          options={missingSignatureReasons}
                          value={(missingSignatureReasons ?? []).find((s) => s.value === review.missingSigReasonId) ?? ''}
                          onChange={onMissingSigReasonChanged}
                          validationMessage="A reason is required."
                      />
                  )
            }
            {
                // If tenant setting is disabled, show free text input.
                !review.isComplete && !tenantSettings.enablePredefinedTimesheetMissingSigReason
                  && (
                      <>
                          <textarea
                              id="notes"
                              name="review.notes"
                              className="form-control"
                              defaultValue={review.notes ?? ''}
                              onChange={onChangeNotes}
                              placeholder="Enter notes for why there is no signature."
                              type="text"
                              maxLength="500"
                              rows="5"
                              required
                              disabled={!!review.isComplete}
                          />
                          {!!formValidated && !(review.notes ?? '') && <small className="text-danger">Notes are required.</small>}
                      </>
                  )
            }
            {
                // After the review is complete, only display label.  Also take into account the fact that
                // up until the enhancement we had notes and not the dropdown.
                !!review.isComplete
                  && (
                      <span className="form-control-plaintext">{review.missingSigReason ?? review.notes ?? ''}</span>
                  )
            }
        </FormGroup>
    );
};

/**
* @augments {Component<{
 * review: object,
 * onSignatureTypeChanged:function,
 * onMissingSigReasonChanged:function,
 * onChangeNotes:function,
 * onSigSavedCallback:function,
 * tenantSettings:object,
 * formValidated:boolean,
 * sigIsComplete:boolean *
 * >}
*/
export class ClientTimesheetSignature extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            signatureTypes: [],
            missingSignatureReasons: [],
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const [signatureTypes, missingSigReasons] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.clientSignatureTypes()),
                util.fetch.js(ApiRoutes.timesheetReview.missingSignatureReasons()),
            ]);

            this.setState({
                signatureTypes,
                missingSignatureReasons: missingSigReasons,
            });
        }
    }

    render() {
        const {
            review, onSignatureTypeChanged, onMissingSigReasonChanged, onChangeNotes,
            onSigSavedCallback, tenantSettings, formValidated, sigIsComplete,
        } = { ...this.props };

        const { signatureTypes, missingSignatureReasons } = { ...this.state };

        return (
            <>
                <FormGroup>
                    <FormLabel
                        htmlFor="clientSignatureTypeId"
                        text="Type:"
                        required={!review.isComplete}
                    />
                    <ValidatedSelect
                        id="clientSignatureTypeId"
                        name="review.signatureTypeId"
                        required
                        isDisabled={!!review.isComplete}
                        options={signatureTypes}
                        value={(signatureTypes ?? []).find((s) => s.value === review.signatureTypeId) ?? ''}
                        onChange={onSignatureTypeChanged}
                        validationMessage="A client signature type is required."
                        hideClear
                    />
                </FormGroup>
                {
                    (review ?? {}).signatureTypeId === TimesheetSignatureType.ClientNoSignature
                    && (
                        <NoReasonInput
                            tenantSettings={tenantSettings}
                            review={review}
                            formValidated={formValidated}
                            missingSignatureReasons={missingSignatureReasons}
                            onMissingSigReasonChanged={onMissingSigReasonChanged}
                            onChangeNotes={onChangeNotes}
                        />
                    )
                }
                {
                    (review ?? {}).signatureTypeId !== TimesheetSignatureType.ClientNoSignature
                    && (
                        <>
                            {
                                (!!review.isComplete || !!sigIsComplete)
                            && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="sigPreview"
                                        text="Client Signature:"
                                    />
                                    <img
                                        alt="Signature Preview"
                                        id="sigPreview"
                                        style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ccc' }}
                                        src={review.reviewSignature.signatureData}
                                    />
                                </FormGroup>
                            )
                            }
                            {
                                (!review.isComplete && !sigIsComplete)
                            && (
                                <SignatureButton
                                    id="clientReviewButton"
                                    ref={this.props.signatureRef}
                                    onSigSavedCallback={onSigSavedCallback}
                                    sigData={review?.reviewSignature?.signatureData}
                                />
                            )
                            }
                        </>
                    )
                }
            </>
        );
    }
}
