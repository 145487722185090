import React from 'react';
import './DispatchBoard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import { faToolbox, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Droppable } from '@hello-pangea/dnd';
import EquipmentAssignmentCard from './EquipmentAssignmentCard';

export default class EquipmentJobAssignment extends React.Component {
    render() {
        const {
            job,
            jobIndex,
            day,
            requiredEquipmentType,
            dayAssignments,
            assignmentIndex,
        } = { ...this.props };
        const dropId = `equipment_${jobIndex}_${day.getDay()}_${assignmentIndex}`;
        return (
            <div
                key={requiredEquipmentType.assignmentId}
                className="jobAssignment"
            >
                <Droppable
                    key={dropId}
                    droppableId={dropId}
                    style={{ transform: 'none' }}
                >
                    {(provided, snapshot) => (
                        <div
                            className="assignment-placeholder-row"
                            style={{ height: '50px !important' }}
                        >
                            <label
                                style={{
                                    fontSize: '9px',
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        requiredEquipmentType.description
                                            .toLowerCase()
                                            .indexOf('truck') > -1
                                            ? faTruck
                                            : faToolbox
                                    }
                                    className="mr-1"
                                />
                            </label>
                            <div
                                className={cls('jobAssignmentDropTarget', {
                                    dragging: snapshot.isDraggingOver,
                                    show:
                                        !requiredEquipmentType.assignment
                                        || !!snapshot.isDraggingOver,
                                })}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {
                                    // The assignment
                                    !!requiredEquipmentType.assignment && (
                                        <EquipmentAssignmentCard
                                            removable
                                            compact
                                            innerRef={provided.innerRef}
                                            provided={provided}
                                            job={job}
                                            jobIndex={jobIndex}
                                            day={day}
                                            dayAssignments={dayAssignments}
                                            assignmentIndex={assignmentIndex}
                                            assignmentRequirements={
                                                requiredEquipmentType
                                            }
                                            requiredEquipment={
                                                requiredEquipmentType.assignment
                                                ?? null
                                            }
                                            onEquipmentAssignmentChange={
                                                this.props
                                                    .onEquipmentAssignmentChange
                                            }
                                            onRemoveAssignment={
                                                this.props.onRemoveAssignment
                                            }
                                        />
                                    )
                                }
                                {
                                    // The assignment placeholder
                                    !requiredEquipmentType.assignment && (
                                        <div className="dropPlaceholderWrapper">
                                            <label className="dropPlaceholder">
                                                {
                                                    requiredEquipmentType.description
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}
