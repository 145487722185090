import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FlexCenterRow, FlexColumnStart } from './forms/FormElements';
import SlideForm from './forms/SlideForm';

export class ChangeLog {
    loading = false;

    show = false;

    title = '';

    events = [];
}

export default class ChangeLogViewer extends React.Component {
    render() {
        const { changeLog } = { ...this.props };
        return (
            <SlideForm
                size="col-xl-5 col-lg-6 col-md-8 col-sm-12"
                loading={changeLog.loading}
                show={changeLog.show}
                id="changeLogViewer"
                formIcon={faClipboardCheck}
                formTitle="Change Log"
                ref={this.formRef}
                setIsValidated
                isValidated
                className="change-log-viewer"
                onSubmit={() => { }}
                onClose={this.props.onClose}
                onClearErrors={() => { }}
                errors={[]}
                validationMessage=""
                hideToolbar
            >
                <Container fluid className="p-2">
                    <Row>
                        <Col>
                            {changeLog.title}
                            <div className="table-responsive">
                                <table className="table table-sm table-job-workflow-details table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">
                                                <FlexCenterRow>
                                                    <span className="font-weight-bold">CHANGES</span>
                                                </FlexCenterRow>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Date</th>
                                            <th>Action</th>
                                            <th>Change</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!(changeLog?.events ?? []).length && changeLog.events.map((e, i) => {
                                                const eventDate = moment(moment.utc(e.eventDate).toDate()).local();
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <FlexColumnStart>
                                                                {eventDate.format('M/D/YYYY')}
                                                                <small>
                                                                    {' '}
                                                                    {eventDate.format('h:mm A')}
                                                                </small>
                                                            </FlexColumnStart>
                                                        </td>
                                                        <td>{e.eventType}</td>
                                                        <td>{e.columnName}</td>
                                                        <td>{e.previousValue ?? '-'}</td>
                                                        <td>{e.currentValue}</td>
                                                        <td>{e.modifiedByName}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        {
                                            !(changeLog?.events ?? []).length && (<tr><td colSpan="6">No events found.</td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SlideForm>
        );
    }
}
