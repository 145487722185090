import cls from 'classnames';
import React from 'react';
import { Badge, CardBody, CardTitle } from 'reactstrap';
import { Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

export default class DailyAttendanceEventCard extends React.Component {
    getStaffCount = () => {
        const { dailyAttendanceEvent, employeeDailyAttendanceEvents } = this.props;

        return employeeDailyAttendanceEvents.filter(
            (edae) => edae.dailyAttendanceEventId == dailyAttendanceEvent.id,
        ).length;
    };

    render() {
        const { dailyAttendanceEvent } = this.props;

        if (!dailyAttendanceEvent) {
            return null;
        }

        const staffCount = this.getStaffCount();

        return (
            <div className={cls('card dailyAttendanceEventCard compact')}>
                <Droppable
                    key={`dailyAttendanceEvent_${dailyAttendanceEvent.id}`}
                    droppableId={`dailyAttendanceEvent_${dailyAttendanceEvent.id}`}
                >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                backgroundColor: snapshot.isDraggingOver
                                    ? dailyAttendanceEvent.isRemove
                                        ? 'LightCoral'
                                        : 'LightSteelBlue'
                                    : '',
                            }}
                        >
                            <CardBody>
                                <CardTitle
                                    data-text={`${dailyAttendanceEvent.name}`}
                                    className="text-left"
                                >
                                    {`${dailyAttendanceEvent.name}`}
                                </CardTitle>
                                <span className="title-separator" />
                                {!dailyAttendanceEvent.isRemove && (
                                    <Badge className="badge-bigger">
                                        {staffCount}
                                    </Badge>
                                )}
                                {dailyAttendanceEvent.isRemove && (
                                    <FontAwesomeIcon
                                        className="remove-icon"
                                        icon={faEraser}
                                    />
                                )}
                                {provided.placeholder}
                            </CardBody>
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}
