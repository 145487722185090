import React from 'react';
import {
    Button, Col, Container, Row, Input,
} from 'reactstrap';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Can } from '../Can';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { util } from '../Util';
import NumericInput from '../common/forms/NumericInput';

export class ContractChargeTypeGroupGrid extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showForm: false,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <CircularProgress color="secondary" variant="indeterminate" />
            );
        }
        const { groups } = this.props;

        return (
            <Container fluid>
                <Row className="pb-1">
                    <Col className="ml-0 mr-0">
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <Can I="edit" a="chargetype">
                                <CommonContext.Consumer>
                                    {(value) => (
                                        <SmallButton
                                            type="button"
                                            disabled={
                                                !!value.formIsOpen
                                            }
                                            onClick={
                                                this.props.onAddCTGroup
                                            }
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Group
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            </Can>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-0 mr-0">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: '15%' }}>
                    Contract Invoice Description
                                    </th>
                                    <th style={{ width: '15%' }}>
                    Contract Bill Rate
                                    </th>
                                    <th style={{ width: '15%' }}>
                    Invoice Description
                                    </th>
                                    <th style={{ width: '15%' }}>
                    Bill Rate
                                    </th>
                                    <th style={{ width: '15%' }}>
                    Bill Quantity
                                    </th>
                                    <Can I="edit" a="chargetype">
                                        <th
                                            className="text-center"
                                            style={{ width: '5%' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                            />
                                        </th>
                                    </Can>
                                    <Can I="delete" a="chargetype">
                                        <th
                                            className="text-center"
                                            style={{ width: '5%' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                            />
                                        </th>
                                    </Can>
                                </tr>
                            </thead>
                            <tbody>
                                {!(groups ?? []).length ? (
                                    <tr>
                                        <td
                                            colSpan="9"
                                            className="text-center"
                                        >
                      No rates found.
                                        </td>
                                    </tr>
                                ) : (
                                    this.props.groups.map((group, i) => {
                                        if (
                                            (group ?? {}).editing === true
                                        ) {
                                            return (
                                                <tr
                                                    key={
                                                        group.chargeTypeGroupId
                                                    }
                                                >
                                                    <td>
                                                        <Input
                                                            required
                                                            type="textarea"
                                                            name="invoiceDescription"
                                                            value={
                                                                group.invoiceDescription
                                                                    ?? ''
                                                            }
                                                            onChange={(
                                                                ev,
                                                            ) => this.props.onCTGroupChanged(
                                                                i,
                                                                ev,
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumericInput
                                                            className="form-control-sm text-right"
                                                            name="billRate"
                                                            required
                                                            value={
                                                                group.billRate
                                                                    ?? ''
                                                            }
                                                            onChange={(
                                                                ev,
                                                            ) => this.props.onCTGroupChanged(
                                                                i,
                                                                ev,
                                                            )}
                                                            type="number"
                                                            min="0"
                                                            step="0.5"
                                                            preventNegative
                                                        />
                                                    </td>
                                                    <td>
                                                        {
                                                            group
                                                                .chargeTypeGroup
                                                                .invoiceDescription
                                                        }
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(
                                                            group
                                                                .chargeTypeGroup
                                                                .billRate,
                                                        )
                                                                && util.number.formatCurrency(
                                                                    group
                                                                        .chargeTypeGroup
                                                                        .billRate,
                                                                )}
                                                    </td>
                                                    <td>
                                                        {parseFloat(
                                                            group
                                                                .chargeTypeGroup
                                                                .billQuantity,
                                                        ).toFixed(2)}
                                                    </td>
                                                    <Can
                                                        I="edit"
                                                        a="chargetype"
                                                    >
                                                        <td
                                                            className="text-center"
                                                            style={{
                                                                width: '30px',
                                                            }}
                                                        >
                                                            <CommonContext.Consumer>
                                                                {(
                                                                    value,
                                                                ) => (
                                                                    <Button
                                                                        disabled={
                                                                            !!value.formIsOpen
                                                                        }
                                                                        type="button"
                                                                        color="outline-primary"
                                                                        onClick={() => this.props.onEditCTGroup(
                                                                            group,
                                                                        )}
                                                                        size="sm"
                                                                    >
                                    Edit
                                                                    </Button>
                                                                )}
                                                            </CommonContext.Consumer>
                                                        </td>
                                                    </Can>
                                                    <Can
                                                        I="delete"
                                                        a="chargetype"
                                                    >
                                                        <td
                                                            className="text-center"
                                                            style={{
                                                                width: '30px',
                                                            }}
                                                        >
                                                            <CommonContext.Consumer>
                                                                {(
                                                                    value,
                                                                ) => (
                                                                    <Button
                                                                        disabled={
                                                                            !!value.formIsOpen
                                                                        }
                                                                        type="button"
                                                                        color="outline-primary"
                                                                        onClick={() => this.props.onRemoveCTGroup(
                                                                            group,
                                                                        )}
                                                                        size="sm"
                                                                    >
                                                                        <i className="fa fa-trash" />
                                                                    </Button>
                                                                )}
                                                            </CommonContext.Consumer>
                                                        </td>
                                                    </Can>
                                                </tr>
                                            );
                                        }
                                        return (
                                            <tr
                                                key={
                                                    group.chargeTypeGroupId
                                                }
                                            >
                                                <td>
                                                    {
                                                        group.invoiceDescription
                                                    }
                                                </td>
                                                <td>
                                                    {!util.isEmpty(
                                                        group.billRate,
                                                    )
                                                                && util.number.formatCurrency(
                                                                    group.billRate,
                                                                )}
                                                </td>
                                                <td>
                                                    {
                                                        group
                                                            .chargeTypeGroup
                                                            .invoiceDescription
                                                    }
                                                </td>
                                                <td>
                                                    {!util.isEmpty(
                                                        group
                                                            .chargeTypeGroup
                                                            .billRate,
                                                    )
                                                                && util.number.formatCurrency(
                                                                    group
                                                                        .chargeTypeGroup
                                                                        .billRate,
                                                                )}
                                                </td>
                                                <td>
                                                    {parseFloat(
                                                        group
                                                            .chargeTypeGroup
                                                            .billQuantity,
                                                    ).toFixed(2)}
                                                </td>
                                                <Can
                                                    I="edit"
                                                    a="chargetype"
                                                >
                                                    <td
                                                        className="text-center"
                                                        style={{
                                                            width: '30px',
                                                        }}
                                                    >
                                                        <CommonContext.Consumer>
                                                            {(
                                                                value,
                                                            ) => (
                                                                <Button
                                                                    disabled={
                                                                        !!value.formIsOpen
                                                                    }
                                                                    type="button"
                                                                    color="outline-primary"
                                                                    onClick={() => this.props.onEditCTGroup(
                                                                        group,
                                                                    )}
                                                                    size="sm"
                                                                >
                                  Edit
                                                                </Button>
                                                            )}
                                                        </CommonContext.Consumer>
                                                    </td>
                                                </Can>
                                                <Can
                                                    I="delete"
                                                    a="chargetype"
                                                >
                                                    <td
                                                        className="text-center"
                                                        style={{
                                                            width: '30px',
                                                        }}
                                                    >
                                                        <CommonContext.Consumer>
                                                            {(
                                                                value,
                                                            ) => (
                                                                <Button
                                                                    disabled={
                                                                        !!value.formIsOpen
                                                                    }
                                                                    type="button"
                                                                    color="outline-primary"
                                                                    onClick={() => this.props.onRemoveCTGroup(
                                                                        group,
                                                                    )}
                                                                    size="sm"
                                                                >
                                                                    <i className="fa fa-trash" />
                                                                </Button>
                                                            )}
                                                        </CommonContext.Consumer>
                                                    </td>
                                                </Can>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        );
    }
}
