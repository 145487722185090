import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, {
    agGridConstants,
    ApiRoutes,
    AppNavPaths,
} from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class UserGroupIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    populateState = async () => {
        const [employeeGroups] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.employeeGroups()),
        ]);

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'GroupName',
                sortable: true,
                headerName: 'User Group Name',
                field: 'userGroupName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    to: (id) => `${AppNavPaths.UserGroup}/${id}`,
                    nameField: 'userGroupName',
                    idField: 'id',
                    title: 'View this User Group',
                },
            },
            {
                colId: 'ApplicationGroupUserGroups.ApplicationUserGroup',
                sortable: false,
                headerName: 'Groups',
                field: 'groupsFlat',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter by type',
                    options: employeeGroups,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: employeeGroups,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
            },
        ];

        const ds = createDataSource(ApiRoutes.userGroup.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions,
            dataSource: ds,
        });
    };

    render() {
        if (this.state.loading) return <LinearProgress variant="indeterminate" color="secondary" />;
        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faWarehouse}
                        className="mr-2 text-muted"
                    />
                    <span>User Groups</span>
                </PageHeading>
                <DataGridToolbar
                    entity="user_group"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() => this.props.history.push(`${AppNavPaths.UserGroupNew}`)}
                    addLabel="Add User Group"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(UserGroupIndex);
