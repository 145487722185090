import React from 'react';
import cls from 'classnames';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faCalendarTimes,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Col, FormGroup } from 'reactstrap';
import {
    FormLabel,
    FormValidated,
    SmallButton,
    onFieldChange,
    toasty,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import ValidationMessageDisplay from '../common/forms/ValidationMessageDisplay';
import { EmployeeLongTermStatus } from './EmployeeLongTermStatus';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';

export class EmployeeLongTermStatusForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            errors: {},
            employeeLongTermStatus:
                this.props.employeeLongTermStatus
                ?? new EmployeeLongTermStatus(),
            longTermStatuses: [],
            isValidated: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onLongTermStatusChanged = this.onLongTermStatusChanged.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onChange = onFieldChange;

    onClose(response) {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    onDelete = async () => {
        const { employeeLongTermStatus } = this.state;

        const response = await util.fetch
            .delete(
                ApiRoutes.employeeLongTermStatus.delete(
                    employeeLongTermStatus.id,
                ),
            )
            .catch(this.handleSaveError);

        if (response && !isNaN(response)) {
            toasty.success(
                'Employee Long Term Status deleted',
                'Employee Long Term Status deleted successfully.',
            );

            this.onClose(response);
        }
    };

    onLongTermStatusChanged = (selection) => {
        const { employeeLongTermStatus } = this.state;

        employeeLongTermStatus.longTermStatusId = selection.id;

        this.setState({ employeeLongTermStatus });
    };

    onSubmit = async (e) => {
        const { employeeLongTermStatus } = this.state;

        if (!employeeLongTermStatus.endDate) {
            employeeLongTermStatus.endDate = null;
        }

        this.setState({ errors: {}, saving: true });

        const isExisting = Boolean(
            employeeLongTermStatus.id && employeeLongTermStatus.id > 0,
        );

        const url = isExisting
            ? ApiRoutes.employeeLongTermStatus.update(employeeLongTermStatus.id)
            : ApiRoutes.employeeLongTermStatus.create();

        const fetchType = isExisting
            ? util.fetch.types.put
            : util.fetch.types.post;

        const response = await util.fetch.andGetResponse(
            fetchType,
            url,
            employeeLongTermStatus,
            'Error Saving Long-Term Status',
            () => this.setState({ saving: false }),
        );

        if (response && !isNaN(response)) {
            this.onClose(response);

            const description = isExisting ? 'updated' : 'created';
            toasty.success(
                `Employee Long Term Status ${description}`,
                `Employee Long Term Status ${description} successfully.`,
            );

            this.props.onSaveCallback();
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    isEndDateRequired = () => {
        const { employeeLongTermStatus, longTermStatuses } = this.state;

        if (
            !employeeLongTermStatus
            || !employeeLongTermStatus.longTermStatusId
        ) {
            return false;
        }

        const longTermStatus = longTermStatuses.find(
            (l) => l.id == employeeLongTermStatus.longTermStatusId,
        );

        return Boolean(longTermStatus && longTermStatus.requiresEndDate);
    };

    openEmployee(employeeId) {
        const employeeLongTermStatus = new EmployeeLongTermStatus();
        employeeLongTermStatus.employeeId = employeeId;

        this.openEmployeeLongTermStatus(employeeLongTermStatus);
    }

    openEmployeeLongTermStatus(employeeLongTermStatus) {
        employeeLongTermStatus.startDate = util.date.getInputFormat(
            employeeLongTermStatus.startDate,
        );
        employeeLongTermStatus.endDate = util.date.getInputFormat(
            employeeLongTermStatus.endDate,
        );
        this.setState({ employeeLongTermStatus });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);
    }

    populateState = async () => {
        const [longTermStatuses] = await Promise.all([
            util.fetch.js(ApiRoutes.longTermStatus.all()),
        ]);

        this.setState({ longTermStatuses, loading: false });
    };

    resetForm = () => this.setState({ formValidated: false });

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                    <CircularProgress
                        variant="indeterminate"
                        color="secondary"
                    />
                </div>
            );
        }
        const {
            errors,
            validationMessage,
            employeeLongTermStatus,
            longTermStatuses,
            isValidated,
        } = this.state;
        const { show } = this.props;

        if (!employeeLongTermStatus) {
            return '';
        }

        const isEndDateRequired = this.isEndDateRequired();

        const classNames = cls('slide-form col-xl-3 col-md-6 col-sm-12', {
            show,
        });
        return (
            <div className={classNames}>
                <FormValidated
                    ref={this.formRef}
                    setIsValidated={(value) => this.setState({ isValidated: value })}
                    isValidated={isValidated}
                    className="m-0 w-100"
                    id="employeeLongTermStatusForm"
                    name="employeeLongTermStatusForm"
                    onSubmit={this.onSubmit}
                >
                    <input
                        type="hidden"
                        name="employeLongTermStatus.employeeId"
                        value={employeeLongTermStatus.employeeId ?? ''}
                    />
                    <input
                        type="hidden"
                        name="employeLongTermStatus.id"
                        value={employeeLongTermStatus.id ?? ''}
                    />
                    <header className="border-bottom d-flex flex-column position-relative  pt-3 pl-3 pr-3 mb-3">
                        <span
                            id="employeeLongTermStatusFormClose"
                            className="cursor-pointer site-form-close"
                            onClick={this.onClose}
                        >
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </span>
                        <h5>
                            <div className="d-flex flex-row align-items-center mb-2">
                                <FontAwesomeIcon
                                    className="mr-2 text-muted"
                                    size="lg"
                                    icon={faCalendarTimes}
                                />
                                <span>
                                    {employeeLongTermStatus.id
                                        ? 'Edit Long Term Status'
                                        : 'New Long Term Status'}
                                </span>
                            </div>
                        </h5>
                        <div className="d-flex flex-row align-items-center text-muted">
                            <SmallButton
                                type="submit"
                                name=""
                                onClick={this.onSave}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave}
                                />
                Save
                            </SmallButton>
                            {employeeLongTermStatus.id ? (
                                <SmallButton onClick={this.onDelete}>
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTrashAlt}
                                    />
                  Remove
                                </SmallButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </header>
                    <ValidationMessageDisplay
                        onClear={this.onClearErrorNotificationClicked}
                        errors={errors}
                        message={validationMessage}
                    />
                    <Row className="pl-3 pr-3 pb-3 mr-0 ml-0">
                        <Col>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="longTermStatusesDropdown"
                                    text="Long Term Status"
                                    required
                                />
                                <ValidatedSelect
                                    className="w-auto"
                                    isClearable={false}
                                    id="longTermStatusesDropdown"
                                    name="employeeLongTermStatus.longTermStatusId"
                                    required
                                    value={
                                        longTermStatuses.find(
                                            (i) => i.id
                                                    == employeeLongTermStatus.longTermStatusId,
                                        ) ?? ''
                                    }
                                    options={longTermStatuses ?? []}
                                    onChange={this.onLongTermStatusChanged}
                                    getOptionLabel={(o) => o.name}
                                    getOptionValue={(o) => o.id}
                                    validationMessage="A long term status is required."
                                    isDisabled={Boolean(
                                        employeeLongTermStatus.id,
                                    )}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="startDate"
                                    text="Start Date"
                                    required
                                />
                                <input
                                    id="startDate"
                                    required
                                    type="date"
                                    className="form-control"
                                    name="employeeLongTermStatus.startDate"
                                    defaultValue={
                                        employeeLongTermStatus.startDate
                                            ?? ''
                                    }
                                    onChange={this.onChange}
                                />
                                <small className="invalid-feedback text-danger">
                  Start Date is required.
                                </small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="endDate"
                                    text="End Date"
                                    required={isEndDateRequired}
                                />
                                <input
                                    id="endDate"
                                    type="date"
                                    required={isEndDateRequired}
                                    className="form-control"
                                    name="employeeLongTermStatus.endDate"
                                    defaultValue={
                                        employeeLongTermStatus.endDate ?? ''
                                    }
                                    onChange={this.onChange}
                                />
                                <small className="invalid-feedback text-danger">
                  End Date is required.
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormValidated>
            </div>
        );
    }
}
