export class EquipmentCompliance {
    id = 0;

    effectiveDate = new Date();

    expirationDate = null;

    notes = '';

    equipmentId = 0;

    equipment = null;

    complianceId = 0;

    compliance = null;
}
