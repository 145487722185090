import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'reactstrap';
import { FlexStartRow } from './FormElements';

const FormErrorResponseDisplay = ({
    response,
    onClear,
}) => (
    <div
        hidden={(Object.keys(response?.errors ?? {}) ?? []).length <= 0}
        className="col-xl-12 col-md-12 mt-1 d-flex flex-column justify-content-center"
    >
        <Alert
            color="danger"
            className="d-flex flex-column flex-nowrap position-relative pr-3"
        >
            <FontAwesomeIcon
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                size="lg"
                icon={faTimesCircle}
                className="close-validation-errors cursor-pointer"
                onClick={onClear}
            />
            <span>{response?.title}</span>
            <ul>
                {(Object.keys(response?.errors ?? {}) ?? []).map((k) => (
                    <li key={k} className="d-flex flex-column flex-nowrap">
                        <FlexStartRow key={k}>
                            <FontAwesomeIcon icon={faCircle} style={{ fontSize: '6px' }} className="mr-2" />
                            <b>{k}</b>
                        </FlexStartRow>
                        {(response?.errors[k] ?? []).map((v) => <FlexStartRow key={`${k}.${v}`}><span className="ml-3" style={{ overflowX: 'auto' }}>{v}</span></FlexStartRow>)}
                    </li>
                ))}
            </ul>
        </Alert>
    </div>
);

export default FormErrorResponseDisplay;
