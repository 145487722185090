import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Can } from '../Can';
import { ApplicationPaths, LoginActions } from './ApiAuthorizationConstants';
import NoPermission from './NoPermission';
import authService from './AuthorizeService';
import CommonContext from '../Common';
import { Login } from './Login';

export default class AuthorizeRoute extends Component {
    // Set the context for this class to the common context.
    // https://reactjs.org/docs/context.html#api
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            authenticated: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    render() {
        const { ready, authenticated } = { ...this.state };
        const {
            location, perm, action, component: Component, ...rest
        } = { ...this.props };
        const { user } = { ...this.context };

        const link = document.createElement('a');
        link.href = location.pathname;

        // const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        // const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

        // Force user to password reset page if needed - cannot navigate to any other path that requires
        // authorization until this is done.
        if (user && user.changePassword) {
            // See comments in Login.js, redirectToApiAuthorizationPath method.
            const redirectUrl = `${window.location.origin}${ApplicationPaths.IdentityChangePassword}`;
            window.location.replace(redirectUrl);
        }

        if (!ready) {
            return <div />;
        }

        if (!!perm && !!action && !!authenticated) {
            return (
                <>
                    <Can do={action} on={perm}>
                        <Route
                            {...rest}
                            render={(props) => <Component {...props} />}
                        />
                    </Can>

                    <Can not do={action} on={perm}>
                        <Route
                            {...rest}
                            render={(props) => <NoPermission />}
                        />
                    </Can>
                </>
            );
        }

        if (!authenticated) {
            return <Login action={LoginActions.Login} />;
        }

        return (
            <Route
                {...rest}
                component={Component}
            />
        );
    }
}