import React, { Fragment } from 'react';
import Select from 'react-select';
import cls from 'classnames';

export const ReactSelectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (base) => ({
        ...base,
        // height: 35, //Was causing to not expand with multi
        minHeight: 35,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '&:hover': { borderColor: '#ccc' }, // border style on hover
        border: '1px solid #ccc', // default border color
        boxShadow: 'none', // no box-shadow
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

export const CompactSelectStyles = {
    ...ReactSelectStyles,
    control: (base) => ({
        ...base,
        // height: 28, //Was causing to not expand with multi
        minHeight: 28,
        minMenuHeight: 100,
        menuHeight: 200,
        maxMenuHeight: 200,
        fontSize: '0.74375rem',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '&:hover': { borderColor: '#ccc' }, // border style on hover
        border: '1px solid #ccc', // default border color
        boxShadow: 'none', // no box-shadow
    }),
};

export const SuperCompactSelectStyles = {
    ...ReactSelectStyles,
    control: (base) => ({
        ...base,
        height: 20, // Was causing to not expand with multi
        minHeight: 20,
        minMenuHeight: 100,
        menuHeight: 200,
        maxMenuHeight: 200,
        fontSize: '0.74375rem',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '&:hover': { borderColor: '#ccc' }, // border style on hover
        border: '1px solid #ccc', // default border color
        boxShadow: 'none', // no box-shadow
    }),
};

/*
 * In order to auto receive html validation on the select for single/multiselect entries,
 * place this component in a <Form> and within a <FormGroup>.  See updateReactSelectStyles,
 * which keys in on certain elements to provide validation messages and automatic validation.
 * */
export default class ValidatedSelect extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.selectRef = React.createRef();
    }

    // When changing props programatically, this will fire our onchange event.
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.updateReactSelectStyles();
        }
    }

    updateReactSelectStyles() {
    // Don't display validation messages until the first submission attempt of the form.
    // Key in on Bootstrap validation classes to display red-border and/or validation
    // messages under the dropdown.
        const input = this.inputRef.current;

        if (!input || !this.selectRef.current.select) {
            return;
        }

        const react_select_hidden_input = this.selectRef.current.select.inputRef;
        const form = react_select_hidden_input.closest('form');
        const form_was_validated = ((form ?? {}).classList ?? []).contains(
            'was-validated',
        );
        const form_group = react_select_hidden_input.closest('.form-group');
        const select = form_group.querySelector('[class^="react-select"]');
        const errorLabel = form_group.querySelector('.invalid-feedback');

        // Show-hide validation messages
        if (form_was_validated) {
            if (errorLabel) {
                errorLabel.hidden = input.validity.valid;
            }

            // Add form-control styles
            if (input && input.validity.valid) {
                select.classList.remove('is-invalid');
                select.classList.add('is-valid');
            } else {
                select.classList.add('is-invalid');
                select.classList.remove('is-valid');
            }
        }
    }

    render() {
        const hasValue = this.props.isMulti
            ? !!(this.props.value ?? '').length
            : !!this.props.value;

        const Component = this.props.component ? this.props.component : Select;

        return (
            <>
                <Component
                    styles={ReactSelectStyles}
                    isClearable={!this.props.hideClear}
                    className={cls(
                        'react-select',
                        'required',
                        this.props.className,
                    )}
                    classNamePrefix="react-select"
                    ref={this.selectRef}
                    isDisabled={this.props.disabled}
                    {...this.props}
                />
                {!this.props.disabled && (
                    <input
                        ref={this.inputRef}
                        type="text"
                        className="react-select-validated-input"
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            height: 0,
                            margin: 0,
                            padding: 0,
                            border: 'none',
                        }}
                        value={hasValue ? JSON.stringify(this.props.value) : ''}
                        required={this.props.required}
                        pattern="\[.+?\]|\{.+?\}"
                        onChange={(e) => {}}
                    />
                )}
                <small className="invalid-feedback text-danger">
                    {this.props.validationMessage}
                </small>
            </>
        );
    }
}
