import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

export class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="site-page-wrap h-100 d-flex flex-row align-items-start">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">404</span>
                            <div className="mb-4 lead">The page you are looking for was not found.</div>
                            <a href="/" className="btn btn-link">Back to Home</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(NotFound);
