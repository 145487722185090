import { faEllipsisV, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    ButtonGroup,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
} from 'reactstrap';
import cls from 'classnames';
import { Weekdays } from '../Common';

export default class EquipmentCard extends React.Component {
    setRef = (ref) => {
    // keep a reference to the dom ref as an instance property
        this.ref = ref;
        // give the dom ref to @hello-pangea/dnd
        this.props.innerRef(ref);
    };

    render() {
        const {
            equipment, provided, allJobs, weekdays, hiddenEquipmentIds,
        } = {
            ...this.props,
        };

        if (hiddenEquipmentIds.includes(equipment.id)) {
            return (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={this.setRef}
                />
            );
        }
        return (
            <div
                className={cls('card equipmentCard', {
                    compact: !!this.props.compact,
                    minimal: !!this.props.minimal,
                })}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={this.setRef}
            >
                <div className="dragIndicator">
                    <FontAwesomeIcon icon={faEllipsisV} />
                    <FontAwesomeIcon icon={faEllipsisV} />
                </div>
                <CardBody>
                    <CardTitle className="text-left d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row flex-fill flex-nowrap justify-space-between align-items-center">
                            <span className="flex-fill">
                                {equipment.description}
                            </span>
                        </div>
                        {!!this.props.removable && (
                            <FontAwesomeIcon
                                title="Remove this equipment"
                                icon={faMinusCircle}
                                className="text-danger cursor-pointer"
                                onClick={this.props.onRemoveClicked}
                            />
                        )}
                    </CardTitle>
                    <CardSubtitle className="mt-1 mb-1 text-muted d-flex flex-row justify-space-between align-items-start">
                        <label>{equipment.equipmentType.description}</label>
                    </CardSubtitle>
                    <CardFooter>
                        <ButtonGroup className="" size="sm">
                            {weekdays.map((dayDate) => {
                                const day = Weekdays.find(
                                    (x) => x.id === dayDate.getDay(),
                                );
                                return (
                                    <span
                                        key={day.id}
                                        className={cls(
                                            'btn btn-sm btn-outline-secondary scheduledDayIndicator',
                                            {
                                                active: !!allJobs.find(
                                                    (j) => !!j.assignments[
                                                        day.id
                                                    ].equipment.find(
                                                        (e) => !!e.assignment
                                                                && e.assignment
                                                                    .id
                                                                    === equipment.id,
                                                    ),
                                                ),
                                            },
                                        )}
                                    >
                                        {day.abbreviationShort}
                                    </span>
                                );
                            })}
                        </ButtonGroup>
                    </CardFooter>
                </CardBody>
            </div>
        );
    }
}
