export const InputTypeIds = {
    Text: 1,
    Select: 2,
    MultiSelect: 3,
    CheckBox: 4,
    SingleLineText: 5,
    Number: 6,
    Decimal: 7,
};

export class Question {
    id = null; // long

    parentQuestionId = null; // long

    dependentQuestionId = null; // long

    name = '';

    subText = '';

    surveySectionId = null; // long

    inputTypeId = null; // long

    inputTypeName = '';

    questionRequired = false;

    answerRequired = false;

    optionGroupId = null; // long

    allowMultiple = false;

    timesheetDetailId = null; // long

    childQuestions = []; // cascading questions

    questionOptions = []; // if multiselect, this will be populated.
}

export class Answer {
    id = null; // long

    questionName = '';

    questionId = null; // long

    questionOptionId = null; // long

    questionOptionChoiceText = null; // string

    employeeId = null; // long

    employeeName = '';

    answerFloat = null; // float

    answerInt = null; // int

    answerText = null; // string

    answerBoolean = null;

    unitOfMeasureId = null; // long

    unitOfMeasureName = null; // string

    timesheetDetailId = null; // long
}

export function getInputTypeField(inputTypeId) {
    let fieldToUpdate = '';
    switch (inputTypeId) {
    case InputTypeIds.Text:
        fieldToUpdate = 'answerText';
        break;
    case InputTypeIds.MultiSelect:
        fieldToUpdate = 'questionOptionId';
        break;
    case InputTypeIds.Select:
        fieldToUpdate = 'questionOptionId';
        break;
    case InputTypeIds.SingleLineText:
        fieldToUpdate = 'answerText';
        break;
    case InputTypeIds.CheckBox:
        fieldToUpdate = 'questionOptionId';
        break;
    case InputTypeIds.Number:
        fieldToUpdate = 'answerText';
        break;
    case InputTypeIds.Decimal:
        fieldToUpdate = 'answerText';
        break;
    default:
        break;
    }
    return fieldToUpdate;
}
