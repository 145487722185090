import React from 'react';
import { LinearProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    EditLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes } from '../Common';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';
import EmployeeComplianceForm from './EmployeeComplianceForm';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { isComplianceAssociatedWithEmployee } from '../complianceType/ComplianceType';

class EmployeeComplianceIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.empComplianceForm = React.createRef();

        this.state = {
            loading: true,
            roles: [],
            showEmployeeComplianceForm: false,
        };
        this.onAddEmployeeCompliance = this.onAddEmployeeCompliance.bind(this);
        this.onEditEmployeeCompliance = this.onEditEmployeeCompliance.bind(this);
        this.onEmployeeComplianceFormClosed = this.onEmployeeComplianceFormClosed.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAddEmployeeCompliance = () => {
        this.context.setFormOpened(true);
        this.empComplianceForm.current.open();
    };

    onEditEmployee = (employeeId) => {
        this.context.setFormOpened(true);
        this.empComplianceForm.current.openEmployee(employeeId);
    };

    onEditEmployeeCompliance = (id) => {
        this.context.setFormOpened(true);
        this.empComplianceForm.current.openEmployeeCompliance(id);
    };

    onEmployeeComplianceFormClosed = () => {
    // doesnt fire the form closed hanlder in datagridtoolbar for some reason
        this.context.setFormOpened(false);
        this.state.gridOptions.refresh();
    };

    getEmployeeDisplayName = (employeeComplianceParam) => {
        const { allKnownEmployees } = this.state;

        if (!employeeComplianceParam.data) {
            return '';
        }

        const { employeeId } = employeeComplianceParam.data;

        const employee = allKnownEmployees.find((e) => e.id == employeeId);

        return employee.displayName;
    };

    dateFormatter = (params) => util.date.getShort(params.value);

    async populateState() {
        const [
            employeeComplianceTypeaheads,
            employeeComplianceTypes,
            allEmployees,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.employeeComplianceTypes()),
            util.fetch.js(
                ApiRoutes.complianceTypes.allEmployeeComplianceTypes(),
            ),
            util.fetch.js(ApiRoutes.employees.all()),
        ]);

        const allKnownEmployees = allEmployees.filter((e) => employeeComplianceTypes.some((ec) => isComplianceAssociatedWithEmployee(ec, e)));

        allKnownEmployees.forEach((e) => {
            let displayName = `${e.lastName}, ${e.firstName}`;

            if (
                allKnownEmployees.find((ke) => ke.displayName === displayName)
            ) {
                displayName += ` (${e.userName})`;
            }

            e.displayName = displayName;
        });

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: EditLinkCellRenderer,
            getEmployeeDisplayName: this.getEmployeeDisplayName,
        };

        const employeeComplianceFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Compliance Type',
            options: employeeComplianceTypeaheads,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const employeeFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Employee',
            options: allKnownEmployees,
            optionsLabel: 'displayName',
            optionsValue: 'id',
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'employeeId',
                headerName: 'Employee',
                sortable: false,
                field: 'employeeId',
                filter: 'selectFilter',
                cellRenderer: this.getEmployeeDisplayName,
                filterParams: employeeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: employeeFilterParams,
            },
            {
                colId: 'complianceTypeId',
                headerName: 'Compliance Type Name',
                sortable: true,
                field: 'complianceType.name',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: employeeComplianceFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: employeeComplianceFilterParams,
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    entity: 'applicationusercompliance',
                    clicked: this.onEditEmployeeCompliance,
                    idField: 'id',
                    nameField: 'complianceType.name',
                    title: 'View this Employee Compliance',
                },
            },
            {
                colId: 'effectiveDate',
                sortable: true,
                headerName: 'Effective Date',
                field: 'effectiveDate',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                valueFormatter: this.dateFormatter,
            },
            {
                colId: 'expirationDate',
                sortable: true,
                headerName: 'Expiration Date',
                field: 'expirationDate',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                valueFormatter: this.dateFormatter,
            },
            {
                colId: 'notes',
                sortable: true,
                headerName: 'Notes',
                field: 'notes',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
            },
        ];

        const dataSource = createDataSource(
            ApiRoutes.employeeCompliances.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            allKnownEmployees,
            employeeComplianceTypes,
        });

        const { search } = { ...this.props.location };

        if (search) {
            const employeeId = new URLSearchParams(search).get('employeeId');

            if (employeeId) {
                this.onEditEmployee(employeeId);
            }
        }
    }

    toggleComplianceForm = (show) => this.setState(
        (state) => ((state.showEmployeeComplianceForm = show), state),
    );

    toggleEmployeeComplianceForm = (show) => this.setState(
        (state) => ((state.showEmployeeComplianceForm = show), state),
    );

    render() {
        if (this.state.loading) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        const {
            rowData,
            gridOptions,
            showEmployeeComplianceForm,
            employeeComplianceTypes,
            allKnownEmployees,
        } = this.state;

        return (
            <>
                <DataGridToolbar
                    entity="applicationusercompliance"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddEmployeeCompliance}
                    addLabel="Add Employee Compliance"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    onNameCellClicked={this.onEditEmployeeCompliance}
                    gridStatus={this.state.gridStatus}
                />
                <EmployeeComplianceForm
                    show={
                        showEmployeeComplianceForm /* controls form open state */
                    }
                    toggleShow={
                        this
                            .toggleEmployeeComplianceForm /* getter/setter for form open state */
                    }
                    ref={this.empComplianceForm}
                    onClose={this.onEmployeeComplianceFormClosed}
                    allKnownEmployees={allKnownEmployees}
                    allEmployeeComplianceTypes={employeeComplianceTypes}
                />
            </>
        );
    }
}

export default withRouter(EmployeeComplianceIndex);
