import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import { AbilityContext } from '../Can';
import defineAbilityFor from '../../AppAbility';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
        };
    }

    componentDidMount() {
        const { action } = this.props;
        switch (action) {
        case LoginActions.Login:
            this.login(this.getReturnUrl());
            break;
        case LoginActions.LoginCallback:
            this.processLoginCallback();
            break;
        case LoginActions.LoginFailed: {
            const params = new URLSearchParams(window.location.search);
            const error = params.get(QueryParameterNames.Message);
            this.setState({ message: error });
            break;
        }
        case LoginActions.Profile:
            this.redirectToProfile();
            break;
        case LoginActions.Register:
            this.redirectToRegister();
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    async login(returnUrl) {
        const state = { returnUrl };
        const result = await authService.signIn(state);

        switch (result.status) {
        case AuthenticationResultStatus.Redirect:
            break;
        case AuthenticationResultStatus.Success:
            await this.updateUserPermissions();
            await this.navigateToReturnUrl(returnUrl);
            break;
        case AuthenticationResultStatus.Fail:
            this.setState({ message: result.message });
            break;
        default:
            throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    navigateToReturnUrl(returnUrl) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result?.status) {
        case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeSignIn finishes
        // is when we are doing a redirect sign in flow.
            throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
            await this.navigateToReturnUrl(this.getReturnUrl(result.state));
            break;
        case AuthenticationResultStatus.Fail:
            this.setState({ message: result.message });
            break;
        default:
            throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    async updateUserPermissions() {
        const ability = this.context;
        const rules = await defineAbilityFor();
        ability.update(rules);
    }

    renderLogin = (message) => (
        <div className="login-message container">
            <h5>
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" size="lg" />
                {message}
            </h5>
        </div>
    );

    render() {
        const { action } = this.props;
        const { message } = this.state;

        if (message) {
            return <div>{message}</div>;
        }
        switch (action) {
        case LoginActions.Login:
            return this.renderLogin('Checking credentials...');
        case LoginActions.LoginCallback:
            return this.renderLogin('Signing in...');
        case LoginActions.Profile:
        case LoginActions.Register:
            return (<div />);
        default:
            throw new Error(`Invalid action '${action}'`);
        }
    }
}
