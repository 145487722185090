import React from 'react';
import { CircularProgress } from '@mui/material';

const CustomCircularProgress = ({
    label,
}) => (
    <div className="h-100 w-100 d-flex flex-column flex-nowrap align-items-center justify-content-center">
        <CircularProgress color="secondary" variant="indeterminate" />
        { !!label && <span className="mt-3 w-100 text-center font-weight bold text-muted">{label}</span>}
    </div>
);

export default CustomCircularProgress;
